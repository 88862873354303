import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { LayoutDashboard, Coins, Gem, Gift, Users, Crown, Lock, Feather, Globe, Twitter, Instagram, Youtube, VoteIcon, Wallet, Share2, Calendar, ChevronDown, ChevronUp, Wrench, Activity, Container, ExternalLink, FileText, Network, FlaskConical, Sunset, ArrowRightLeft } from 'lucide-react';
import { FaTelegramPlane, FaTiktok } from 'react-icons/fa';
import CustomConnectButton from './CustomConnectButton';

const MenuItem = ({ to, icon: Icon, label, isActive, onClick, hasSubmenu, isOpen, isSubmenuItem }) => (
  <div className={`relative ${isSubmenuItem ? 'pl-4' : ''}`}>
    <Link
      to={to}
      className={`flex items-center px-3 py-1.5 text-sm font-bold rounded-md ${
        isActive ? 'bg-gradient-to-br from-orange-500 to-yellow-500 text-white' : 'text-white hover:bg-gray-700'
      } ${isSubmenuItem ? 'text-xs' : ''}`}
      onClick={onClick}
    >
      <Icon className={`mr-2 h-5 w-5 ${isActive ? 'text-white' : 'text-orange-500'}`} />
      {label.toUpperCase()}
      {hasSubmenu && (
        isOpen ? <ChevronUp className="ml-auto" size={14} /> : <ChevronDown className="ml-auto" size={14} />
      )}
    </Link>
  </div>
);

const SocialIcon = ({ href, icon: Icon }) => (
  <a href={href} target="_blank" rel="noopener noreferrer" className="text-white hover:text-orange-500">
    <Icon size={20} />
  </a>
);

const Sidebar = ({ mobile, closeMenu }) => {
  const { pathname } = useLocation();
  const [isToolsOpen, setIsToolsOpen] = useState(false);

  const menuItems = [
    { to: "/dashboard", icon: LayoutDashboard, label: "Dashboard" },
	{ to: "/profile", icon: Crown, label: "Profile" },
	{ to: "/icaswap", icon: ArrowRightLeft, label: "Alvey Migration" },
    { to: "/nft-mint", icon: Gem, label: "NFT Mint" },
    { to: "/staking", icon: Coins, label: "Staking" },
    {
      icon: Wrench,
      label: "Alvey Toolkit",
      hasSubmenu: true,
      submenu: [
        { to: "/tools/alveysetup", icon: Network, label: "Alveychain Setup" },
		{ to: "/tools/wallet-activity", icon: Activity, label: "Wallet Activity" },
		{ to: "/tools/projectanalyzer", icon: FlaskConical, label: "Project Analyzer" },
        { to: "/tools/alvey-monitor", icon: Container, label: "Alvey Chain Monitor" },
        { to: "/tools/project-scanner", icon: Gem, label: "Project Scanner" },
        { to: "/tools/network-of-interactions", icon: Network, label: "Network of Interactions" },
	//	{ to: "/tools/airdrop", icon: Gift, label: "Airdrop Tool" },
		{ to: "/tools/contract-interaction", icon: FileText, label: "Contract Interaction"},
      ]
    },
    { to: "/events", icon: Calendar, label: "Events" },
    { to: "/treasury", icon: Wallet, label: "Treasury" },
    { to: "/acolytes", icon: Feather, label: "ACOLYTES" },
    { to: "/core", icon: Crown, label: "CORE" },   
  ];

  return (
    <div className={`${mobile ? 'w-full h-full' : 'w-64 h-screen'} bg-gray-800 text-white p-4 overflow-y-auto flex flex-col justify-between`}>
      <div>
        <div className="flex justify-center mb-6">
          <Link to="/" onClick={mobile ? closeMenu : undefined}>
            <img src="/logo-tekst-white-600p.png" alt="Icarus Logo" className="w-full max-w-[200px]" />
          </Link>
        </div>
        <div className="mb-6 w-full">
          <CustomConnectButton />
        </div>
        <nav className="space-y-0.5">
          {menuItems.map((item) => (
            item.hasSubmenu ? (
              <div key={item.label}>
                <MenuItem
                  {...item}
                  isOpen={isToolsOpen}
                  onClick={() => setIsToolsOpen(!isToolsOpen)}
                />
                {isToolsOpen && (
                  <div className="ml-2 mt-0.5 space-y-0.5">
                    {item.submenu.map((subItem) => (
                      <MenuItem
                        key={subItem.label}
                        {...subItem}
                        isActive={pathname === subItem.to}
                        onClick={mobile ? closeMenu : undefined}
                        isSubmenuItem={true}
                      />
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <MenuItem
                key={item.label}
                {...item}
                isActive={pathname === item.to}
                onClick={mobile ? closeMenu : undefined}
              />
            )
          ))}
        </nav>
      </div>
      <div>
        <hr className="border-gray-600 my-4" />
        <div className="flex justify-center space-x-4 mb-6">
          <SocialIcon href="https://icaruz.space" icon={Globe} />
          <SocialIcon href="https://t.me/icarus_community" icon={FaTelegramPlane} />
          <SocialIcon href="https://twitter.com/icarus_token" icon={Twitter} />
          <SocialIcon href="https://www.instagram.com/icarus_token/" icon={Instagram} />
          <SocialIcon href="https://www.tiktok.com/@icarus_community" icon={FaTiktok} />
          <SocialIcon href="https://www.youtube.com/@ICARUS_COMMUNITY" icon={Youtube} />
        </div>
        <a
          href="https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0xF012F402747E1E4715b7E496650243A29be204C7"
          target="_blank"
          rel="noopener noreferrer"
          className="block w-full bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 text-white font-bold py-2 px-4 rounded text-center transition duration-300 ease-in-out"
        >
          BUY ICA <ExternalLink size={16} className="inline ml-1" />
        </a>
      </div>
    </div>
  );
};

export default Sidebar;
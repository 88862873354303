import React, { useState, useEffect, useCallback } from 'react';
import { useAccount, usePublicClient, useContractWrite, usePrepareContractWrite, useContractRead } from 'wagmi';
import { motion, AnimatePresence } from 'framer-motion';
import { formatDistanceToNow } from 'date-fns';
import { 
  ArrowRightLeft, 
  Eye,
  Info,  
  Code, 
  Link as LinkIcon, 
  Copy, 
  RefreshCw,
  Search,
  Activity,
  CheckCircle,
  XCircle,
  FileCode,
  Box,
  Hash,
  Clock,
  ChevronDown,
  Wallet,
  DollarSign,
  TrendingUp,
  BarChart3,
  Layers,
  CircleDollarSign,
  AlertTriangle,
  Shield,
  X
} from 'lucide-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isAddress } from 'viem';
import ErrorBoundary from '../ErrorBoundary';
import LoadingAnimation from '../LoadingAnimation';
import QuarkUtils from '../../utils/QuarkUtils';
import { config } from '../../config';
import { formatNumber } from '../../utils/numberUtils';

// Base URL for API
const BASE_URL = 'https://api.alveyscan.com/api/v2/smart-contracts';

// Animation variants
const containerVariants = {
  hidden: { opacity: 0, scale: 0.95 },
  visible: { 
    opacity: 1, 
    scale: 1,
    transition: { 
      duration: 0.3,
      staggerChildren: 0.1 
    }
  },
  exit: { 
    opacity: 0, 
    scale: 0.95,
    transition: { duration: 0.2 }
  }
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: { duration: 0.3 }
  }
};

// Helper function for BigInt serialization
const serializeBigInt = (obj) => {
  return JSON.stringify(obj, (_, value) => {
    if (typeof value === 'bigint') {
      return value.toString();
    }
    return value;
  }, 2);
};

// Custom hook for checking contract ownership
const useIsContractOwner = (contractAddress) => {
  const { address } = useAccount();
  const { data: ownerAddress } = useContractRead({
    address: contractAddress,
    abi: [
      {
        inputs: [],
        name: 'owner',
        outputs: [{ type: 'address', name: '', internalType: 'address' }],
        stateMutability: 'view',
        type: 'function'
      }
    ],
    functionName: 'owner',
    enabled: Boolean(contractAddress && address),
  });

  return ownerAddress?.toLowerCase() === address?.toLowerCase();
};

// Confirmation Modal Component
const ConfirmationModal = ({ isOpen, onClose, onConfirm, functionName, inputs = [] }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div 
        className="absolute inset-0 bg-black/60 backdrop-blur-sm"
        onClick={onClose}
      />
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        className="bg-gray-800 rounded-xl p-6 shadow-xl z-10 max-w-lg w-full mx-4 relative border border-orange-500/20"
      >
        <div className="mb-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-xl font-bold text-white flex items-center gap-2">
              <AlertTriangle className="text-orange-500" size={24} />
              Confirm Write Operation
            </h3>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-white transition-colors"
            >
              <X size={20} />
            </button>
          </div>
          <p className="text-gray-300 mb-4">
            You are about to execute the write function <span className="font-bold text-orange-500">{functionName}</span>. 
            This operation will modify the contract state and cannot be undone.
          </p>
          {inputs.length > 0 && (
            <div className="bg-gray-900/50 rounded-lg p-4 mb-4">
              <h4 className="text-sm font-medium text-gray-400 mb-2">Function Parameters:</h4>
              <div className="space-y-2">
                {inputs.map(({ name, value }, index) => (
                  <div key={index} className="flex justify-between">
                    <span className="text-gray-400">{name}:</span>
                    <span className="text-white font-mono">{value}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="bg-orange-500/10 border border-orange-500/20 rounded-lg p-4">
            <p className="text-orange-400 text-sm">
              Please ensure you understand the consequences of this action. This transaction:
              <ul className="list-disc list-inside mt-2 space-y-1">
                <li>Will require gas fees</li>
                <li>Will modify the blockchain state</li>
                <li>Cannot be reversed once confirmed</li>
              </ul>
            </p>
          </div>
        </div>
        <div className="flex justify-end gap-3">
          <button
            onClick={onClose}
            className="px-4 py-2 rounded-lg bg-gray-700 hover:bg-gray-600 text-white transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 rounded-lg bg-orange-500 hover:bg-orange-600 text-white transition-colors flex items-center gap-2"
          >
            <Shield size={16} />
            Confirm Write
          </button>
        </div>
      </motion.div>
    </div>
  );
};

// GlowingCard Component
const GlowingCard = ({ children, className = '', type = 'default' }) => {
  const getBorderColor = () => {
    switch (type) {
      case 'read': return 'border-l-4 border-l-blue-500';
      case 'write': return 'border-l-4 border-l-orange-500';
      default: return 'border border-gray-700';
    }
  };

  return (
    <motion.div
      className={`relative rounded-xl overflow-hidden bg-gray-800/50 backdrop-blur-sm ${getBorderColor()} ${className}`}
      whileHover={{ scale: 1.01 }}
      transition={{ type: "spring", stiffness: 300 }}
    >
      <div className="relative z-10 p-6">
        {children}
      </div>
    </motion.div>
  );
};

// Search Portal Component
const SearchPortal = ({ onSearch, isExpanded }) => {
  const [input, setInput] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isAddress(input)) {
      onSearch(input);
    } else {
      toast.error('Please enter a valid contract address');
    }
  };

  return (
    <motion.div
      initial={{ scale: 1, y: 0 }}
      animate={{
        scale: 1,
        y: 0,
        width: isExpanded ? '100%' : '100%',
        maxWidth: isExpanded ? '100%' : '42rem'
      }}
      transition={{ duration: 0.5, ease: "easeInOut" }}
      className={`mx-auto ${isExpanded ? '' : 'mt-32'}`}
    >
      <GlowingCard className="bg-gradient-to-br from-orange-500 to-orange-600">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-3xl font-bold text-white mb-6 flex items-center justify-center">
            <Code className="mr-2" size={32} />
            Contract Explorer
          </h2>

          <form onSubmit={handleSubmit} className="relative">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Enter contract address"
              className="w-full p-4 pr-12 bg-gray-800 text-white rounded-lg border border-white/20 focus:border-white/40 focus:ring-2 focus:ring-white/20 focus:outline-none transition-all duration-200 placeholder-white/50"
            />
            <button
              type="submit"
              className="absolute right-2 top-1/2 transform -translate-y-1/2 p-2 bg-orange-500 hover:bg-orange-600 rounded-lg transition-colors duration-200"
            >
              <Search className="text-white" size={20} />
            </button>
          </form>

          {!isExpanded && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="mt-6 text-center text-white/70"
            >
              <p>Enter a contract address to explore its functions and interact with it</p>
              <ChevronDown className="mx-auto mt-4 animate-bounce" size={24} />
            </motion.div>
          )}
        </motion.div>
      </GlowingCard>
    </motion.div>
  );
};

// Token Info Card Component
const TokenInfoCard = ({ contractAddress }) => {
  const [tokenInfo, setTokenInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTokenInfo = async () => {
      try {
        const info = await QuarkUtils.getToken(config.alveychainDec, contractAddress);
        setTokenInfo(info);
      } catch (error) {
        console.error('Error fetching token info:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (isAddress(contractAddress)) {
      fetchTokenInfo();
    }
  }, [contractAddress]);

  if (isLoading) return <LoadingAnimation />;
  if (!tokenInfo) return null;

  const InfoItem = ({ icon: Icon, label, value }) => (
    <div className="flex items-center gap-2">
      <div className="p-2 bg-gray-800/50 rounded-lg">
        <Icon size={18} className="text-orange-500" />
      </div>
      <div>
        <p className="text-sm text-gray-400">{label}</p>
        <p className="text-lg font-bold text-white">{value}</p>
      </div>
    </div>
  );

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="mb-6"
    >
      <GlowingCard className="bg-gradient-to-br from-gray-800/50 to-gray-900/50">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-6">
          <InfoItem 
            icon={Code}
            label="Token Name"
            value={tokenInfo.name}
          />
          <InfoItem 
            icon={CircleDollarSign}
            label="Price"
            value={formatNumber(tokenInfo.price_usd, 'ICAPrice')}
          />
          <InfoItem 
            icon={Hash}
            label="Decimals"
            value={tokenInfo.decimals}
          />
          <InfoItem 
            icon={Layers}
            label="Total Supply"
            value={formatNumber(tokenInfo.ttl_supply, 'ICA')}
          />
          <InfoItem 
            icon={TrendingUp}
            label="Circulating Supply"
            value={formatNumber(tokenInfo.crc_supply, 'ICA')}
          />
        </div>
      </GlowingCard>
    </motion.div>
  );
};

// Contract Metadata Section Component
const ContractMetadataSection = ({ contractAddress }) => {
  const [metadata, setMetadata] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const isOwner = useIsContractOwner(contractAddress);
  const { address } = useAccount();

  useEffect(() => {
    const fetchMetadata = async () => {
      if (!isAddress(contractAddress)) return;
      
      setIsLoading(true);
      try {
        const response = await fetch(`${BASE_URL}/${contractAddress}`);
        const data = await response.json();
        setMetadata(data);
      } catch (error) {
        console.error('Error fetching contract metadata:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMetadata();
  }, [contractAddress]);

  if (isLoading) return <LoadingAnimation />;
  if (!metadata) return null;

  const MetadataItem = ({ icon: Icon, label, value }) => (
    <motion.div 
      variants={itemVariants}
      className="space-y-2"
    >
      <h4 className="text-sm font-medium text-gray-400 flex items-center gap-2">
        <Icon size={16} />
        {label}
      </h4>
      <p className="text-lg font-bold text-white">{value}</p>
    </motion.div>
  );

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <GlowingCard className="mb-6 bg-gradient-to-br from-gray-800/50 to-gray-900/50">
        <div className="flex justify-between items-start mb-4">
          <h3 className="text-xl font-bold text-white">Contract Information</h3>
          {address && (
            <div className="flex items-center gap-2">
              <span className={`inline-flex items-center gap-1 px-3 py-1 rounded-full text-sm font-medium ${
                isOwner 
                  ? 'bg-green-500/20 text-green-400' 
                  : 'bg-gray-500/20 text-gray-400'
              }`}>
                <Wallet size={14} />
                {isOwner ? 'Contract Owner' : 'Not Owner'}
              </span>
            </div>
          )}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <MetadataItem
            icon={FileCode}
            label="Contract Name"
            value={metadata.name || 'Unknown'}
          />
          
          <MetadataItem
            icon={Box}
            label="Compiler Version"
            value={metadata.compiler_version || 'N/A'}
          />

          <div className="space-y-2">
            <h4 className="text-sm font-medium text-gray-400 flex items-center gap-2">
              <Hash size={16} />
              Verification Status
            </h4>
            <motion.div 
              variants={itemVariants}
              className="flex items-center gap-2"
            >
              {metadata.is_verified ? (
                <span className="inline-flex items-center gap-1 px-2.5 py-0.5 rounded-full text-sm font-medium bg-green-500/20 text-green-400">
                  <CheckCircle size={14} />
                  Verified
                </span>
              ) : (
                <span className="inline-flex items-center gap-1 px-2.5 py-0.5 rounded-full text-sm font-medium bg-red-500/20 text-red-400">
                  <XCircle size={14} />
                  Unverified
                </span>
              )}
            </motion.div>
          </div>

          <MetadataItem
            icon={Clock}
            label="Last Updated"
            value={metadata.updated_at ? formatDistanceToNow(new Date(metadata.updated_at), { addSuffix: true }) : 'N/A'}
          />
        </div>

        {metadata.description && (
          <motion.div
            variants={itemVariants}
            className="mt-6 p-4 bg-gray-800/50 rounded-lg"
          >
            <p className="text-gray-300">{metadata.description}</p>
          </motion.div>
        )}
      </GlowingCard>
    </motion.div>
  );
};

// Function Card Component
const FunctionCard = ({ 
  func, 
  type, 
  functionInputs, 
  handleInputChange, 
  executeFunction, 
  functionResults,
  setFunctionResults,
  isConnected,
  isExecuting,
  contractAddress,
  onWriteFunction
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const result = functionResults[func.name];

  const args = func.inputs?.map(input => functionInputs[func.name]?.[input.name]) || [];

  const { config: writeConfig } = usePrepareContractWrite({
    address: contractAddress,
    abi: [func],
    functionName: func.name,
    args,
    enabled: type === 'write' && args.every(arg => arg !== undefined),
  });

  const { write, isLoading: isWriteLoading } = useContractWrite({
    ...writeConfig,
    onSuccess: (data) => {
      toast.success(`Transaction submitted: ${data.hash}`);
      data.wait().then(() => {
        toast.success(`Transaction confirmed for ${func.name}`);
        setFunctionResults(prev => ({
          ...prev,
          [func.name]: JSON.stringify({
            hash: data.hash,
            success: true
          }, null, 2)
        }));
      });
    },
    onError: (error) => {
      toast.error(`Transaction failed: ${error.message}`);
    }
  });

  // Updated handleExecute as per user-provided code
  const handleExecute = async (e) => {
    e.preventDefault();
    if (type === 'write') {
      // Validate inputs before showing confirmation modal
      try {
        const validatedInputs = func.inputs?.map(input => {
          const value = functionInputs[func.name]?.[input.name];
          
          if (value === undefined || value === '') {
            throw new Error(`Missing value for ${input.name}`);
          }

          let processedValue = value;

          if (input.type.includes('uint') || input.type.includes('int')) {
            if (!/^-?\d+$/.test(value)) {
              throw new Error(`Invalid number format for ${input.name}`);
            }
            if (input.type.includes('uint') && parseInt(value) < 0) {
              throw new Error(`${input.name} cannot be negative`);
            }
          } 
          else if (input.type === 'bool') {
            if (value.toLowerCase() !== 'true' && value.toLowerCase() !== 'false') {
              throw new Error(`${input.name} must be 'true' or 'false'`);
            }
          }
          else if (input.type === 'address') {
            if (!isAddress(value)) {
              throw new Error(`Invalid address format for ${input.name}`);
            }
          }

          return { name: input.name, value: processedValue };
        }) || [];

        if (!write) {
          toast.error('Please fill in all required parameters correctly');
          return;
        }
        onWriteFunction(func, write);
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      await executeFunction(func);
    }
  };

  return (
    <motion.div variants={itemVariants} layout>
      <GlowingCard type={type}>
        <div className="flex justify-between items-start">
          <div className="flex-1">
            <div className="flex items-center gap-2">
              <h4 className="text-lg font-bold text-white">{func.name}</h4>
              <span className={`text-xs px-2 py-0.5 rounded-full ${
                type === 'read' ? 'bg-blue-500/20 text-blue-300' : 'bg-orange-500/20 text-orange-300'
              }`}>
                {type.toUpperCase()}
              </span>
            </div>
            <p className="text-sm text-gray-400 mt-1">
              {func.description || `${type === 'read' ? 'View' : 'Transaction'} Function`}
            </p>
          </div>
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="p-2 hover:bg-gray-700/50 rounded-full transition-colors text-gray-400 hover:text-white"
          >
            {isExpanded ? <Eye size={20} /> : <Info size={20} />}
          </button>
        </div>

        <AnimatePresence>
          {isExpanded && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="mt-4 space-y-4"
            >
              {func.inputs?.length > 0 && (
                <div className="space-y-2">
                  {func.inputs.map((input, idx) => (
                    <motion.div
                      key={`${func.name}-${input.name}-${idx}`}
                      variants={itemVariants}
                      className="bg-gray-900/50 p-4 rounded-lg"
                    >
                      <label className="block text-sm font-medium text-gray-300 mb-1">
                        {input.name} <span className="text-gray-500">({input.type})</span>
                      </label>
                      <input
                        type="text"
                        value={functionInputs[func.name]?.[input.name] || ''}
                        onChange={(e) => handleInputChange(func.name, input.name, e.target.value, input.type)}
                        disabled={isExecuting || isWriteLoading}
                        placeholder={`Enter ${input.type}`}
                        className="w-full p-2 bg-gray-800 text-white rounded border border-gray-600 focus:border-orange-500 focus:ring-2 focus:ring-orange-500/20 focus:outline-none disabled:opacity-50 transition-all duration-200"
                      />
                    </motion.div>
                  ))}
                </div>
              )}

              <motion.button
                variants={itemVariants}
                onClick={handleExecute} // Updated to use the new handleExecute
                disabled={!isConnected || isExecuting || (type === 'write' && !write) || isWriteLoading}
                className={`w-full py-2 px-4 rounded-lg font-bold ${
                  type === 'read'
                    ? 'bg-blue-500 hover:bg-blue-600'
                    : 'bg-orange-500 hover:bg-orange-600'
                } text-white transition-all duration-200 flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed`}
              >
                {isExecuting || isWriteLoading ? (
                  <RefreshCw className="animate-spin" size={20} />
                ) : (
                  <>
                    {type === 'write' ? (
                      <ArrowRightLeft size={20} className="mr-2" />
                    ) : (
                      <Eye size={20} className="mr-2" />
                    )}
                    {type === 'write' ? 'Execute' : 'Read'}
                  </>
                )}
              </motion.button>

              {result && (
                <div className="mt-4 p-4 bg-gray-800 rounded-lg overflow-x-auto">
                  <div className="flex justify-between items-center mb-2">
                    <span className="text-sm font-medium text-gray-300">Result</span>
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(result);
                        toast.success('Result copied to clipboard');
                      }}
                      className="text-gray-400 hover:text-white transition-colors p-1 hover:bg-gray-700 rounded"
                    >
                      <Copy size={16} />
                    </button>
                  </div>
                  <pre className="text-xs text-white whitespace-pre-wrap bg-gray-900 p-3 rounded">
                    {result}
                  </pre>
                </div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </GlowingCard>
    </motion.div>
  );
};

// Main Component
const ContractInteractionTool = () => {
  const { address, isConnected } = useAccount();
  const publicClient = usePublicClient();
  
  const [contractAddress, setContractAddress] = useState('');
  const [contractFunctions, setContractFunctions] = useState({ read: [], write: [] });
  const [functionInputs, setFunctionInputs] = useState({});
  const [functionResults, setFunctionResults] = useState({});
  const [isExecuting, setIsExecuting] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  
  // Modal state
  const [modalState, setModalState] = useState({
    isOpen: false,
    functionName: '',
    inputs: [],
    onConfirm: () => {},
  });

  // Contract methods fetching logic
  const fetchContractMethods = useCallback(async (address) => {
    if (!isAddress(address)) {
      toast.error('Invalid contract address');
      return;
    }

    setIsLoading(true);
    try {
      const [readMethods, writeMethods] = await Promise.all([
        fetch(`${BASE_URL}/${address}/methods-read`).then(r => r.json()),
        fetch(`${BASE_URL}/${address}/methods-write`).then(r => r.json()),
      ]);
      
      setContractFunctions({
        read: readMethods || [],
        write: writeMethods || [],
      });
      setError(null);
    } catch (err) {
      setError(`Error fetching contract methods: ${err.message}`);
      toast.error(`Failed to fetch contract methods: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Handle search
  const handleSearch = (address) => {
    setContractAddress(address);
    setIsExpanded(true);
  };

  // Handle write function execution
  const handleWriteFunction = (func, write) => {
    const inputsForModal = func.inputs?.map(input => ({
      name: input.name,
      value: functionInputs[func.name]?.[input.name] || ''
    })) || [];

    setModalState({
      isOpen: true,
      functionName: func.name,
      inputs: inputsForModal,
      onConfirm: () => {
        setModalState(prev => ({ ...prev, isOpen: false }));
        write();
      }
    });
  };

  // Function to close modal
  const handleCloseModal = () => {
    setModalState(prev => ({ ...prev, isOpen: false }));
  };

  // Updated handleInputChange - no validation during input
  const handleInputChange = useCallback((funcName, name, value, type) => {
    setFunctionInputs(prev => ({
      ...prev,
      [funcName]: { ...prev[funcName], [name]: value }
    }));
  }, []);

  // Updated executeFunction with all validations
  const executeFunction = useCallback(async (func) => {
    if (!isConnected) {
      toast.error('Please connect your wallet');
      return;
    }

    setIsExecuting(true);
    setError(null);

    try {
      const args = func.inputs?.map(input => {
        const value = functionInputs[func.name]?.[input.name];
        
        // Check for missing values
        if (value === undefined || value === '') {
          throw new Error(`Missing value for ${input.name}`);
        }

        // Validate based on type only during execution
        let processedValue = value;

        if (input.type.includes('uint') || input.type.includes('int')) {
          if (!/^-?\d+$/.test(value)) {
            throw new Error(`Invalid number format for ${input.name}`);
          }
          if (input.type.includes('uint') && parseInt(value) < 0) {
            throw new Error(`${input.name} cannot be negative`);
          }
          processedValue = value;
        } 
        else if (input.type === 'bool') {
          if (value.toLowerCase() !== 'true' && value.toLowerCase() !== 'false') {
            throw new Error(`${input.name} must be 'true' or 'false'`);
          }
          processedValue = value.toLowerCase() === 'true';
        }
        else if (input.type === 'address') {
          if (!isAddress(value)) {
            throw new Error(`Invalid address format for ${input.name}`);
          }
        }

        return processedValue;
      }) || [];

      let result;
      if (func.stateMutability === 'view' || func.stateMutability === 'pure') {
        const rawResult = await publicClient.readContract({
          address: contractAddress,
          abi: [func],
          functionName: func.name,
          args
        });

        result = Array.isArray(rawResult) 
          ? rawResult.map(item => typeof item === 'bigint' ? item.toString() : item)
          : typeof rawResult === 'bigint' 
            ? rawResult.toString() 
            : rawResult;

        setFunctionResults(prev => ({
          ...prev,
          [func.name]: serializeBigInt(result)
        }));

        toast.success(`Successfully executed ${func.name}`);
      }
    } catch (err) {
      console.error('Execution error:', err);
      setError(err.message);
      toast.error(`Failed to execute ${func.name}: ${err.message}`);
    } finally {
      setIsExecuting(false);
    }
  }, [isConnected, contractAddress, functionInputs, publicClient]);

  useEffect(() => {
    if (isAddress(contractAddress)) {
      fetchContractMethods(contractAddress);
    }
  }, [contractAddress, fetchContractMethods]);

  const renderFunctionCard = (func, type) => (
    <FunctionCard
      key={func.name}
      func={func}
      type={type}
      functionInputs={functionInputs}
      handleInputChange={handleInputChange}
      executeFunction={executeFunction}
      functionResults={functionResults}
      setFunctionResults={setFunctionResults}
      isConnected={isConnected}
      isExecuting={isExecuting}
      contractAddress={contractAddress}
      onWriteFunction={handleWriteFunction}
    />
  );

  return (
    <ErrorBoundary>
      <div className="min-h-screen p-6 bg-gray-900">
        <ToastContainer theme="dark" />
        
        {/* Modal */}
        <AnimatePresence>
          {modalState.isOpen && (
            <ConfirmationModal
              isOpen={modalState.isOpen}
              onClose={handleCloseModal}
              onConfirm={modalState.onConfirm}
              functionName={modalState.functionName}
              inputs={modalState.inputs}
            />
          )}
        </AnimatePresence>

        <SearchPortal onSearch={handleSearch} isExpanded={isExpanded} />

        <AnimatePresence>
          {isExpanded && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              className="space-y-6 max-w-7xl mx-auto mt-6"
            >
              {contractAddress && <TokenInfoCard contractAddress={contractAddress} />}
              {contractAddress && <ContractMetadataSection contractAddress={contractAddress} />}

              {isLoading ? (
                <LoadingAnimation />
              ) : (contractFunctions.read.length > 0 || contractFunctions.write.length > 0) ? (
                <motion.div
                  variants={containerVariants}
                  initial="hidden"
                  animate="visible"
                  className="space-y-6"
                >
                  <div className="flex items-center justify-between">
                    <h3 className="text-xl font-bold text-white flex items-center">
                      <LinkIcon className="mr-2" size={20} />
                      Contract Methods
                    </h3>
                    
                    <div className="flex items-center gap-4">
                      <div className="flex items-center gap-1 text-blue-400 text-sm">
                        <Eye size={16} />
                        <span>{contractFunctions.read.length} Read</span>
                      </div>
                      <div className="flex items-center gap-1 text-orange-400 text-sm">
                        <ArrowRightLeft size={16} />
                        <span>{contractFunctions.write.length} Write</span>
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <motion.div 
                      variants={itemVariants}
                      className="space-y-4"
                    >
                      <h4 className="text-lg font-semibold text-blue-400 flex items-center gap-2">
                        <Eye size={20} />
                        Read Functions
                      </h4>
                      {contractFunctions.read.map(func => renderFunctionCard(func, 'read'))}
                    </motion.div>
                    
                    <motion.div 
                      variants={itemVariants}
                      className="space-y-4"
                    >
                      <h4 className="text-lg font-semibold text-orange-400 flex items-center gap-2">
                        <ArrowRightLeft size={20} />
                        Write Functions
                      </h4>
                      {contractFunctions.write.map(func => renderFunctionCard(func, 'write'))}
                    </motion.div>
                  </div>
                </motion.div>
              ) : (
                <motion.div
                  variants={containerVariants}
                  initial="hidden"
                  animate="visible"
                  className="text-center text-gray-400 py-12"
                >
                  <Code size={48} className="mx-auto mb-4 opacity-50" />
                  <p className="text-lg">No contract methods available. Enter a valid contract address to begin.</p>
                </motion.div>
              )}

              {error && (
                <motion.div
                  variants={itemVariants}
                  initial="hidden"
                  animate="visible"
                  className="bg-red-500/10 border border-red-500 text-red-500 p-4 rounded-lg mt-6"
                >
                  {error}
                </motion.div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </ErrorBoundary>
  );
};

export default ContractInteractionTool;

import React, { useState, useEffect } from 'react';
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi';
import { motion } from 'framer-motion';
import { PlusCircle, RefreshCw, Coins, ArrowRightLeft, ExternalLink, MessageCircle, DollarSign, Info, CreditCard, Globe, Crown } from 'lucide-react';
import { config } from '../../config';
import ErrorBoundary from './../ErrorBoundary';

const ALVEY_CHAIN_ID = 3797;
const ALVEY_CHAIN_INFO = {
  chainId: '0xED5',
  chainName: 'Alveychain Mainnet',
  nativeCurrency: {
    name: 'ALV',
    symbol: 'ALV',
    decimals: 18,
  },
  rpcUrls: ['https://elves-core3.alvey.io'],
  blockExplorerUrls: ['https://alveyscan.com/'],
};

const GlowingCard = ({ children, glowColor = 'rgba(249, 115, 22, 0.5)', className = '' }) => (
  <motion.div
    className={`relative bg-gray-800 rounded-xl overflow-hidden ${className}`}
    whileHover={{ scale: 1.02 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <div className="absolute inset-0 bg-gradient-to-r from-transparent via-gray-700 to-transparent opacity-50" />
    <div className="relative z-10 p-6">
      {children}
    </div>
    <div
      className="absolute inset-0 opacity-0 hover:opacity-100 transition-opacity duration-300"
      style={{
        boxShadow: `0 0 20px ${glowColor}`,
        filter: 'blur(15px)',
        zIndex: -1,
      }}
    />
  </motion.div>
);

const ProjectCard = ({ title, logo, banner, description, website, telegram, buyLink }) => (
  <GlowingCard className="flex flex-col h-full">
    <div className="h-40 overflow-hidden -mx-6 -mt-6 mb-4">
      <img src={banner} alt={`${title} banner`} className="w-full h-full object-cover" />
    </div>
    <div className="flex-grow px-4">
      <div className="flex items-center justify-between mb-4">
        <h4 className="text-xl font-bold text-white">{title}</h4>
        <div className="flex space-x-2">
          <a 
            href={website} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="bg-gray-700 hover:bg-blue-500 text-gray-300 hover:text-white p-2 rounded-full transition-colors duration-200 flex items-center justify-center" 
            title="Visit Website"
          >
            <Globe size={16} />
          </a>
          <a 
            href={telegram} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="bg-gray-700 hover:bg-green-500 text-gray-300 hover:text-white p-2 rounded-full transition-colors duration-200 flex items-center justify-center" 
            title="Join Telegram"
          >
            <MessageCircle size={16} />
          </a>
          <a 
            href={buyLink} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="bg-gray-700 hover:bg-orange-500 text-gray-300 hover:text-white p-2 rounded-full transition-colors duration-200 flex items-center justify-center" 
            title="Buy Token"
          >
            <DollarSign size={16} />
          </a>
        </div>
      </div>
      <p className="text-gray-300">{description}</p>
    </div>
  </GlowingCard>
);

const ToolCard = ({ title, description, icon: Icon, link, linkText }) => (
  <GlowingCard className="flex flex-col h-full">
    <div className="flex items-center mb-4">
      <Icon className="text-orange-500 mr-3" size={24} />
      <h4 className="text-xl font-bold text-white">{title}</h4>
    </div>
    <div className="text-gray-300 mb-4 flex-grow">{description}</div>
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded transition-colors duration-200 flex items-center justify-center mt-auto"
      title="{linkText}"
    >
      {linkText} <ExternalLink className="ml-2" size={16} />
    </a>
  </GlowingCard>
);

const AlveySetup = () => {
  const { address, isConnected } = useAccount();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  const [isAddingNetwork, setIsAddingNetwork] = useState(false);
  const [isAddingToken, setIsAddingToken] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const steps = [
    { title: "Add Alveychain to Your Wallet", action: addAlveyChainToMetaMask },
    { title: "Switch to Alveychain", action: switchToAlveyChain },
    { title: "Add ICA Token to Wallet", action: addICATokenToWallet },
    { title: "Explore Alveychain Ecosystem", action: null }
  ];

  useEffect(() => {
    if (chain?.id === ALVEY_CHAIN_ID) {
      setCurrentStep(2);
    }
  }, [chain]);

  async function addAlveyChainToMetaMask() {
    if (typeof window.ethereum !== 'undefined') {
      setIsAddingNetwork(true);
      try {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [ALVEY_CHAIN_INFO],
        });
        console.log('Alveychain added to MetaMask');
        setCurrentStep(1);
      } catch (error) {
        console.error('Failed to add Alveychain to MetaMask:', error);
      } finally {
        setIsAddingNetwork(false);
      }
    } else {
      console.error('MetaMask is not installed');
    }
  }

  function switchToAlveyChain() {
    if (chain?.id !== ALVEY_CHAIN_ID) {
      switchNetwork?.(ALVEY_CHAIN_ID);
    } else {
      setCurrentStep(2);
    }
  }

  async function addICATokenToWallet() {
    if (typeof window.ethereum !== 'undefined') {
      setIsAddingToken(true);
      try {
        await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: config.alveyContractAddress,
              symbol: 'ICA',
              decimals: 18,
              image: 'https://icaruz.space/assets/img/favicon/favicon-32x32.png',
            },
          },
        });
        console.log('ICA token added to wallet');
        setCurrentStep(3);
      } catch (error) {
        console.error('Failed to add ICA token to wallet:', error);
      } finally {
        setIsAddingToken(false);
      }
    } else {
      console.error('MetaMask is not installed');
    }
  }

  if (!isConnected) {
    return (
      <ErrorBoundary>
        <GlowingCard>
          <h2 className="text-2xl font-bold text-orange-500 mb-4">Alveychain Setup</h2>
          <p className="text-white">Please connect your wallet to set up Alveychain.</p>
        </GlowingCard>
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary>
      <div className="space-y-6">
        <GlowingCard>
          <h2 className="text-3xl font-bold text-orange-500 mb-6 flex items-center">
            <Crown className="mr-2" size={32} /> Alveychain Setup
          </h2>
          <p className="text-white mb-6">
            Welcome to the Alveychain Setup page! Here, you can easily configure your wallet to interact with the Alveychain and start exploring its ecosystem. Follow the step-by-step guide below to get started:
          </p>
          
          <div className="space-y-4">
            {steps.map((step, index) => (
              <div key={index} className={`flex items-center ${currentStep >= index ? 'text-white' : 'text-gray-500'}`}>
                <div className={`w-8 h-8 rounded-full flex items-center justify-center mr-4 ${currentStep >= index ? 'bg-orange-500' : 'bg-gray-700'}`}>
                  {index + 1}
                </div>
                <div className="flex-grow">
                  <h3 className="font-bold">{step.title}</h3>
                  {currentStep === index && step.action && (
                    <button
                      onClick={step.action}
                      disabled={isAddingNetwork || isAddingToken || (index === 1 && chain?.id === ALVEY_CHAIN_ID)}
                      className="mt-2 bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded transition-colors duration-200 flex items-center disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      {index === 1 && chain?.id === ALVEY_CHAIN_ID ? 'Already on Alveychain' : step.title}
                      {(isAddingNetwork || isAddingToken) && <RefreshCw className="ml-2 animate-spin" size={16} />}
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </GlowingCard>

        <GlowingCard>
          <h3 className="text-2xl font-bold text-orange-500 mb-4 flex items-center">
            <Coins className="mr-2" size={24} /> Start Trading on Alveychain
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <ToolCard
              title="1. Buy wALV on PancakeSwap"
              description={
                <>
                  <p>Start your Alveychain journey by purchasing wALV (Wrapped ALV) on the BSC chain:</p>
                  <ol className="list-decimal list-inside mt-2 text-sm">
                    <li>Visit PancakeSwap on BSC</li>
                    <li>Use this contract address: <code className="bg-gray-700 px-1 py-0.5 rounded text-xs">0x256d1fce1b1221e8398f65f9b36033ce50b2d497</code></li>
                    <li>Swap BNB or any other BSC token for wALV</li>
                    <li>Ensure you have some BNB for gas fees</li>
                  </ol>
                </>
              }
              icon={Coins}
              link="https://pancakeswap.finance/swap?outputCurrency=0x256d1fce1b1221e8398f65f9b36033ce50b2d497"
              linkText="Buy wALV on PancakeSwap"
            />
            <ToolCard
              title="2. Bridge to Alveychain"
              description={
                <>
                  <p>Use the Alvey Bridge to transfer your wALV to native ALV on Alveychain:</p>
                  <ol className="list-decimal list-inside mt-2 text-sm">
                    <li>Visit Alvey Bridge</li>
                    <li>Connect your wallet (ensure you're on BSC)</li>
                    <li>Select wALV as the token to bridge</li>
                    <li>Choose the amount and confirm the transaction</li>
                    <li>Wait for the bridging process to complete</li>
                  </ol>
                </>
              }
              icon={ArrowRightLeft}
              link="https://alveybridge.com/"
              linkText="Go to Alvey Bridge"
            />
            <ToolCard
              title="3. Trade on ElvesDEX"
              description={
                <>
                  <p>Start trading on Alveychain using ElvesDEX:</p>
                  <ol className="list-decimal list-inside mt-2 text-sm">
                    <li>Switch your wallet to Alveychain network</li>
                    <li>Visit ElvesDEX</li>
                    <li>Connect your wallet</li>
                    <li>Use your bridged ALV to swap for other tokens</li>
                    <li>Explore providing liquidity for extra rewards</li>
                  </ol>
                  <p className="mt-2 text-sm">Tip: Always check token contracts and do your own research before trading!</p>
                </>
              }
              icon={ArrowRightLeft}
              link="https://elvesdex.com/swap"
              linkText="Trade on ElvesDEX"
            />
          </div>
        </GlowingCard>

        <div>
          <h2 className="text-3xl font-bold text-orange-500 mb-6 flex items-center">
            <Globe className="mr-2" size={32} /> Featured Alveychain Projects
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            <ProjectCard
              title="ICARUS"
              logo="/icarus-logo.png"
              banner="/icarus-banner.jpg"
              description="Community Growth Token focused on creating substantial value for all members."
              website="https://icaruz.space"
              telegram="https://t.me/icarus_community"
              buyLink="https://old.elvesdex.com/swap?outputCurrency=0x34195f8dfd55b0abd159001ff5ade6e284bb0fdd"
            />
            <ProjectCard
              title="SUPAH"
              logo="/supah-logo.png"
              banner="/supah-banner.jpg"
              description="Experimental Meme token launched by Icarus in celebration of the Alvey Subgraph and CMC listing."
              website="https://icaruz.space"
              telegram="https://t.me/icarus_community"
              buyLink="https://elvesdex.com/swap?outputCurrency=0x597261e7b92a5fd493154b2875dee58bf68e3fcc"
            />
            <ProjectCard
              title="Quark"
              logo="/quark-logo.png"
              banner="/quark-banner.jpg"
              description="Cornerstone project on Alveychain, support NFT Market, Buybot, Launchpad, API's, and More"
              website="quarktoken.io"
              telegram="https://t.me/QuarkEntryPortal"
              buyLink="https://old.elvesdex.com/swap?outputCurrency=0xa0c0e4a09715e7b8e03adeb5699628b7ae3ed8ed"
            />
            <ProjectCard
              title="Balin"
              logo="/balin-logo.png"
              banner="/balin-banner.jpg"
              description="A groundbreaking fusion of meme culture and decentralized finance brought to you by the innovative minds behind Alvey Chain."
              website="https://balinbank.com/"
              telegram="https://t.me/BALiNBANK"
              buyLink="https://elvesdex.com/swap?outputCurrency=0xd7034d58ee1ca005514f4a446aa95630ddd17fb4"
            />
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default AlveySetup;

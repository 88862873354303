import React, { useState, useCallback } from 'react';
import { Activity, AlertTriangle, Search, Info } from 'lucide-react';
import NetworkOfInteractions from './tools/NetworkOfInteractions';
import LoadingAnimation from './LoadingAnimation';
import { Tooltip } from 'react-tooltip';

const NOI = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [walletAddress, setWalletAddress] = useState('');
  const [networkData, setNetworkData] = useState(null);

  const fetchData = useCallback(async () => {
    if (!walletAddress) {
      setError('Please enter a wallet address');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const [transactionsResponse, tokenTransfersResponse] = await Promise.all([
        fetch(`https://api.alveyscan.com/api/v2/addresses/${walletAddress}/transactions`),
        fetch(`https://api.alveyscan.com/api/v2/addresses/${walletAddress}/token-transfers`)
      ]);

      if (!transactionsResponse.ok || !tokenTransfersResponse.ok) {
        throw new Error('Failed to fetch data from Alveyscan API');
      }

      const transactions = await transactionsResponse.json();
      const tokenTransfers = await tokenTransfersResponse.json();

      if (!transactions.items || !tokenTransfers.items) {
        throw new Error('Invalid data received from Alveyscan API');
      }

      setNetworkData({
        transactions: transactions.items,
        tokenTransfers: tokenTransfers.items,
      });
    } catch (err) {
      console.error('Error fetching wallet data:', err);
      setError(`Failed to fetch wallet data: ${err.message}`);
    } finally {
      setLoading(false);
    }
  }, [walletAddress]);

  return (
    <div className="bg-gray-900 text-white p-6 min-h-screen flex flex-col">
      <h2 className="text-4xl font-bold text-orange-500 mb-8 flex items-center">
        <Activity className="mr-4" size={36} /> Network of Interactions
        <Info
          className="ml-2 cursor-help"
          size={20}
          data-tooltip-id="noi-info"
          data-tooltip-content="Visualize the network of interactions for a given wallet address on the Alvey Chain."
        />
        <Tooltip id="noi-info" />
      </h2>

      <div className="mb-8 flex flex-wrap gap-4">
        <div className="flex-grow">
          <input
            type="text"
            value={walletAddress}
            onChange={(e) => setWalletAddress(e.target.value)}
            placeholder="Enter wallet address"
            className="bg-gray-800 text-white p-3 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-orange-500"
          />
        </div>
        <button
          onClick={fetchData}
          className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-3 px-6 rounded-lg flex items-center transition duration-300 ease-in-out transform hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={loading || !walletAddress}
        >
          {loading ? (
            <>
              <LoadingAnimation size={20} />
              <span className="ml-2">Analyzing...</span>
            </>
          ) : (
            <>
              <Search className="mr-2" size={20} />
              Analyze Wallet
            </>
          )}
        </button>
      </div>

      {error && (
        <div className="bg-red-900 border-l-4 border-red-500 text-red-300 p-4 mb-6 rounded-lg" role="alert">
          <AlertTriangle className="inline-block mr-2" size={20} />
          {error}
        </div>
      )}

      {loading ? (
        <div className="flex-grow flex items-center justify-center">
          <LoadingAnimation />
        </div>
      ) : networkData ? (
        <div className="flex-grow">
          <NetworkOfInteractions
            initialTransactions={networkData.transactions}
            initialTokenTransfers={networkData.tokenTransfers}
            alvContractAddress={walletAddress}
            onError={setError}
          />
        </div>
      ) : (
        <div className="flex-grow flex items-center justify-center text-gray-500">
          <p>Enter a wallet address and click "Analyze Wallet" to view the network of interactions.</p>
        </div>
      )}
    </div>
  );
};

export default NOI;
import React from 'react';
import { BarChart } from 'lucide-react';

const IcarusChart = () => {
  return (
    <section className="bg-gray-900 rounded-lg shadow-md p-6 mt-8">
      <h2 className="text-2xl font-bold mb-6 text-orange-500 flex items-center">
        <BarChart className="mr-2" /> ICARUS Trading Charts
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="chart-container">
          <h3 className="text-xl font-bold text-white mb-4">ICA BSC Chart</h3>
          <iframe
            id="dextools-widget-bsc"
            title="DEXTools Trading Chart BSC"
            width="100%"
            height="400"
            src="https://www.dextools.io/widget-chart/en/bnb/pe-light/0xf9c399975a332d911c6f90201fcab4569b72c98e?theme=dark&chartType=2&chartResolution=30&drawingToolbars=false"
            style={{ border: 'none', borderRadius: '8px' }}
          ></iframe>
        </div>
        <div className="chart-container">
          <h3 className="text-xl font-bold text-white mb-4">ICA Alvey Chart</h3>
          <iframe
            id="dextools-widget-alvey"
            title="DEXTools Trading Chart Alvey"
            width="100%"
            height="400"
            src="https://www.dextools.io/widget-chart/en/alvey/pe-light/0x0c53653848b2e15d861e5a941d620023b5b543cf?theme=dark&chartType=2&chartResolution=30&drawingToolbars=false"
            style={{ border: 'none', borderRadius: '8px' }}
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default IcarusChart;
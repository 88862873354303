import React from 'react';

const GemPadStaking = () => {
  return (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
      <h3 className="text-2xl font-bold text-orange-500 mb-4">GemPad Staking Pool</h3>
      <div className="w-full max-w-[400px]">
        <iframe 
          width="100%" 
          height="520" 
          frameBorder="0" 
          scrolling="no" 
          src="https://gempad.app/embed?address=0x01864EC020105E6d5E8987A34e84Dba747F77eBA&bgColor=%23ffffff&fgColor=%23ffffff&hasAnimation=true&hasBanner=true&hasSocialLinks=true&network=BNB_Smart_Chain&padding=38&refer=&responsive=true&saleType=staking&theme=dark&txtColor=%23262626" 
          title="GemPad Staking"
          className="w-full"
        />
      </div>
    </div>
  );
};

export default GemPadStaking;
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FileText, RefreshCw, Copy, DollarSign, Check, X, Droplet, ExternalLink, Clock, Link as LinkIcon, ChartLine, AlertTriangle, Volume2, VolumeX, User, FileCode, Bell, BellOff } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';
import { formatNumber, formatAddress } from '../../utils/numberUtils';
import { Tooltip } from 'react-tooltip';
import QuarkUtils from '../../utils/QuarkUtils';
import { config } from '../../config';
import LoadingAnimation from '../LoadingAnimation';
import { useNotifications } from '../../hooks/useNotifications';
import classNames from 'classnames';
import styles from './NewTokensList.module.css';

const COLORS = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F7DC6F', '#B19CD9'];
const CACHE_KEY = 'alvey_token_list';
const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
const ALVEY_API_BASE_URL = 'https://api.alveyscan.com/api/v2';
const REFRESH_INTERVAL = 5 * 60 * 1000; // 5 minutes

const NewTokensList = () => {
  const [tokens, setTokens] = useState([]);
  const [tokenDetails, setTokenDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [newTokens, setNewTokens] = useState(new Set());
  const [newLiquidityPairs, setNewLiquidityPairs] = useState(new Set());
  const [isMuted, setIsMuted] = useState(false);
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [lastRefreshTime, setLastRefreshTime] = useState(null);

  const audioRef = useRef(new Audio('/notification.mp3'));
  const { triggerNotification, permissionGranted } = useNotifications();

  const playNotificationSound = useCallback(() => {
    if (!isMuted && (newTokens.size > 0 || newLiquidityPairs.size > 0)) {
      audioRef.current.play().catch(error => console.error("Error playing sound:", error));
    }
  }, [isMuted, newTokens, newLiquidityPairs]);

  const toggleMute = () => {
    setIsMuted(!isMuted);
    audioRef.current.muted = !isMuted;
  };

  const toggleNotifications = () => {
    const newState = !notificationsEnabled;
    setNotificationsEnabled(newState);
    if (permissionGranted) {
      triggerNotification(
        newState ? 'Notifications Enabled' : 'Notifications Disabled',
        {
          body: newState ? 'You will now receive notifications for new tokens and liquidity pairs.' : 'You will no longer receive notifications.',
          icon: '/logo192.png'
        }
      );
    }
  };

  const fetchTokenDetails = async (tokenAddress) => {
    try {
      const tokenInfo = await QuarkUtils.getToken(config.alveychainDec, tokenAddress);

      if (tokenInfo.detail === "Token not found") {
        return null;
      }

      const pairsData = await QuarkUtils.getTokenPairs(config.alveychainDec, tokenAddress, { limit: 10, offset: 0 });
      const mainPair = pairsData.reduce((prev, current) => (prev.liquidity > current.liquidity ? prev : current), pairsData[0]);

      return {
        tokenName: tokenInfo.name,
        price: tokenInfo.price_usd,
        circSupply: tokenInfo.crc_supply,
        marketCap: tokenInfo.mcap,
        mainPair: mainPair ? `${mainPair.name}` : 'N/A',
        liquidity: mainPair ? mainPair.liquidity : 0,
        isVerified: tokenInfo.is_verified,
        hasLiquidity: !!mainPair,
        isPotentiallyScam: (tokenInfo.price_usd > 0 && (!mainPair || mainPair.liquidity < 100))
      };
    } catch (error) {
      console.error(`Error fetching details for token ${tokenAddress}:`, error);
      return null;
    }
  };

  const fetchNewTokens = useCallback(async () => {
    if (isRefreshing) return;

    setIsRefreshing(true);
    setError(null);

    try {
      const response = await fetch(`${ALVEY_API_BASE_URL}/transactions?type=contract_creation&limit=10`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      const newTokens = data.items
        .filter(tx => tx.created_contract && tx.created_contract.name)
        .map(tx => ({
          timestamp: new Date(tx.timestamp),
          tokenAddress: tx.created_contract.hash,
          creator: tx.from.hash,
        }));

      const validTokens = [];
      const details = {};
      const newTokenAddresses = new Set();
      const newLiquidityPairAddresses = new Set();

      for (const token of newTokens) {
        const tokenDetail = await fetchTokenDetails(token.tokenAddress);
        if (tokenDetail) {
          details[token.tokenAddress] = tokenDetail;
          if (!tokenDetails[token.tokenAddress]) {
            newTokenAddresses.add(token.tokenAddress);
            if (notificationsEnabled && permissionGranted) {
              triggerNotification('New Token Created', {
                body: `New token ${tokenDetail.tokenName} has been created`,
                icon: '/logo192.png'
              });
            }
          } else if (!tokenDetails[token.tokenAddress].hasLiquidity && tokenDetail.hasLiquidity) {
            newLiquidityPairAddresses.add(token.tokenAddress);
            if (notificationsEnabled && permissionGranted) {
              triggerNotification('New Liquidity Pool', {
                body: `Liquidity pool added for ${tokenDetail.tokenName}`,
                icon: '/logo192.png'
              });
            }
          }
          validTokens.push({ ...token, isNew: !tokenDetails[token.tokenAddress] });
        }
      }

      setTokens(validTokens);
      setTokenDetails(prevDetails => ({ ...prevDetails, ...details }));
      setNewTokens(newTokenAddresses);
      setNewLiquidityPairs(newLiquidityPairAddresses);

      if (newTokenAddresses.size > 0 || newLiquidityPairAddresses.size > 0) {
        playNotificationSound();
      }

      setLastUpdated(new Date());
      setLastRefreshTime(new Date());
    } catch (err) {
      console.error('Error fetching new tokens:', err);
      setError('Failed to fetch new tokens. Please try again later.');
    } finally {
      setIsRefreshing(false);
      setIsLoading(false);
    }
  }, [tokenDetails, playNotificationSound, isRefreshing, notificationsEnabled, permissionGranted, triggerNotification]);

  useEffect(() => {
    fetchNewTokens();
    const intervalId = setInterval(() => {
      const now = new Date();
      if (lastRefreshTime && now - lastRefreshTime >= REFRESH_INTERVAL) {
        fetchNewTokens();
      }
    }, 60000); // Check every minute
    return () => clearInterval(intervalId);
  }, [fetchNewTokens, lastRefreshTime]);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        console.log('Address copied to clipboard:', text);
      })
      .catch(err => {
        console.error('Failed to copy address:', err);
      });
  };

  const handleRefreshProject = async (tokenAddress) => {
    setTokenDetails(prevDetails => ({
      ...prevDetails,
      [tokenAddress]: { ...prevDetails[tokenAddress], isRefreshing: true }
    }));

    const updatedDetails = await fetchTokenDetails(tokenAddress);

    if (updatedDetails) {
      setTokenDetails(prevDetails => ({
        ...prevDetails,
        [tokenAddress]: { ...updatedDetails, isRefreshing: false }
      }));
    } else {
      setTokens(prevTokens => prevTokens.filter(token => token.tokenAddress !== tokenAddress));
      setTokenDetails(prevDetails => {
        const newDetails = { ...prevDetails };
        delete newDetails[tokenAddress];
        return newDetails;
      });
    }
  };

  if (isLoading && !isRefreshing) {
    return <LoadingAnimation />;
  }

  return (
    <div className="bg-gray-900 p-3 rounded-lg shadow-lg mt-6 relative">
      <h3 className="text-xl font-bold text-orange-500 mb-3 flex items-center">
        <FileText className="mr-2" size={20} /> Latest Alvey Projects
      </h3>
      <div className="absolute top-3 right-3 flex items-center">
        {lastUpdated && !isRefreshing && (
          <span className="text-gray-400 mr-2 flex items-center text-xs">
            <Clock size={12} className="mr-1" />
            {formatDistanceToNow(lastUpdated, { addSuffix: true })}
          </span>
        )}
        <button
          onClick={toggleMute}
          className="bg-gray-800 hover:bg-gray-700 text-white p-1 rounded mr-1 flex items-center transition-colors duration-300"
        >
          {isMuted ? <VolumeX size={14} /> : <Volume2 size={14} />}
        </button>
        <button
          onClick={toggleNotifications}
          className={`bg-gray-800 hover:bg-gray-700 text-white p-1 rounded mr-1 flex items-center transition-colors duration-300 ${notificationsEnabled ? 'text-green-500' : 'text-gray-400'}`}
        >
          {notificationsEnabled ? <Bell size={14} /> : <BellOff size={14} />}
        </button>
        <button
          onClick={fetchNewTokens}
          className="bg-orange-500 hover:bg-orange-600 text-white p-1 rounded flex items-center transition-colors duration-300"
          disabled={isRefreshing}
        >
          <RefreshCw className={`${isRefreshing ? 'animate-spin' : ''}`} size={14} />
        </button>
      </div>
      {tokens.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="w-full table-auto text-xs">
            <thead>
              <tr className="bg-orange-500 text-white leading-normal">
                <th className="py-2 px-1 text-left">Time</th>
                <th className="py-2 px-1 text-left">Token</th>
                <th className="py-2 px-1 text-right">Price</th>
                <th className="hidden sm:table-cell py-2 px-1 text-right">Market Cap</th>
                <th className="hidden md:table-cell py-2 px-1 text-right">Liquidity</th>
                <th className="hidden lg:table-cell py-2 px-1 text-right">Main Pair</th>
                <th className="py-2 px-1 text-center">Actions</th>
              </tr>
            </thead>
            <tbody className="text-gray-300 font-light">
              {tokens.map((token, index) => {
                const details = tokenDetails[token.tokenAddress] || {};
                const isNewToken = newTokens.has(token.tokenAddress);
                const hasNewLiquidity = newLiquidityPairs.has(token.tokenAddress);
                return (
                  <tr 
                    key={index} 
                    className={classNames(
                      "border-b border-gray-700 hover:bg-gray-800 transition-colors duration-300",
                      {
                        'bg-gray-800': index % 2 === 0,
                        'bg-gray-900': index % 2 !== 0,
                        [styles.blinkBg]: isNewToken || hasNewLiquidity
                      }
                    )}
                  >
                    <td className="py-2 px-1 text-left whitespace-nowrap">
                      {formatDistanceToNow(token.timestamp, { addSuffix: true })}
                    </td>
                    <td className="py-2 px-1 text-left">
                      <div className="flex items-center">
                        <span className="truncate max-w-[80px] sm:max-w-[120px] md:max-w-[160px]">
                          {details.tokenName || 'N/A'}
                        </span>
                        {details.isPotentiallyScam && (
                          <AlertTriangle size={12} className="ml-1 text-red-500" data-tooltip-id={`scam-warning-${index}`} />
                        )}
                        <Tooltip id={`scam-warning-${index}`} content="Potential scam. Use caution." />
                      </div>
                    </td>
                    <td className="py-2 px-1 text-right">
                      {details.price ? formatNumber(details.price, 'lowPriceUSD') : 'N/A'}
                    </td>
                    <td className="hidden sm:table-cell py-2 px-1 text-right">
                      {details.marketCap ? formatNumber(details.marketCap, 'dollarRound') : 'N/A'}
                    </td>
                    <td className="hidden md:table-cell py-2 px-1 text-right">
                      {details.liquidity ? formatNumber(details.liquidity, 'dollarRound') : 'N/A'}
                    </td>
                    <td className="hidden lg:table-cell py-2 px-1 text-right">
                      {details.mainPair || 'N/A'}
                    </td>
                    <td className="py-2 px-1 text-center">
                      <div className="flex items-center justify-center space-x-1">
                        <a
                          href={`https://elvesdex.com/swap?outputCurrency=${token.tokenAddress}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-green-500 hover:text-green-400 transition-colors duration-300"
                          data-tooltip-id="buy-tooltip"
                        >
                          <DollarSign size={14} />
                        </a>
                        <Tooltip id="buy-tooltip" content="Buy on ElvesDex" />
						<a
                          href={`https://alveyscan.com/address/${token.creator}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-400 hover:text-blue-300 transition-colors duration-300"
                          data-tooltip-id={`creator-${index}`}
                        >
                          <User size={14} />
                        </a>
                        <Tooltip id={`creator-${index}`} content={`Creator: ${formatAddress(token.creator)}`} />

                        <a
                          href={`https://alveyscan.com/token/${token.tokenAddress}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-purple-400 hover:text-purple-300 transition-colors duration-300"
                          data-tooltip-id={`contract-${index}`}
                        >
                          <FileCode size={14} />
                        </a>
                        <Tooltip id={`contract-${index}`} content={`Contract: ${formatAddress(token.tokenAddress)}`} />

                        {details.hasLiquidity ? (
                          <Droplet size={14} className="text-blue-500" data-tooltip-id={`liquidity-${index}`} />
                        ) : (
                          <Droplet size={14} className="text-red-500" data-tooltip-id="no-liquidity-tooltip" />
                        )}
                        <Tooltip id={`liquidity-${index}`} content={`Liquidity Pool: ${details.mainPair}`} />
                        <Tooltip id="no-liquidity-tooltip" content="No Liquidity Pairs Found" />

                        <a
                          href={`https://www.dextools.io/app/en/alvey/pair-explorer/${token.tokenAddress}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-yellow-500 hover:text-yellow-400 transition-colors duration-300"
                          data-tooltip-id="dextools-tooltip"
                        >
                          <ChartLine size={14} />
                        </a>
                        <Tooltip id="dextools-tooltip" content="View on DexTools" />

                        {details.isVerified ? (
                          <Check size={14} className="text-green-500" data-tooltip-id="verified-tooltip" />
                        ) : (
                          <X size={14} className="text-red-500" data-tooltip-id="unverified-tooltip" />
                        )}
                        <Tooltip id="verified-tooltip" content="Contract Verified" />
                        <Tooltip id="unverified-tooltip" content="Contract Not Verified" />

                        <button
                          onClick={() => handleRefreshProject(token.tokenAddress)}
                          className={`text-blue-500 hover:text-blue-400 transition-colors duration-300 ${details.isRefreshing ? 'animate-spin' : ''}`}
                          disabled={details.isRefreshing}
                          data-tooltip-id="refresh-tooltip"
                        >
                          <RefreshCw size={14} />
                        </button>
                        <Tooltip id="refresh-tooltip" content="Refresh Project Data" />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-gray-300">No new token creations found.</p>
      )}
      {error && (
        <div className="mt-4 p-4 bg-red-900 text-white rounded-lg">
          <AlertTriangle className="inline-block mr-2" size={20} />
          {error}
        </div>
      )}
    </div>
  );
};

export default NewTokensList;
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';
import { format, parseISO } from 'date-fns';
import { motion, AnimatePresence } from 'framer-motion';
import { BarChart2, RefreshCw, AlertCircle, ExternalLink, X } from 'lucide-react';
import { formatNumber } from '../../utils/numberUtils';

const ALVEY_API_BASE_URL = 'https://api.alveyscan.com/api/v2';
const QUARK_API_BASE_URL = 'https://cryptoapi.quarkproject.com/api/v1/chain/3797';

const BalanceHistoryChart = ({ walletAddress }) => {
  const [balanceHistory, setBalanceHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const fetchBalanceHistory = useCallback(async (address) => {
    setIsLoading(true);
    setError(null);

    try {
      const url = `${ALVEY_API_BASE_URL}/addresses/${address}/coin-balance-history`;
      const response = await fetch(url);
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      setBalanceHistory(data.items || []);
    } catch (err) {
      console.error('Error fetching balance history:', err);
      setError('Failed to fetch balance history. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (walletAddress) {
      fetchBalanceHistory(walletAddress);
    }
  }, [walletAddress, fetchBalanceHistory]);

  const chartData = useMemo(() => {
    return balanceHistory
      .sort((a, b) => new Date(a.block_timestamp) - new Date(b.block_timestamp))
      .map(item => ({
        date: format(new Date(item.block_timestamp), 'MMM dd'),
        balance: parseFloat(item.value) / 1e18,
        transaction_hash: item.transaction_hash
      }));
  }, [balanceHistory]);

  const handleDataPointClick = useCallback(async (data) => {
    if (data && data.transaction_hash) {
      try {
        const txResponse = await fetch(`${ALVEY_API_BASE_URL}/transactions/${data.transaction_hash}`);
        const txData = await txResponse.json();

        let outgoingToken = null;
        let incomingToken = null;

        if (txData.token_transfers && txData.token_transfers.length > 0) {
          txData.token_transfers.forEach(transfer => {
            if (transfer.from.hash.toLowerCase() === walletAddress.toLowerCase()) {
              outgoingToken = transfer;
            } else if (transfer.to.hash.toLowerCase() === walletAddress.toLowerCase()) {
              incomingToken = transfer;
            }
          });
        }

        let outgoingTokenPrice = null;
        let incomingTokenPrice = null;

        if (outgoingToken) {
          const outgoingPriceResponse = await fetch(`${QUARK_API_BASE_URL}/token/${outgoingToken.token.address}/price`);
          const outgoingPriceData = await outgoingPriceResponse.json();
          outgoingTokenPrice = outgoingPriceData.price;
        }

        if (incomingToken) {
          const incomingPriceResponse = await fetch(`${QUARK_API_BASE_URL}/token/${incomingToken.token.address}/price`);
          const incomingPriceData = await incomingPriceResponse.json();
          incomingTokenPrice = incomingPriceData.price;
        }

        setSelectedTransaction({
          ...txData,
          outgoingToken: outgoingToken ? {
            ...outgoingToken,
            price: outgoingTokenPrice
          } : null,
          incomingToken: incomingToken ? {
            ...incomingToken,
            price: incomingTokenPrice
          } : null
        });
      } catch (error) {
        console.error('Error fetching transaction details:', error);
      }
    }
  }, [walletAddress]);

  const TransactionDetailsModal = ({ transaction, onClose }) => {
    if (!transaction) return null;

    return (
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
        >
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 50, opacity: 0 }}
            className="bg-gray-800 rounded-lg p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto"
          >
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold text-orange-500">Transaction Details</h3>
              <button onClick={onClose} className="text-gray-400 hover:text-white">
                <X size={24} />
              </button>
            </div>
            <div className="space-y-4">
              <p className="text-white"><span className="font-bold">Hash:</span> {transaction.hash}</p>
              <p className="text-white"><span className="font-bold">Block:</span> {transaction.block}</p>
              <p className="text-white"><span className="font-bold">Time:</span> {format(new Date(transaction.timestamp), 'yyyy-MM-dd HH:mm:ss')}</p>
              <p className="text-white"><span className="font-bold">From:</span> {transaction.from.hash}</p>
              <p className="text-white"><span className="font-bold">To:</span> {transaction.to.hash}</p>
              <p className="text-white"><span className="font-bold">Value:</span> {formatNumber(parseFloat(transaction.value) / 1e18, 'ALV')} ALV</p>
              {transaction.outgoingToken && (
                <div>
                  <h4 className="text-lg font-semibold text-orange-500 mt-4">Outgoing Token</h4>
                  <p className="text-white"><span className="font-bold">Token:</span> {transaction.outgoingToken.token.symbol}</p>
                  <p className="text-white"><span className="font-bold">Contract:</span> {transaction.outgoingToken.token.address}</p>
                  <p className="text-white"><span className="font-bold">Amount:</span> {formatNumber(parseFloat(transaction.outgoingToken.total.value) / Math.pow(10, transaction.outgoingToken.token.decimals), transaction.outgoingToken.token.symbol)}</p>
                  <p className="text-white"><span className="font-bold">Current Price:</span> ${transaction.outgoingToken.price || 'N/A'}</p>
                </div>
              )}
              {transaction.incomingToken && (
                <div>
                  <h4 className="text-lg font-semibold text-orange-500 mt-4">Incoming Token</h4>
                  <p className="text-white"><span className="font-bold">Token:</span> {transaction.incomingToken.token.symbol}</p>
                  <p className="text-white"><span className="font-bold">Contract:</span> {transaction.incomingToken.token.address}</p>
                  <p className="text-white"><span className="font-bold">Amount:</span> {formatNumber(parseFloat(transaction.incomingToken.total.value) / Math.pow(10, transaction.incomingToken.token.decimals), transaction.incomingToken.token.symbol)}</p>
                  <p className="text-white"><span className="font-bold">Current Price:</span> ${transaction.incomingToken.price || 'N/A'}</p>
                </div>
              )}
            </div>
            <div className="mt-6">
              <a
                href={`https://alveyscan.com/tx/${transaction.hash}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-300 flex items-center"
              >
                View on Alveyscan <ExternalLink size={16} className="ml-1" />
              </a>
            </div>
          </motion.div>
        </motion.div>
      </AnimatePresence>
    );
  };

  if (isLoading) {
    return (
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg mt-6 flex items-center justify-center">
        <RefreshCw className="animate-spin text-orange-500 mr-2" size={24} />
        <p className="text-gray-300">Loading balance history...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg mt-6">
        <h3 className="text-2xl font-bold text-orange-500 mb-4 flex items-center">
          <BarChart2 className="mr-2" size={24} /> Recent ALV Balance
        </h3>
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  return (
    <motion.div
      className="bg-gray-800 p-6 rounded-lg shadow-lg mt-6"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h3 className="text-2xl font-bold text-orange-500 mb-4 flex items-center">
        <BarChart2 className="mr-2" size={24} /> Recent ALV Balance
      </h3>
      {chartData.length > 0 ? (
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={chartData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" stroke="#444" />
            <XAxis
              dataKey="date"
              tick={{ fill: '#9CA3AF' }}
              tickLine={{ stroke: '#4B5563' }}
            />
            <YAxis
              tick={{ fill: '#9CA3AF' }}
              tickLine={{ stroke: '#4B5563' }}
              tickFormatter={value => formatNumber(value, 'ALV')}
            />
            <Tooltip
              contentStyle={{ backgroundColor: '#374151', border: 'none', borderRadius: '8px' }}
              labelStyle={{ color: '#E5E7EB' }}
              formatter={(value) => [formatNumber(value, 'ALV'), 'Balance']}
              labelFormatter={label => format(new Date(label), 'MMM dd, yyyy')}
            />
            <Line
              type="monotone"
              dataKey="balance"
              stroke="#F97316"
              strokeWidth={2}
              dot={false}
              activeDot={{ 
                r: 8, 
                onClick: (_, { payload }) => handleDataPointClick(payload)
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <p className="text-gray-300 text-center">No balance history available.</p>
      )}
      {balanceHistory.length > 0 && (
        <div className="mt-4 text-sm text-gray-400 flex items-center justify-end">
          <span>Last updated: {format(new Date(balanceHistory[balanceHistory.length - 1].block_timestamp), 'MMM dd, yyyy HH:mm:ss')}</span>
        </div>
      )}
      <TransactionDetailsModal
        transaction={selectedTransaction}
        onClose={() => setSelectedTransaction(null)}
      />
    </motion.div>
  );
};

export default BalanceHistoryChart;
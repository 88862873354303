import React from 'react';
import { motion } from 'framer-motion';
import { Feather, Zap, Users, ChartBar, Rocket, ExternalLink, Scan, DollarSign, Calendar, Gift } from 'lucide-react';
import { Link } from 'react-router-dom';
import { config } from '../config';
import ErrorBoundary from './ErrorBoundary';

const FeatureCard = ({ title, description, icon: Icon }) => (
  <motion.div
    className="bg-gray-800 rounded-lg p-6 shadow-lg"
    whileHover={{ scale: 1.05 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <Icon className="text-orange-500 mb-4" size={36} />
    <h3 className="text-xl font-bold text-white mb-2">{title}</h3>
    <p className="text-gray-300">{description}</p>
  </motion.div>
);

const TierCard = ({ title, threshold, gradient }) => (
  <motion.div
    className={`${gradient} rounded-lg p-6 shadow-lg`}
    whileHover={{ scale: 1.05 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <h3 className="text-2xl font-bold text-white mb-2">{title}</h3>
    <p className="text-white">Threshold: {threshold} ICA</p>
  </motion.div>
);

const ProjectScannerCard = () => (
  <motion.div
    className="bg-gradient-to-r from-orange-500 to-yellow-500 rounded-lg p-8 shadow-lg mb-12"
    initial={{ opacity: 0, y: -20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <h2 className="text-3xl font-bold text-white mb-4 flex items-center">
      <Scan className="mr-2" size={36} /> Exclusive Access: Project Scanner
    </h2>
    <p className="text-xl text-white mb-6">
      Get an edge in the crypto market with our powerful Project Scanner. Unlock features like:
    </p>
    <ul className="list-disc list-inside text-white space-y-2 mb-6">
      <li>Real-time monitoring of new token launches</li>
      <li>Comprehensive risk analysis and scoring</li>
      <li>Detailed contract audits and liquidity pool analysis</li>
      <li>Early detection of potential rug pulls and scams</li>
      <li>Customizable alerts for promising projects</li>
    </ul>
    <Link
      to="/project-scanner"
      className="bg-white text-orange-500 font-bold py-3 px-8 rounded-full inline-flex items-center transition-colors duration-200 hover:bg-gray-100"
    >
      Access Project Scanner <ExternalLink size={20} className="ml-2" />
    </Link>
  </motion.div>
);

const BuyButton = ({ chain, contractAddress }) => (
  <a
    href={chain === 'BSC' 
      ? `https://pancakeswap.finance/swap?outputCurrency=${contractAddress}`
      : `https://elvesdex.com/swap?outputCurrency=${contractAddress}`
    }
    target="_blank"
    rel="noopener noreferrer"
    className="bg-gradient-to-r from-blue-500 to-purple-500 text-white font-bold py-2 px-4 rounded-lg inline-flex items-center transition-colors duration-200 hover:from-blue-600 hover:to-purple-600 mt-4"
  >
    Buy ICA on {chain} <ExternalLink size={16} className="ml-2" />
  </a>
);

const IcarusIntroduction = () => {
  return (
    <ErrorBoundary>
      <div className="bg-gray-900 text-white min-h-screen p-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="text-4xl font-bold text-orange-500 mb-8 flex items-center">
            <Feather className="mr-4" size={48} /> Join ICARUS
          </h1>

          <div className="mb-12">
            <h2 className="text-3xl font-bold text-white mb-4">Unlock the Power of ICARUS</h2>
            <p className="text-xl text-gray-300 mb-6">
              To access our advanced toolkit and exclusive features, you need to achieve one of the following tiers:
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
              <TierCard title="Flock" threshold="1,000,000,000" gradient="bg-gradient-to-r from-blue-500 to-blue-700" />
              <TierCard title="Soar" threshold="5,000,000,000" gradient="bg-gradient-to-r from-green-500 to-green-700" />
              <TierCard title="CORE" threshold="9,500,000,000" gradient="bg-gradient-to-r from-yellow-500 to-orange-500" />
            </div>
            <div className="flex justify-center space-x-4">
              <BuyButton chain="BSC" contractAddress={config.contractAddress} />
              <BuyButton chain="Alvey" contractAddress={config.alveyContractAddress} />
            </div>
          </div>

          <div className="mb-12">
            <h2 className="text-3xl font-bold text-white mb-6">Why Join ICARUS?</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              <FeatureCard
                icon={DollarSign}
                title="Native Coin Reflections"
                description="Earn passive income with BNB and ALV reflections just by holding ICA tokens."
              />
              <FeatureCard
                icon={Zap}
                title="Exclusive Features"
                description="Access cutting-edge tools and analytics available only to ICARUS members."
              />
              <FeatureCard
                icon={Users}
                title="Vibrant Community"
                description="Join a thriving ecosystem of like-minded investors and innovators."
              />
              <FeatureCard
                icon={ChartBar}
                title="Advanced Analytics"
                description="Gain insights with our state-of-the-art data analysis and visualization tools."
              />
              <FeatureCard
                icon={Rocket}
                title="Early Access"
                description="Be the first to know about new features, partnerships, and investment opportunities."
              />
              <FeatureCard
                icon={Calendar}
                title="Exciting Events"
                description="Participate in regular community events, competitions, and exclusive giveaways."
              />
            </div>
          </div>

          <div className="text-center">
            <Link
              to="/dashboard"
              className="bg-gradient-to-r from-orange-500 to-yellow-500 text-white font-bold py-3 px-8 rounded-full inline-flex items-center transition-colors duration-200 hover:from-orange-600 hover:to-yellow-600"
            >
              Get Started with ICARUS <ExternalLink size={20} className="ml-2" />
            </Link>
          </div>
        </motion.div>
      </div>
    </ErrorBoundary>
  );
};

export default IcarusIntroduction;
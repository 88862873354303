import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './Sidebar';
import { Menu, X } from 'lucide-react';

const Layout = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="flex min-h-screen bg-background">
      <div className="hidden lg:block lg:fixed lg:inset-y-0 lg:left-0 lg:w-64 lg:overflow-y-auto">
        <Sidebar />
      </div>

      <div className="flex-1 flex flex-col lg:ml-64">
        <div className="lg:hidden fixed top-0 left-0 right-0 z-20 bg-gray-800 p-4 flex justify-between items-center">
          <Link to="/">
            <img src="/logo-tekst-white-600p.png" alt="Icarus Logo" className="h-8" />
          </Link>
          <button onClick={toggleMenu} className="text-white">
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>

        {isMenuOpen && (
          <div className="fixed inset-0 bg-gray-900 bg-opacity-95 z-30 lg:hidden overflow-y-auto">
            <div className="flex flex-col items-center justify-center h-full">
              <button onClick={toggleMenu} className="absolute top-4 right-4 text-white">
                <X size={24} />
              </button>
              <Sidebar mobile closeMenu={toggleMenu} />
            </div>
          </div>
        )}

        <main className="flex-1 p-4 lg:p-6 overflow-y-auto pt-20 lg:pt-6">
          <div className="max-w-full mx-auto">
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Layout;
import React, { useState, useEffect, lazy, Suspense } from 'react';
import { motion } from 'framer-motion';
import { MessageCircle, Rocket, Gamepad, DollarSign, Users, BookOpen, ExternalLink, Award, Zap, Calendar } from 'lucide-react';
import { config } from '../config';
import LoadingAnimation from './LoadingAnimation';
import ErrorBoundary from './ErrorBoundary';

const LazyImage = lazy(() => import('./LazyImage'));

const GlowingCard = ({ children, glowColor = 'rgba(255, 152, 0, 0.5)', className = '' }) => (
  <motion.div
    className={`relative bg-gray-900 rounded-xl overflow-hidden ${className}`}
    whileHover={{ scale: 1.02 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <div className="absolute inset-0 bg-gradient-to-r from-transparent via-gray-800 to-transparent opacity-50" />
    <div className="relative z-10 p-6">
      {children}
    </div>
    <div
      className="absolute inset-0 opacity-0 hover:opacity-100 transition-opacity duration-300"
      style={{
        boxShadow: `0 0 20px ${glowColor}`,
        filter: 'blur(15px)',
        zIndex: -1,
      }}
    />
  </motion.div>
);

const EventTypeDescription = ({ title, description, icon: Icon, banner }) => (
  <GlowingCard className="overflow-hidden">
    <Suspense fallback={<div className="w-full h-48 bg-gray-700 animate-pulse rounded-lg mb-4"></div>}>
      <LazyImage src={banner} alt={title} className="w-full h-48 object-cover mb-4 rounded-lg" />
    </Suspense>
    <div className="flex items-center mb-4">
      <Icon className="text-orange-500 mr-4" size={32} />
      <h3 className="text-2xl font-bold text-white">{title}</h3>
    </div>
    <p className="text-gray-300">{description}</p>
  </GlowingCard>
);

const PromotionalCard = ({ title, description, icon: Icon, link, linkText }) => (
  <GlowingCard className="mb-4">
    <div className="flex items-center mb-4">
      <Icon className="text-orange-500 mr-4" size={24} />
      <h3 className="text-xl font-bold text-white">{title}</h3>
    </div>
    <p className="text-gray-300 mb-4">{description}</p>
    <div className="flex justify-end">
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 text-white font-bold py-2 px-4 rounded-lg inline-flex items-center transition-colors duration-200"
      >
        {linkText} <ExternalLink size={16} className="ml-2" />
      </a>
    </div>
  </GlowingCard>
);

const TelegramCard = () => (
  <GlowingCard className="bg-gradient-to-r from-blue-600 to-purple-600 mb-4">
    <div className="flex items-center mb-4">
      <MessageCircle className="text-white mr-4" size={24} />
      <h3 className="text-xl font-bold text-white">Join Our Telegram</h3>
    </div>
    <p className="text-white mb-4">Connect with the ICARUS community, participate in discussions, and stay updated on the latest news and events!</p>
    <div className="flex justify-end">
      <a
        href="https://t.me/ICARUS_Portal"
        target="_blank"
        rel="noopener noreferrer"
        className="bg-white text-blue-600 font-bold py-2 px-4 rounded-lg inline-flex items-center transition-colors duration-200 hover:bg-gray-100"
      >
        Join Telegram <ExternalLink size={16} className="ml-2" />
      </a>
    </div>
  </GlowingCard>
);

const IntroductionCard = () => (
  <GlowingCard className="mb-8">
    <div className="flex flex-col md:flex-row">
      <div className="md:w-1/2 pr-6">
        <h2 className="text-3xl font-bold text-orange-500 mb-4">Welcome to ICARUS Events</h2>
        <p className="text-gray-300">
          At ICARUS, we believe in creating a robust, engaging environment where community members can connect, learn, and grow together. Our event lineup is crafted with the dual goals of enhancing user experience and fostering a deeper understanding of the crypto ecosystem. Here's what our participants can look forward to:
        </p>
      </div>
      <div className="md:w-1/2 mt-4 md:mt-0">
        <Suspense fallback={<div className="w-full h-full bg-gray-700 animate-pulse rounded-lg"></div>}>
          <LazyImage src="/bridge.png" alt="ICARUS Bridge" className="w-full h-full object-cover rounded-lg" />
        </Suspense>
      </div>
    </div>
  </GlowingCard>
);

const Events = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <LoadingAnimation />;
  }

  return (
    <ErrorBoundary>
      <div className="w-full px-4 sm:px-6 lg:px-8 py-8 bg-gray-900">
        <h1 className="text-4xl font-bold text-orange-500 mb-8 flex items-center">
          <Calendar className="mr-4" size={36} /> ICARUS Events
        </h1>
        <div className="flex flex-col lg:flex-row gap-8">
          <div className="lg:w-3/4">
            <IntroductionCard />

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
              <EventTypeDescription
                title="Chat2Earn"
                description="Dive into our vibrant community through our Chat2Earn program on Telegram. This initiative rewards active participation with $ICA tokens."
                icon={MessageCircle}
                banner="/banner-chat2earn.png"
              />
              <EventTypeDescription
                title="Raiding"
                description="Participate in our raiding events where the community comes together to increase the visibility and impact of ICARUS in the crypto landscape."
                icon={Rocket}
                banner="/banner-raid2.png"
              />
              <EventTypeDescription
                title="Gaming"
                description="Merge the thrill of gaming with blockchain technology. Our gaming events come with the potential to earn crypto rewards."
                icon={Gamepad}
                banner="/banner-games.png"
              />
              <EventTypeDescription
                title="Buy Comps"
                description="Challenge yourself in Buy Comps, where your investment acumen can lead to substantial rewards based on timing and market strategies."
                icon={DollarSign}
                banner="/banner-buycomp.png"
              />
              <EventTypeDescription
                title="AMA Sessions"
                description="Gain exclusive insights during our AMA sessions, featuring industry experts, crypto influencers, and the ICARUS development team."
                icon={Users}
                banner="/banner-ama.png"
              />
              <EventTypeDescription
                title="Project Reviews"
                description="Our in-depth project reviews provide critical analysis of both burgeoning and established crypto projects."
                icon={Zap}
                banner="/banner-science.png"
              />
              <EventTypeDescription
                title="Educational Content"
                description="Empower yourself with our extensive range of educational content that caters to all levels of crypto enthusiasts."
                icon={BookOpen}
                banner="/banner-edu.png"
              />
              <GlowingCard className="bg-gradient-to-r from-orange-500 to-yellow-500">
                <h2 className="text-2xl font-bold text-white mb-4">Get Involved!</h2>
                <p className="text-white mb-6">
                  Are you ready to deepen your engagement with the crypto world? Join ICARUS today and start your journey. Each event is designed to support your growth in the crypto space and to enhance your understanding and enjoyment of this dynamic field.
                </p>
                <div className="flex justify-end">
                  <a
                    href="https://t.me/ICARUS_Portal"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-white text-orange-500 font-bold py-3 px-6 rounded-full inline-flex items-center transition-colors duration-200 hover:bg-gray-100"
                  >
                    Join ICARUS Community <ExternalLink size={20} className="ml-2" />
                  </a>
                </div>
              </GlowingCard>
            </div>
          </div>

          <div className="lg:w-1/4">
            <TelegramCard />
            <PromotionalCard
              title="Current Chat2Earn Campaign"
              description="Join our ongoing Chat2Earn campaign and start earning $ICA tokens today!"
              icon={MessageCircle}
              link="#"
              linkText="Join Campaign"
            />
            <PromotionalCard
              title="KOL Program"
              description="Become a Key Opinion Leader for ICARUS and unlock exclusive benefits."
              icon={Award}
              link={`https://zealy.io/c/${config.zealyApiUrl.split('/').pop()}`}
              linkText="Join Zealy"
            />
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default Events;
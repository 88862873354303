import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { WagmiConfig } from 'wagmi';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import '@rainbow-me/rainbowkit/styles.css';
import { wagmiConfig, chains } from './utils/wagmi';
import Layout from './components/Layout';
import LandingPage from './components/LandingPage';
import Dashboard from './components/Dashboard';
import NFTMint from './components/NFTMint';
import Staking from './components/Staking';
import Core from './components/Core';
import Events from './components/Events';
import Acolytes from './components/Acolytes';
import Treasury from './components/Treasury';
import WalletActivityVisualization from './components/tools/WalletActivityVisualization';
import AlveyChainMonitor from './components/tools/AlveyChainMonitor';
import ProjectScanner from './components/ProjectScanner';
import NOI from './components/NOI';
import UserProfile from './components/UserProfile';
import { darkTheme } from '@rainbow-me/rainbowkit';
import ErrorBoundary from './components/ErrorBoundary';
import ProjectAnalyzer from './components/tools/ProjectAnalyzer';
import AlveySetup from './components/tools/AlveySetup';
import IcaSwap from './components/IcaSwap';
import AirdropTool from './components/tools/AirdropTool';
import ContractInteractionTool from './components/tools/ContractInteractionTool';



const customDarkTheme = darkTheme({
  accentColor: '#f97316',
  accentColorForeground: 'white',
  borderRadius: 'small',
  fontStack: 'system',
  overlayBlur: 'small',
});

const App = () => {
  return (
    <>
      <style jsx global>{`
        [data-rk] .ju367v4 { font-size: 0.9em; }
        [data-rk] .ju367v1c { padding: 16px; }
        [data-rk] .ju367v11 { gap: 8px; }
      `}</style>
	   <ErrorBoundary>
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider chains={chains} theme={customDarkTheme}>
          <Router>
            <Layout>
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/dashboard" element={<Dashboard />} />
				<Route path="/icaswap" element={<IcaSwap />} />
                <Route path="/nft-mint" element={<NFTMint />} />
                <Route path="/staking" element={<Staking />} />
                <Route path="/core" element={<Core />} />
                <Route path="/acolytes" element={<Acolytes />} />
                <Route path="/treasury" element={<Treasury />} />
                <Route path="/events" element={<Events />} />
                <Route path="/tools/wallet-activity" element={<WalletActivityVisualization />} />
                <Route path="/tools/alvey-monitor" element={<AlveyChainMonitor />} />
                <Route path="/tools/project-scanner" element={<ProjectScanner />} />
                <Route path="/tools/network-of-interactions" element={<NOI />} />
				<Route path="/profile" element={<UserProfile />} />
				<Route path="/tools/projectanalyzer" element={<ProjectAnalyzer />} />
				<Route path="/tools/alveysetup" element={<AlveySetup />} />
				<Route path="/tools/airdrop" element={<AirdropTool />} />
				<Route path="/tools/contract-interaction" element={<ContractInteractionTool />} />
              </Routes>
            </Layout>
          </Router>
        </RainbowKitProvider>
      </WagmiConfig>
	   </ErrorBoundary>
    </>
  );
};

export default App;
import React, { useState, useEffect } from 'react';
import { useAccount, useContractRead, useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';
import { parseEther, formatEther } from 'viem';
import { Coins, ExternalLink, MessageCircle, AlertTriangle } from 'lucide-react';
import { config } from '../config';
import { STAKING_ABI } from '../utils/stakingABI';

const StakingCard = ({ contractAddress }) => {
  const { address } = useAccount();
  const [stakeAmount, setStakeAmount] = useState('');
  const [unstakeAmount, setUnstakeAmount] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);

  const { data: stakedAmount } = useContractRead({
    address: contractAddress,
    abi: STAKING_ABI,
    functionName: 'balanceOf',
    args: [address],
    watch: true,
  });

  const { data: rewards } = useContractRead({
    address: contractAddress,
    abi: STAKING_ABI,
    functionName: 'pendingRewards',
    args: [address],
    watch: true,
  });

  const { data: apr } = useContractRead({
    address: contractAddress,
    abi: STAKING_ABI,
    functionName: 'rewardRate',
    watch: true,
  });

  const { data: totalStaked } = useContractRead({
    address: contractAddress,
    abi: STAKING_ABI,
    functionName: 'totalSupply',
    watch: true,
  });

  const { data: hardcap } = useContractRead({
    address: contractAddress,
    abi: STAKING_ABI,
    functionName: 'cap',
    watch: true,
  });

  const { data: stakingDuration } = useContractRead({
    address: contractAddress,
    abi: STAKING_ABI,
    functionName: 'duration',
    watch: true,
  });

  const { config: stakeConfig } = usePrepareContractWrite({
    address: contractAddress,
    abi: STAKING_ABI,
    functionName: 'stake',
    args: [parseEther(stakeAmount || '0')],
    enabled: Boolean(stakeAmount),
  });

  const { write: stake, data: stakeData } = useContractWrite(stakeConfig);

  const { config: unstakeConfig } = usePrepareContractWrite({
    address: contractAddress,
    abi: STAKING_ABI,
    functionName: 'withdraw',
    args: [parseEther(unstakeAmount || '0')],
    enabled: Boolean(unstakeAmount),
  });

  const { write: unstake, data: unstakeData } = useContractWrite(unstakeConfig);

  const { config: claimConfig } = usePrepareContractWrite({
    address: contractAddress,
    abi: STAKING_ABI,
    functionName: 'getReward',
  });

  const { write: claim, data: claimData } = useContractWrite(claimConfig);

  const { isLoading: isStakeLoading, isSuccess: isStakeSuccess } = useWaitForTransaction({
    hash: stakeData?.hash,
  });

  const { isLoading: isUnstakeLoading, isSuccess: isUnstakeSuccess } = useWaitForTransaction({
    hash: unstakeData?.hash,
  });

  const { isLoading: isClaimLoading, isSuccess: isClaimSuccess } = useWaitForTransaction({
    hash: claimData?.hash,
  });

  const handleStake = () => {
    if (stake) {
      stake();
      setStakeAmount('');
    }
  };

  const handleUnstake = () => {
    if (unstake) {
      unstake();
      setUnstakeAmount('');
    }
  };

  const handleClaim = () => {
    if (claim) {
      claim();
    }
  };

  const progressPercentage = totalStaked && hardcap
    ? (Number(formatEther(totalStaked)) / Number(formatEther(hardcap))) * 100
    : 0;

  return (
    <div className="bg-gray-800 rounded-lg shadow-lg overflow-hidden">
      <div className="h-40 bg-cover bg-center relative" style={{ backgroundImage: `url('/dhlt.png')` }}>
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <h3 className="text-2xl font-bold text-white">STAKE ICARUS 60D, EARN 10%</h3>
        </div>
      </div>
      <div className="p-6">
        <p className="text-white mb-2">Stake ICA to Earn DHLT</p>
        <div className="mb-4">
          <div className="flex justify-between text-sm text-gray-400 mb-1">
            <span>Total Staked</span>
            <span>Hardcap</span>
          </div>
          <div className="relative pt-1">
            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-700">
              <div style={{ width: `${progressPercentage}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
            </div>
          </div>
          <div className="flex justify-between text-sm text-white">
            <span>{totalStaked ? formatEther(totalStaked) : '0'} ICA</span>
            <span>{hardcap ? formatEther(hardcap) : '0'} ICA</span>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div className="bg-gray-700 p-3 rounded">
            <p className="text-sm text-gray-400">APR</p>
            <p className="text-lg font-bold text-green-400">{apr ? Number(formatEther(apr)) * 100 : '0'}%</p>
          </div>
          <div className="bg-gray-700 p-3 rounded">
            <p className="text-sm text-gray-400">Duration</p>
            <p className="text-lg font-bold text-white">{stakingDuration ? stakingDuration / (24 * 60 * 60) : '0'} days</p>
          </div>
        </div>
        <button 
          className="w-full bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded mb-4"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? 'Hide Details' : 'Show Details'}
        </button>
        {isExpanded && (
          <div className="space-y-4">
            <div className="bg-gray-700 p-4 rounded">
              <h4 className="text-lg font-bold text-white mb-2">Your Staking Info</h4>
              <p className="text-white">Staked Amount: {stakedAmount ? formatEther(stakedAmount) : '0'} ICA</p>
              <p className="text-white">Pending Rewards: {rewards ? formatEther(rewards) : '0'} DHLT</p>
            </div>
            <div>
              <input
                type="number"
                value={stakeAmount}
                onChange={(e) => setStakeAmount(e.target.value)}
                placeholder="Amount to stake"
                className="w-full p-2 mb-2 bg-gray-700 text-white rounded"
              />
              <button 
                onClick={handleStake} 
                disabled={isStakeLoading}
                className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded mb-2"
              >
                {isStakeLoading ? 'Staking...' : 'Stake'}
              </button>
            </div>
            <div>
              <input
                type="number"
                value={unstakeAmount}
                onChange={(e) => setUnstakeAmount(e.target.value)}
                placeholder="Amount to unstake"
                className="w-full p-2 mb-2 bg-gray-700 text-white rounded"
              />
              <button 
                onClick={handleUnstake} 
                disabled={isUnstakeLoading}
                className="w-full bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded mb-2"
              >
                {isUnstakeLoading ? 'Unstaking...' : 'Unstake'}
              </button>
            </div>
            <button 
              onClick={handleClaim} 
              disabled={isClaimLoading}
              className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
            >
              {isClaimLoading ? 'Claiming...' : 'Claim Rewards'}
            </button>
          </div>
        )}
        <div className="mt-4 flex justify-between text-sm text-gray-400">
          <a href="https://dehealth.com" target="_blank" rel="noopener noreferrer" className="flex items-center">
            <ExternalLink size={16} className="mr-1" /> Website
          </a>
          <a href="https://t.me/dehealthofficial" target="_blank" rel="noopener noreferrer" className="flex items-center">
            <MessageCircle size={16} className="mr-1" /> Telegram
          </a>
        </div>
      </div>
    </div>
  );
};

export default StakingCard;
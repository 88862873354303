import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Trophy, Rocket, Users, Shield, Coins, ChartBar, ExternalLink, Book, MessageCircle } from 'lucide-react';
import { config } from '../config';
import LoadingAnimation from './LoadingAnimation';

const FeatureCard = ({ title, description, icon: Icon, link }) => (
  <motion.div
    className="bg-gray-800 rounded-lg shadow-lg p-6 flex flex-col justify-between"
    whileHover={{ scale: 1.05 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <div>
      <Icon className="text-orange-500 mb-4" size={36} />
      <h3 className="text-xl font-bold text-white mb-2">{title}</h3>
      <p className="text-gray-300 mb-4">{description}</p>
    </div>
    <Link to={link} className="text-orange-500 hover:text-orange-600 font-bold inline-flex items-center">
      Explore <ExternalLink size={16} className="ml-2" />
    </Link>
  </motion.div>
);

const LandingPage = () => {
  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <div className="relative">
        <img src="/icarus-decentra.png" alt="ICARUS Decentralized" className="w-full h-[80vh] rounded-lg shadow-lg" />
        <div className="absolute inset-0 bg-black bg-opacity-0">
          <div className="container mx-auto h-full flex items-center">
            <div className="w-full md:w-2/3 lg:w-1/2 space-y-6">
              <motion.div
                className="bg-gray-900 bg-opacity-45 p-6 rounded-lg"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
              >
                <h1 className="text-4xl md:text-5xl font-bold mb-4">Welcome to ICARUS</h1>
                <p className="text-xl text-gray-300">Community Growth Token & Innovative dApp Platform</p>
              </motion.div>
              <motion.div
                className="bg-gray-900 bg-opacity-35 p-6 rounded-lg"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <p className="text-gray-300 mb-4">
                  ICARUS is not just another token; we're a Community Growth Token focused on creating substantial value for all members. Our innovative dApp platform offers a comprehensive suite of tools designed to enhance your blockchain experience and foster community engagement.
                </p>
                <Link to="/dashboard" className="bg-gradient-to-r from-orange-500 to-yellow-500 text-white font-bold py-3 px-6 rounded-full inline-flex items-center transition-colors duration-200 hover:from-orange-600 hover:to-yellow-600">
                  Launch dApp <Rocket size={20} className="ml-2" />
                </Link>
              </motion.div>
            </div>
          </div>
        </div>
      </div>

      <main className="container mx-auto px-4 py-12">
        <h2 className="text-3xl font-bold text-center mb-8">Explore ICARUS Ecosystem</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
          <FeatureCard
            title="Dashboard"
            description="Get a real-time overview of your assets, performance metrics, and tailored insights to make informed decisions."
            icon={ChartBar}
            link="/dashboard"
          />
          <FeatureCard
            title="NFT Minting"
            description="Mint unique Acolyte NFTs and participate in the digital economy of ICARUS. Unlock exclusive benefits and express your creativity."
            icon={Rocket}
            link="/nft-mint"
          />
          <FeatureCard
            title="Staking"
            description="Maximize your earnings through competitive APYs and rewards. Stake ICA tokens and earn partner tokens."
            icon={Coins}
            link="/staking"
          />
          <FeatureCard
            title="KOL Program"
            description="Become a Key Opinion Leader, gain insights, share strategies, and network within our thriving crypto community."
            icon={Users}
            link="/kol"
          />
          <FeatureCard
            title="Treasury"
            description="Experience full transparency with real-time management of community funds. See how your investment grows the ICARUS ecosystem."
            icon={Shield}
            link="/treasury"
          />
          <FeatureCard
            title="Community Events"
            description="Engage in chat2earn, buy competitions, meme contests, and other rewarding activities. Earn while you have fun!"
            icon={Trophy}
            link="/events"
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
          <motion.div
            className="bg-gradient-to-r from-blue-600 to-purple-600 rounded-lg shadow-lg p-6"
            whileHover={{ scale: 1.05 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            <Book className="text-white mb-4" size={36} />
            <h3 className="text-2xl font-bold text-white mb-2">ICARUS Whitepaper</h3>
            <p className="text-gray-100 mb-4">Dive deep into the ICARUS vision, tokenomics, and roadmap. Understand the technology and philosophy driving our community growth token.</p>
            <a 
              href="https://icarus-community-growth-project.gitbook.io/icarus-whitepaper"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-white text-blue-600 font-bold py-2 px-4 rounded inline-flex items-center transition-colors duration-200 hover:bg-gray-100"
            >
              Read Whitepaper <ExternalLink size={16} className="ml-2" />
            </a>
          </motion.div>
          <motion.div
            className="bg-gradient-to-r from-green-500 to-teal-500 rounded-lg shadow-lg p-6"
            whileHover={{ scale: 1.05 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            <MessageCircle className="text-white mb-4" size={36} />
            <h3 className="text-2xl font-bold text-white mb-2">Join Our Telegram</h3>
            <p className="text-gray-100 mb-4">Connect with the ICARUS community, participate in discussions, and stay updated on the latest news and events!</p>
            <a 
              href="https://t.me/ICARUS_Portal"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-white text-green-600 font-bold py-2 px-4 rounded inline-flex items-center transition-colors duration-200 hover:bg-gray-100"
            >
              Join Telegram <ExternalLink size={16} className="ml-2" />
            </a>
          </motion.div>
        </div>

        <div className="text-center">
          <h2 className="text-3xl font-bold mb-4">Ready to Soar with ICARUS?</h2>
          <p className="text-xl text-gray-300 mb-6">Join a community that's redefining success in the crypto space. Your journey to financial growth and empowerment starts here.</p>
          <Link to="/dashboard" className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-3 px-8 rounded-full inline-flex items-center transition-colors duration-200">
            Get Started Now <Rocket size={20} className="ml-2" />
          </Link>
        </div>
      </main>

      <footer className="bg-gray-800 py-6 mt-12">
        <div className="container mx-auto px-4 text-center">
          <p className="text-gray-300">© 2024 ICARUS. All rights reserved.</p>
          <p className="text-sm text-gray-500 mt-2">Version: {config.version}</p>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
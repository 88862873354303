import { ethers } from 'ethers';
import { config } from '../config';
import { ICARUS_ABI } from '../abi';

const cache = new Map();

const fetchWithCache = async (url, options = {}) => {
  const cacheKey = `${url}${JSON.stringify(options)}`;
  const cachedData = cache.get(cacheKey);
  
  if (cachedData && Date.now() - cachedData.timestamp < config.apiCacheDuration) {
    return cachedData.data;
  }

  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(`API call failed: ${response.status} for URL: ${url}`);
    }
    
    const data = await response.json();
    cache.set(cacheKey, { data, timestamp: Date.now() });
    
    return data;
  } catch (error) {
    console.error(`Error fetching data from ${url}:`, error);
    throw error;
  }
};

export const fetchTokenHoldings = async (addresses) => {
  const provider = new ethers.providers.JsonRpcProvider(config.bscRpcUrl);
  const contract = new ethers.Contract(config.contractAddress, ICARUS_ABI, provider);
  
  const holdings = await Promise.all(
    addresses.map(async (address) => {
      try {
        const balance = await contract.balanceOf(address);
        return {
          address,
          balance: ethers.utils.formatUnits(balance, 18)
        };
      } catch (error) {
        console.error(`Error fetching balance for ${address}:`, error);
        return { address, balance: '0' };
      }
    })
  );
  return holdings;
};

export const fetchPoolInfo = async () => {
  const url = `${config.geckoTerminalApiUrl}/networks/bsc/pools/${config.icaPoolPairAddress}`;
  return fetchWithCache(url);
};

export const fetchTokenInfo = async () => {
  const url = `${config.geckoTerminalApiUrl}/networks/bsc/tokens/${config.contractAddress}`;
  return fetchWithCache(url);
};

export const fetchTokenPrice = async () => {
  try {
    const poolInfo = await fetchPoolInfo();
    return poolInfo.data?.attributes?.base_token_price_usd || 0.0000000001;
  } catch (error) {
    console.warn(`Failed to fetch token price, using placeholder:`, error);
    return 0.0000000001;
  }
};

export const fetchTotalSupply = async () => {
  try {
    const provider = new ethers.providers.JsonRpcProvider(config.bscRpcUrl);
    const contract = new ethers.Contract(config.contractAddress, ICARUS_ABI, provider);
    const totalSupply = await contract.totalSupply();
    return ethers.utils.formatUnits(totalSupply, 18);
  } catch (error) {
    console.error('Error fetching total supply from contract:', error);
    return '1000000000000';
  }
};

export const fetch24hData = async () => {
  const url = `${config.geckoTerminalApiUrl}/networks/bsc/pools/${config.icaPoolPairAddress}/ohlcv/day`;
  try {
    const data = await fetchWithCache(url);
    const latestData = data.data?.[0]?.attributes || {};
    return {
      volume24h: latestData.volume || 0,
      priceChange24h: latestData.close && latestData.open
        ? ((latestData.close - latestData.open) / latestData.open) * 100
        : 0
    };
  } catch (error) {
    console.error('Error fetching 24h data:', error);
    return { volume24h: 0, priceChange24h: 0 };
  }
};
import React, { useState, useCallback, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Activity, Search, AlertTriangle, DollarSign, Users, BarChart2, TrendingUp, Clock, Droplet, FileCode, ExternalLink, RefreshCw, Zap, ShieldAlert, Coins, ArrowRightLeft, FlaskConical } from 'lucide-react';
import { formatDistanceToNow, fromUnixTime } from 'date-fns';
import { formatNumber, formatAddress } from '../../utils/numberUtils';
import QuarkUtils from '../../utils/QuarkUtils';
import AlveyUtils from '../../utils/AlveyUtils';
import { config } from '../../config';
import LoadingAnimation from '../LoadingAnimation';
import ErrorBoundary from '../ErrorBoundary';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, Legend, PieChart, Pie, Cell } from 'recharts';

const COLORS = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F7DC6F', '#B19CD9'];

const GlowingCard = ({ children, glowColor = 'rgba(255, 152, 0, 0.5)', className = '' }) => (
  <motion.div
    className={`relative bg-gray-800 rounded-xl overflow-hidden ${className}`}
    whileHover={{ scale: 1.02 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <div className="absolute inset-0 bg-gradient-to-r from-transparent via-gray-700 to-transparent opacity-50" />
    <div className="relative z-10 p-6">
      {children}
    </div>
    <div
      className="absolute inset-0 opacity-0 hover:opacity-100 transition-opacity duration-300"
      style={{
        boxShadow: `0 0 20px ${glowColor}`,
        filter: 'blur(15px)',
        zIndex: -1,
      }}
    />
  </motion.div>
);

const ProjectAnalyzer = () => {
  const [projectAddress, setProjectAddress] = useState('');
  const [projectData, setProjectData] = useState(null);
  const [tokenCounters, setTokenCounters] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchProjectData = useCallback(async () => {
    if (!projectAddress) {
      setError('Please enter a project address');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const [tokenInfo, pairsData, holdersData, contractInfo, transfers, transactions, counters] = await Promise.all([
        QuarkUtils.getToken(config.alveychainDec, projectAddress),
        QuarkUtils.getTokenPairs(config.alveychainDec, projectAddress),
        AlveyUtils.getTokenHolders(projectAddress),
        AlveyUtils.getSmartContract(projectAddress),
        AlveyUtils.getTokenTransfers(projectAddress),
        AlveyUtils.getTransactions({ address: projectAddress, type: 'token' }),
        AlveyUtils.getTokenCounters(projectAddress)
      ]);

      if (!tokenInfo || tokenInfo.detail === "Token not found") {
        throw new Error("Token not found or invalid data received");
      }

      const topHolders = Array.isArray(holdersData) 
        ? holdersData.map(holder => ({
            address: holder.address || 'Unknown',
            balance: parseFloat(holder.balance) || 0,
            percentage: ((parseFloat(holder.balance) || 0) / (tokenInfo.ttl_supply || 1)) * 100
          }))
        : [];

      const projectData = {
        ...tokenInfo,
        pairs: pairsData,
        topHolders,
        holdersCount: counters.token_holders_count,
        transfersCount: counters.transfers_count,
        isVerified: contractInfo ? contractInfo.is_verified : false,
        creationDate: contractInfo && contractInfo.created_at ? new Date(contractInfo.created_at) : null,
        contractSourceCode: contractInfo ? contractInfo.source_code : null,
        transfers: transfers.result,
        transactions: transactions.result,
      };

      setProjectData(projectData);
      setTokenCounters(counters);

      const analysis = analyzeProjectRisks(projectData);

      setProjectData(prevData => ({ ...prevData, ...analysis }));

    } catch (err) {
      console.error('Error in fetchProjectData:', err);
      setError(`Failed to fetch project data: ${err.message}`);
      if (err.response) {
        console.error('Response data:', err.response.data);
        console.error('Response status:', err.response.status);
      }
    } finally {
      setIsLoading(false);
    }
  }, [projectAddress]);

  const analyzeProjectRisks = (data) => {
    const risks = [];
    let riskScore = 0;

    if (!data.isVerified) {
      risks.push("Contract is not verified");
      riskScore += 20;
    }

    const totalLiquidity = data.pairs ? data.pairs.reduce((sum, pair) => sum + (pair.liquidity || 0), 0) : 0;
    if (totalLiquidity < 10000) {
      risks.push("Low liquidity");
      riskScore += 15;
    }

    const topHolderPercentage = data.topHolders && data.topHolders[0] ? data.topHolders[0].percentage : 0;
    if (topHolderPercentage > 20) {
      risks.push(`Top holder owns ${topHolderPercentage.toFixed(2)}% of supply`);
      riskScore += 15;
    }

    const minutesSinceCreation = data.creationDate ? (new Date() - data.creationDate) / (1000 * 60) : null;
    if (minutesSinceCreation !== null && minutesSinceCreation < 60) {
      risks.push("Token created less than an hour ago");
      riskScore += 10;
    }

    if (data.contractSourceCode && data.contractSourceCode.includes("selfdestruct")) {
      risks.push("Contract contains selfdestruct function");
      riskScore += 25;
    }

    const giniCoefficient = calculateGiniCoefficient(data.topHolders || []);
    if (giniCoefficient > 0.8) {
      risks.push("High token concentration (Gini coefficient > 0.8)");
      riskScore += 15;
    }

    if (parseInt(data.holdersCount) < 100) {
      risks.push("Low number of holders");
      riskScore += 10;
    }

    return { risks, riskScore, giniCoefficient };
  };

  const calculateGiniCoefficient = (holders) => {
    const n = holders.length;
    if (n === 0) return 0;

    const sortedBalances = holders.map(h => h.balance).sort((a, b) => a - b);
    let sumOfDifferences = 0;
    let sumOfBalances = 0;

    for (let i = 0; i < n; i++) {
      for (let j = 0; j < n; j++) {
        sumOfDifferences += Math.abs(sortedBalances[i] - sortedBalances[j]);
      }
      sumOfBalances += sortedBalances[i];
    }

    return sumOfDifferences / (2 * n * sumOfBalances);
  };

  const renderMetricCard = (title, value, icon, accentColor = 'text-orange-500') => (
    <GlowingCard>
      <h3 className={`text-lg font-semibold ${accentColor} mb-2 flex items-center`}>
        {icon}
        <span className="ml-2">{title}</span>
      </h3>
      <p className="text-2xl font-bold text-white">{value}</p>
    </GlowingCard>
  );

  const ProjectHeader = ({ data }) => (
    <GlowingCard className="mb-8 bg-gradient-to-r from-orange-500 to-yellow-500">
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-3xl font-bold text-white mb-2">{data.name} ({data.symbol})</h2>
          <p className="text-xl text-white">Price: {formatNumber(data.price_usd, 'lowPriceUSD')}</p>
        </div>
        <div className="text-right">
          <p className="text-xl font-bold text-white">Risk Score: <span className={data.riskScore > 50 ? 'text-red-500' : 'text-green-500'}>{data.riskScore}/100</span></p>
        </div>
      </div>
    </GlowingCard>
  );

  return (
    <ErrorBoundary>
      <div className="bg-gray-900 text-white p-6">
        <h2 className="text-4xl font-bold text-orange-500 mb-8 flex items-center">
          <FlaskConical className="mr-4" size={36} /> Alvey Chain Project Analyzer
        </h2>

        <div className="mb-8 flex items-center">
          <input
            type="text"
            value={projectAddress}
            onChange={(e) => setProjectAddress(e.target.value)}
            placeholder="Enter project address"
            className="bg-gray-800 text-white p-3 rounded-lg mr-4 flex-grow focus:outline-none focus:ring-2 focus:ring-orange-500"
          />
          <button
            onClick={fetchProjectData}
            className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-3 px-6 rounded-lg flex items-center transition duration-300 ease-in-out transform hover:scale-105"
          >
            <Search className="mr-2" size={20} /> Analyze Project
          </button>
        </div>

        {isLoading && <LoadingAnimation />}

        <AnimatePresence>
          {error && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="bg-red-900 border-l-4 border-red-500 text-red-300 p-4 mb-6 rounded-lg"
            >
              <AlertTriangle className="inline-block mr-2" size={20} />
              {error}
            </motion.div>
          )}
        </AnimatePresence>

        {projectData && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="space-y-8"
          >
            <ProjectHeader data={projectData} />

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
              {renderMetricCard("Market Cap", formatNumber(projectData.mcap, 'dollar'), <BarChart2 size={24} />, "text-blue-400")}
              {renderMetricCard("Fully Diluted Value", formatNumber(projectData.fdv, 'dollar'), <DollarSign size={24} />, "text-green-400")}
              {renderMetricCard("Circulating Supply", formatNumber(projectData.crc_supply, projectData.symbol), <TrendingUp size={24} />)}
              {renderMetricCard("Total Supply", formatNumber(projectData.ttl_supply, projectData.symbol), <Coins size={24} />)}
              {renderMetricCard("Holders", formatNumber(projectData.holdersCount, 'number'), <Users size={24} />)}
              {renderMetricCard("Transfers", formatNumber(projectData.transfersCount, 'number'), <ArrowRightLeft size={24} />)}
              {renderMetricCard("Decimals", projectData.decimals, <Zap size={24} />)}
            </div>

            <GlowingCard>
              <h3 className="text-2xl font-bold text-orange-500 mb-4 flex items-center">
                <Droplet className="mr-2" size={24} /> Liquidity Pairs
              </h3>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-700">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Pair</th>
                      <th className="px-6 py-3 text-right text-xs font-medium text-gray-300 uppercase tracking-wider">Liquidity</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-700">
                    {projectData.pairs && projectData.pairs.length > 0 ? (
                      projectData.pairs.map((pair, index) => (
                        <tr key={index}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">{pair.name}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300 text-right">{formatNumber(pair.liquidity, 'dollar')}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="2" className="px-6 py-4 whitespace-nowrap text-sm text-gray-300 text-center">No liquidity pairs found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </GlowingCard>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
              <GlowingCard>
                <h3 className="text-2xl font-bold text-red-400 mb-4 flex items-center">
                  <ShieldAlert className="mr-2" size={24} /> Risk Analysis
                </h3>
                <p className="text-xl mb-4">Risk Score: <span className={projectData.riskScore > 50 ? 'text-red-500' : 'text-green-500'}>{projectData.riskScore}/100</span></p>
                <ul className="list-disc list-inside mb-4">
                  {projectData.risks && projectData.risks.map((risk, index) => (
                    <li key={index} className="text-yellow-300">{risk}</li>
                  ))}
                </ul>
                <p className="mb-2">Gini Coefficient: {projectData.giniCoefficient.toFixed(2)}</p>
              </GlowingCard>
            </div>

            <GlowingCard>
              <h3 className="text-2xl font-bold text-purple-400 mb-4 flex items-center">
                <ArrowRightLeft className="mr-2" size={24} /> Recent Transactions
              </h3>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-700">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Transaction Hash</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">From</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">To</th>
                      <th className="px-6 py-3 text-right text-xs font-medium text-gray-300 uppercase tracking-wider">Value</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-700">
                    {projectData.transactions && projectData.transactions.slice(0, 10).map((tx, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                          <a href={`https://alveyscan.com/tx/${tx.hash}`} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
                            {formatAddress(tx.hash)}
                          </a>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">{formatAddress(tx.from)}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">{formatAddress(tx.to)}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300 text-right">
                          {formatNumber(parseFloat(tx.value) / Math.pow(10, projectData.decimals), projectData.symbol)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </GlowingCard>

            <GlowingCard>
              <h3 className="text-2xl font-bold text-yellow-400 mb-4 flex items-center">
                <Zap className="mr-2" size={24} /> Recent Transfers
              </h3>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-700">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Transaction Hash</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">From</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">To</th>
                      <th className="px-6 py-3 text-right text-xs font-medium text-gray-300 uppercase tracking-wider">Value</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-700">
                    {projectData.transfers && projectData.transfers.slice(0, 10).map((transfer, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                          <a href={`https://alveyscan.com/tx/${transfer.transaction_hash}`} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
                            {formatAddress(transfer.transaction_hash)}
                          </a>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">{formatAddress(transfer.from)}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">{formatAddress(transfer.to)}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300 text-right">
                          {formatNumber(parseFloat(transfer.value) / Math.pow(10, projectData.decimals), projectData.symbol)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </GlowingCard>

            <GlowingCard>
              <h3 className="text-2xl font-bold text-purple-400 mb-4 flex items-center">
                <FileCode className="mr-2" size={24} /> Contract Information
              </h3>
              <p className="mb-2"><strong>Verified:</strong> {projectData.isVerified ? 'Yes' : 'No'}</p>
              <p className="mb-2"><strong>Creation Date:</strong> {projectData.creationDate ? formatDistanceToNow(projectData.creationDate, { addSuffix: true }) : 'N/A'}</p>
              {projectData.contractSourceCode && (
                <div>
                  <h4 className="text-xl font-semibold mb-2">Contract Source Code Preview:</h4>
                  <pre className="bg-gray-900 p-4 rounded-lg overflow-x-auto max-h-60">
                    {projectData.contractSourceCode.slice(0, 500)}...
                  </pre>
                </div>
              )}
              <div className="mt-4">
                <a
                  href={`https://alveyscan.com/address/${projectAddress}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-orange-500 hover:text-orange-400 flex items-center"
                >
                  View on Alveyscan <ExternalLink size={16} className="ml-1" />
                </a>
              </div>
            </GlowingCard>
          </motion.div>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default ProjectAnalyzer;

import React, { useState, useEffect, useCallback } from 'react';
import { 
  useAccount, 
  useContractRead, 
  useContractWrite, 
  usePrepareContractWrite, 
  useBalance, 
  useWaitForTransaction 
} from 'wagmi';
import { motion } from 'framer-motion';
import { 
  ArrowRightLeft, 
  CheckCircle, 
  XCircle, 
  Plus, 
  RefreshCw, 
  Lock, 
  Trash2, 
  ExternalLink,
  DollarSign,
  TrendingUp,
  FileText,
  Wallet
} from 'lucide-react';
import { formatUnits, parseUnits } from 'viem';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import QuarkUtils from '../utils/QuarkUtils';
import { config } from '../config';
import ErrorBoundary from './ErrorBoundary';
import LoadingAnimation from './LoadingAnimation';
import { formatNumber, formatAddress } from '../utils/numberUtils';
import { ICASwapABI } from './ICASwapABI';

// Minimal ERC20 ABI for allowance and approve
const ERC20_ABI = [
  {
    "constant": true,
    "inputs": [
      { "name": "owner", "type": "address" },
      { "name": "spender", "type": "address" }
    ],
    "name": "allowance",
    "outputs": [{ "name": "", "type": "uint256" }],
    "type": "function",
  },
  {
    "constant": false,
    "inputs": [
      { "name": "spender", "type": "address" },
      { "name": "value", "type": "uint256" }
    ],
    "name": "approve",
    "outputs": [{ "name": "", "type": "bool" }],
    "type": "function",
  },
];

// Swap Contract Address
const SWAP_CONTRACT_ADDRESS = '0x680C1902030A6566C6563bbd9ab1bc1acf33ab58';

// GlowingCard Component
const GlowingCard = ({ children, className = '' }) => (
  <motion.div
    className={`relative bg-gray-800 rounded-xl overflow-hidden ${className}`}
    whileHover={{ scale: 1.02 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <div className="absolute inset-0 bg-gradient-to-r from-transparent via-gray-700 to-transparent opacity-50" />
    <div className="relative z-10 p-6">
      {children}
    </div>
  </motion.div>
);

// TokenInfoCard Component
const TokenInfoCard = ({ title, symbol, price, marketCap, address, color }) => (
  <GlowingCard className={`border-l-4 ${color}`}>
    <div className="flex justify-between items-start mb-4">
      <h3 className="text-2xl font-bold text-white">{title}</h3>
      <span className={`text-3xl font-bold ${color}`}>{symbol}</span>
    </div>
    <div className="space-y-2">
      <div className="flex items-center">
        <DollarSign size={16} className="text-gray-400 mr-2" />
        <span className="text-gray-300">Price: {price ? formatNumber(price, 'ICAPrice') : 'N/A'}</span>
      </div>
      <div className="flex items-center">
        <TrendingUp size={16} className="text-gray-400 mr-2" />
        <span className="text-gray-300">Market Cap: {marketCap ? formatNumber(marketCap, 'USD') : 'N/A'}</span>
      </div>
      <div className="flex items-center">
        <FileText size={16} className="text-gray-400 mr-2" />
        <a href={`https://alveyscan.com/token/${address}`} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
          {formatAddress(address)} <ExternalLink size={14} className="inline ml-1" />
        </a>
      </div>
    </div>
  </GlowingCard>
);

const IcaSwap = () => {
  const { address, isConnected } = useAccount();
  const [oldTokenInfo, setOldTokenInfo] = useState(null);
  const [newTokenInfo, setNewTokenInfo] = useState(null);
  const [swapAmount, setSwapAmount] = useState('');
  const [swapValueOld, setSwapValueOld] = useState(0);
  const [swapValueNew, setSwapValueNew] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isOwner, setIsOwner] = useState(false);
  const [whitelistAddress, setWhitelistAddress] = useState('');
  const [whitelistAmount, setWhitelistAmount] = useState('');
  const [batchWhitelistAddresses, setBatchWhitelistAddresses] = useState('');
  const [batchWhitelistAmounts, setBatchWhitelistAmounts] = useState('');
  const [removeWhitelistAddress, setRemoveWhitelistAddress] = useState('');

  // Custom toast configurations
  const notifySuccess = (message) => toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    style: { background: '#1F2937', color: '#F97316' },
  });

  const notifyError = (message) => toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    style: { background: '#1F2937', color: '#F97316' },
  });

  const notifyInfo = (message) => toast.info(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    style: { background: '#1F2937', color: '#F97316' },
  });

  // Contract Reads
  const { data: ownerAddress } = useContractRead({
    address: SWAP_CONTRACT_ADDRESS,
    abi: ICASwapABI,
    functionName: 'owner',
  });

  const { data: oldTokenAddress } = useContractRead({
    address: SWAP_CONTRACT_ADDRESS,
    abi: ICASwapABI,
    functionName: 'oldToken',
  });

  const { data: newTokenAddress } = useContractRead({
    address: SWAP_CONTRACT_ADDRESS,
    abi: ICASwapABI,
    functionName: 'newToken',
  });

  const { data: whitelistStatus, refetch: refetchWhitelistStatus } = useContractRead({
    address: SWAP_CONTRACT_ADDRESS,
    abi: ICASwapABI,
    functionName: 'getWhitelistStatus',
    args: [address],
    enabled: isConnected,
  });

  const { data: oldTokenBalance } = useBalance({
    address,
    token: oldTokenAddress,
  });

  const { data: contractOldTokenBalance } = useBalance({
    address: SWAP_CONTRACT_ADDRESS,
    token: oldTokenAddress,
  });

  const { data: contractNewTokenBalance } = useBalance({
    address: SWAP_CONTRACT_ADDRESS,
    token: newTokenAddress,
  });

  // Approval Checks
  const { data: allowance, refetch: refetchAllowance } = useContractRead({
    address: oldTokenAddress,
    abi: ERC20_ABI,
    functionName: 'allowance',
    args: [address, SWAP_CONTRACT_ADDRESS],
    enabled: isConnected && oldTokenAddress && parseFloat(swapAmount) > 0,
  });

  // Determine if approved using parseFloat for comparison
  const formattedAllowance = allowance ? parseFloat(formatUnits(allowance, 18)) : 0;
  const isApproved = formattedAllowance >= parseFloat(swapAmount || '0');

  // Prepare Approve Contract Write
  const { config: approveConfig } = usePrepareContractWrite({
    address: oldTokenAddress,
    abi: ERC20_ABI,
    functionName: 'approve',
    args: [SWAP_CONTRACT_ADDRESS, parseUnits(swapAmount || '0', 18)],
    enabled: !isApproved && isConnected && parseFloat(swapAmount) > 0,
  });

  const { write: approve, data: approveData } = useContractWrite(approveConfig);

  // Monitor Approval Transaction
  const { isLoading: isApproving } = useWaitForTransaction({
    hash: approveData?.hash,
    onSuccess: () => {
      notifySuccess("Token transfer approved successfully!");
      refetchAllowance();
      refetchWhitelistStatus();
    },
    onError: (error) => {
      console.error('Approval Error:', error);
      notifyError("Approval failed. Please try again.");
    },
  });

  // Prepare Swap Contract Write
  const { config: swapConfig } = usePrepareContractWrite({
    address: SWAP_CONTRACT_ADDRESS,
    abi: ICASwapABI,
    functionName: 'swap',
    args: [parseUnits(swapAmount || '0', 18)],
    enabled: isConnected && swapAmount !== '' && isApproved,
  });

  const { write: swap, data: swapData } = useContractWrite(swapConfig);

  // Monitor Swap Transaction
  const { isLoading: isSwapping } = useWaitForTransaction({
    hash: swapData?.hash,
    onSuccess: () => {
      notifySuccess("Swap completed successfully!");
      refetchAllowance();
      refetchWhitelistStatus();
      // Add other refetch calls here if needed
    },
    onError: (error) => {
      console.error('Swap Error:', error);
      notifyError("Swap failed. Please try again.");
    },
  });

  // Prepare Owner Contract Writes
  const { config: addToWhitelistConfig } = usePrepareContractWrite({
    address: SWAP_CONTRACT_ADDRESS,
    abi: ICASwapABI,
    functionName: 'addToWhitelist',
    args: [whitelistAddress, parseUnits(whitelistAmount || '0', 18)],
    enabled: isOwner && whitelistAddress !== '' && whitelistAmount !== '',
  });

  const { write: addToWhitelist, isLoading: isAddingToWhitelist } = useContractWrite(addToWhitelistConfig);

  const { config: addMultipleToWhitelistConfig } = usePrepareContractWrite({
    address: SWAP_CONTRACT_ADDRESS,
    abi: ICASwapABI,
    functionName: 'addMultipleToWhitelist',
    args: [
      batchWhitelistAddresses.split(',').map(addr => addr.trim()),
      batchWhitelistAmounts.split(',').map(amount => parseUnits(amount.trim() || '0', 18)),
    ],
    enabled: isOwner && batchWhitelistAddresses !== '' && batchWhitelistAmounts !== '',
  });

  const { write: addMultipleToWhitelist, isLoading: isAddingMultipleToWhitelist } = useContractWrite(addMultipleToWhitelistConfig);

  const { config: removeFromWhitelistConfig } = usePrepareContractWrite({
    address: SWAP_CONTRACT_ADDRESS,
    abi: ICASwapABI,
    functionName: 'removeFromWhitelist',
    args: [removeWhitelistAddress],
    enabled: isOwner && removeWhitelistAddress !== '',
  });

  const { write: removeFromWhitelist, isLoading: isRemovingFromWhitelist } = useContractWrite(removeFromWhitelistConfig);

  const { config: withdrawOldTokenConfig } = usePrepareContractWrite({
    address: SWAP_CONTRACT_ADDRESS,
    abi: ICASwapABI,
    functionName: 'withdrawOldToken',
    args: [contractOldTokenBalance?.value || '0'],
    enabled: isOwner && contractOldTokenBalance?.value !== '0',
  });

  const { write: withdrawOldToken, isLoading: isWithdrawingOldToken } = useContractWrite(withdrawOldTokenConfig);

  const { config: withdrawNewTokenConfig } = usePrepareContractWrite({
    address: SWAP_CONTRACT_ADDRESS,
    abi: ICASwapABI,
    functionName: 'withdrawNewToken',
    args: [contractNewTokenBalance?.value || '0'],
    enabled: isOwner && contractNewTokenBalance?.value !== '0',
  });

  const { write: withdrawNewToken, isLoading: isWithdrawingNewToken } = useContractWrite(withdrawNewTokenConfig);

  // Fetch Token Info
  const fetchTokenInfo = useCallback(async () => {
    if (!oldTokenAddress || !newTokenAddress) return;
    
    try {
      const [oldToken, newToken] = await Promise.all([
        QuarkUtils.getToken(config.alveychainDec, oldTokenAddress),
        QuarkUtils.getToken(config.alveychainDec, newTokenAddress),
      ]);
      setOldTokenInfo(oldToken);
      setNewTokenInfo(newToken);
    } catch (err) {
      console.error('Error fetching token info:', err);
      setError('Failed to fetch token information. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, [oldTokenAddress, newTokenAddress]);

  useEffect(() => {
    if (isConnected) {
      fetchTokenInfo();
    } else {
      setIsLoading(false);
    }
  }, [fetchTokenInfo, isConnected]);

  useEffect(() => {
    setIsOwner(address === ownerAddress);
  }, [address, ownerAddress]);

  // Calculate Swap Values
  useEffect(() => {
    if (oldTokenInfo && newTokenInfo && swapAmount) {
      const oldValue = parseFloat(swapAmount) * oldTokenInfo.price_usd;
      const newValue = parseFloat(swapAmount) * newTokenInfo.price_usd;
      setSwapValueOld(oldValue);
      setSwapValueNew(newValue);
    }
  }, [swapAmount, oldTokenInfo, newTokenInfo]);

  // Handlers
  const handleApprove = () => {
    if (!isConnected) {
      notifyError("Please connect your wallet to approve token transfer.");
      return;
    }
    if (!swapAmount || parseFloat(swapAmount) <= 0) {
      notifyError("Please enter a valid amount to approve.");
      return;
    }
    if (approve) {
      approve();
      notifyInfo("Approval transaction initiated. Please wait for confirmation.");
    }
  };

  const handleSwap = () => {
    if (!isConnected) {
      notifyError("Please connect your wallet to swap tokens.");
      return;
    }
    if (!swapAmount || parseFloat(swapAmount) <= 0) {
      notifyError("Please enter a valid amount to swap.");
      return;
    }
    if (!isApproved) {
      notifyError("Please approve the token transfer before swapping.");
      return;
    }
    if (swap) {
      swap();
      notifyInfo("Swap transaction initiated. Please wait for confirmation.");
    }
  };

  const handleAddToWhitelist = () => {
    if (addToWhitelist) {
      addToWhitelist();
      notifyInfo("Adding address to whitelist. Please wait for confirmation.");
    }
  };

  const handleAddMultipleToWhitelist = () => {
    if (addMultipleToWhitelist) {
      addMultipleToWhitelist();
      notifyInfo("Adding multiple addresses to whitelist. Please wait for confirmation.");
    }
  };

  const handleRemoveFromWhitelist = () => {
    if (removeFromWhitelist) {
      removeFromWhitelist();
      notifyInfo("Removing address from whitelist. Please wait for confirmation.");
    }
  };

  const handleWithdrawOldToken = () => {
    if (withdrawOldToken) {
      withdrawOldToken();
      notifyInfo("Withdrawing old tokens. Please wait for confirmation.");
    }
  };

  const handleWithdrawNewToken = () => {
    if (withdrawNewToken) {
      withdrawNewToken();
      notifyInfo("Withdrawing new tokens. Please wait for confirmation.");
    }
  };

  const handleMaxSwap = () => {
    if (whitelistStatus?.[1]) {
      setSwapAmount(formatUnits(whitelistStatus[1], 18));
    }
  };

  // Refetch allowance and whitelist status after approval or swap
  useEffect(() => {
    if (isApproved) {
      refetchAllowance();
      refetchWhitelistStatus();
    }
  }, [isApproved, refetchAllowance, refetchWhitelistStatus]);

  if (!isConnected) {
    return (
      <ErrorBoundary>
        <GlowingCard className="bg-gradient-to-br from-orange-500 to-yellow-500">
          <h2 className="text-3xl font-bold text-white mb-4">ICA Token Swap</h2>
          <div className="flex items-center justify-center text-white">
            <Wallet className="mr-2" size={24} />
            <p>Please connect your wallet to access the ICA Token Swap feature.</p>
          </div>
        </GlowingCard>
      </ErrorBoundary>
    );
  }

  if (isLoading) {
    return <LoadingAnimation />;
  }

  return (
    <ErrorBoundary>
      <div className="space-y-6">
        <ToastContainer 
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />

        {/* Header Card */}
        <GlowingCard className="bg-gradient-to-br from-orange-500 to-yellow-500">
          <h2 className="text-3xl font-bold text-white mb-2">ICA Token Swap</h2>
          <p className="text-white mb-2">Connected Wallet: {address ? formatAddress(address) : 'Not connected'}</p>
          <p className="text-white">Swap your old ICA tokens for new ones. Make sure you're connected to the Alveychain network.</p>
        </GlowingCard>

        {/* Token Info Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <TokenInfoCard
            title="Old ICA Token"
            symbol={oldTokenInfo?.symbol || 'N/A'}
            price={oldTokenInfo?.price_usd}
            marketCap={oldTokenInfo?.mcap}
            address={oldTokenAddress}
            color="border-[#4FC3F7] text-[#4FC3F7]"
          />
          <TokenInfoCard
            title="New ICA Token"
            symbol={newTokenInfo?.symbol || 'N/A'}
            price={newTokenInfo?.price_usd}
            marketCap={newTokenInfo?.mcap}
            address={newTokenAddress}
            color="border-[#FF9800] text-[#FF9800]"
          />
        </div>

        {/* User Swap Status */}
        <GlowingCard>
          <h3 className="text-xl font-bold text-white mb-2">Your Swap Status</h3>
          <p className="text-gray-300">
            Your Old ICA Balance: {oldTokenBalance ? formatNumber(formatUnits(oldTokenBalance.value, 18), 'alv') : 'Loading...'}
          </p>
          {whitelistStatus ? (
            <>
              <p className="text-gray-300 flex items-center">
                Whitelisted: {whitelistStatus[0] ? (
                  <CheckCircle className="inline-block text-green-500 ml-2" size={20} />
                ) : (
                  <XCircle className="inline-block text-red-500 ml-2" size={20} />
                )}
              </p>
              <p className="text-gray-300">
                Remaining Swap Amount: {formatNumber(formatUnits(whitelistStatus[1], 18), 'alv')}
              </p>
            </>
          ) : (
            <p className="text-gray-300">Whitelist status unavailable.</p>
          )}
        </GlowingCard>

        {/* Swap Card with Approval */}
        <GlowingCard>
          <h3 className="text-xl font-bold text-white mb-4">Swap Tokens</h3>
          
          {/* Swap Amount Input */}
          <div className="flex items-center mb-4">
            <input
              type="number"
              value={swapAmount}
              onChange={(e) => setSwapAmount(e.target.value)}
              placeholder="Amount to swap"
              className="w-full p-2 bg-gray-700 text-white rounded-l"
              min="0"
            />
            <button
              onClick={handleMaxSwap}
              className="bg-[#4FC3F7] hover:bg-[#03A9F4] text-white font-bold py-2 px-4 rounded-r"
            >
              MAX
            </button>
          </div>

          {/* Swap Value Information */}
          <div className="mb-4 p-3 bg-gray-700 rounded">
            <div className="flex justify-between items-center mb-2">
              <span className="text-gray-300">You will swap:</span>
              <span className="text-lg font-semibold text-white">
                {swapAmount || '0'} {oldTokenInfo?.symbol || 'Old Token'}
              </span>
            </div>
            <div className="flex justify-between items-center mb-2">
              <span className="text-gray-300">You will receive:</span>
              <span className="text-lg font-semibold text-white">
                {swapAmount || '0'} {newTokenInfo?.symbol || 'New Token'}
              </span>
            </div>
            <div className="flex justify-between items-center mb-2">
              <span className="text-gray-300">Current value:</span>
              <span className="text-lg font-semibold text-white">{formatNumber(swapValueOld, 'USD')}</span>
            </div>
            <div className="flex justify-between items-center mb-2">
              <span className="text-gray-300">New value:</span>
              <span className="text-lg font-semibold text-white">{formatNumber(swapValueNew, 'USD')}</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-gray-300">Net profit/loss:</span>
              <span className={`text-lg font-semibold ${swapValueNew - swapValueOld >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                {formatNumber(swapValueNew - swapValueOld, 'USD')}
              </span>
            </div>
          </div>

          {/* Approval and Swap Buttons */}
          <div className="flex space-x-4">
            <button
              onClick={handleApprove}
              disabled={!isConnected || isApproved || isApproving || !swapAmount}
              className={`flex-1 flex items-center justify-center font-bold py-2 px-4 rounded transition-colors duration-200 ${
                !isApproved 
                  ? 'bg-[#FF9800] hover:bg-[#F57C00] text-white' 
                  : 'bg-gray-600 text-gray-400 cursor-not-allowed'
              }`}
            >
              {isApproving ? (
                <>
                  <RefreshCw className="animate-spin mr-2" size={20} />
                  Approving...
                </>
              ) : (
                'Approve'
              )}
            </button>

            <button
              onClick={handleSwap}
              disabled={!isConnected || isSwapping || !swapAmount || !isApproved}
              className={`flex-1 flex items-center justify-center font-bold py-2 px-4 rounded transition-colors duration-200 ${
                isApproved 
                  ? 'bg-[#FF9800] hover:bg-[#F57C00] text-white' 
                  : 'bg-gray-600 text-gray-400 cursor-not-allowed'
              }`}
            >
              {isSwapping ? (
                <>
                  <RefreshCw className="animate-spin mr-2" size={20} />
                  Swapping...
                </>
              ) : (
                'Swap Tokens'
              )}
            </button>
          </div>
        </GlowingCard>

        {/* Owner Functions */}
        {isOwner && (
          <GlowingCard>
            <h3 className="text-2xl font-bold text-[#FF9800] mb-4 flex items-center">
              <Lock className="mr-2" size={24} /> Owner Functions
            </h3>
            <div className="space-y-6">
              {/* Add to Whitelist */}
              <div className="bg-gray-700 p-4 rounded-lg">
                <h4 className="text-xl font-bold text-white mb-2">Add to Whitelist</h4>
                <input
                  type="text"
                  value={whitelistAddress}
                  onChange={(e) => setWhitelistAddress(e.target.value)}
                  placeholder="Address"
                  className="w-full p-2 mb-2 bg-gray-800 text-white rounded"
                />
                <input
                  type="number"
                  value={whitelistAmount}
                  onChange={(e) => setWhitelistAmount(e.target.value)}
                  placeholder="Max Amount"
                  className="w-full p-2 mb-2 bg-gray-800 text-white rounded"
                />
                <button
                  onClick={handleAddToWhitelist}
                  disabled={isAddingToWhitelist}
                  className="w-full bg-[#4FC3F7] hover:bg-[#03A9F4] text-white font-bold py-2 px-4 rounded transition-colors duration-200 flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isAddingToWhitelist ? (
                    <>
                      <RefreshCw className="animate-spin mr-2" size={20} />
                      Adding to Whitelist...
                    </>
                  ) : (
                    <>
                      <Plus size={20} className="mr-2" />
                      Add to Whitelist
                    </>
                  )}
                </button>
              </div>

              {/* Remove from Whitelist */}
              <div className="bg-gray-700 p-4 rounded-lg">
                <h4 className="text-xl font-bold text-white mb-2">Remove from Whitelist</h4>
                <input
                  type="text"
                  value={removeWhitelistAddress}
                  onChange={(e) => setRemoveWhitelistAddress(e.target.value)}
                  placeholder="Address to remove"
                  className="w-full p-2 mb-2 bg-gray-800 text-white rounded"
                />
                <button
                  onClick={handleRemoveFromWhitelist}
                  disabled={isRemovingFromWhitelist}
                  className="w-full bg-[#FF5722] hover:bg-[#E64A19] text-white font-bold py-2 px-4 rounded transition-colors duration-200 flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isRemovingFromWhitelist ? (
                    <>
                      <RefreshCw className="animate-spin mr-2" size={20} />
                      Removing from Whitelist...
                    </>
                  ) : (
                    <>
                      <Trash2 size={20} className="mr-2" />
                      Remove from Whitelist
                    </>
                  )}
                </button>
              </div>

              {/* Batch Add to Whitelist */}
              <div className="bg-gray-700 p-4 rounded-lg">
                <h4 className="text-xl font-bold text-white mb-2">Batch Add to Whitelist</h4>
                <textarea
                  value={batchWhitelistAddresses}
                  onChange={(e) => setBatchWhitelistAddresses(e.target.value)}
                  placeholder="Addresses (comma-separated)"
                  className="w-full p-2 mb-2 bg-gray-800 text-white rounded"
                  rows={3}
                />
                <textarea
                  value={batchWhitelistAmounts}
                  onChange={(e) => setBatchWhitelistAmounts(e.target.value)}
                  placeholder="Max Amounts (comma-separated)"
                  className="w-full p-2 mb-2 bg-gray-800 text-white rounded"
                  rows={3}
                />
                <button
                  onClick={handleAddMultipleToWhitelist}
                  disabled={isAddingMultipleToWhitelist}
                  className="w-full bg-[#4CAF50] hover:bg-[#45A049] text-white font-bold py-2 px-4 rounded transition-colors duration-200 flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isAddingMultipleToWhitelist ? (
                    <>
                      <RefreshCw className="animate-spin mr-2" size={20} />
                      Adding Multiple to Whitelist...
                    </>
                  ) : (
                    <>
                      <Plus size={20} className="mr-2" />
                      Batch Add to Whitelist
                    </>
                  )}
                </button>
              </div>

              {/* Contract Token Balances */}
              <div className="bg-gray-700 p-4 rounded-lg">
                <h4 className="text-xl font-bold text-white mb-2">Contract Token Balances</h4>
                <p className="text-gray-300">
                  Old Token Balance: {contractOldTokenBalance ? formatNumber(formatUnits(contractOldTokenBalance.value, 18), 'ICA') : 'Loading...'}
                </p>
                <p className="text-gray-300">
                  New Token Balance: {contractNewTokenBalance ? formatNumber(formatUnits(contractNewTokenBalance.value, 18), 'ICA') : 'Loading...'}
                </p>
                <div className="flex space-x-4 mt-4">
                  <button
                    onClick={handleWithdrawOldToken}
                    disabled={isWithdrawingOldToken || contractOldTokenBalance?.value === '0'}
                    className="flex-1 bg-[#FFC107] hover:bg-[#FFA000] text-white font-bold py-2 px-4 rounded transition-colors duration-200 flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {isWithdrawingOldToken ? (
                      <>
                        <RefreshCw className="animate-spin mr-2" size={20} />
                        Withdrawing Old Token...
                      </>
                    ) : (
                      <>
                        <ArrowRightLeft size={20} className="mr-2" />
                        Withdraw Old Token
                      </>
                    )}
                  </button>
                  <button
                    onClick={handleWithdrawNewToken}
                    disabled={isWithdrawingNewToken || contractNewTokenBalance?.value === '0'}
                    className="flex-1 bg-[#9C27B0] hover:bg-[#7B1FA2] text-white font-bold py-2 px-4 rounded transition-colors duration-200 flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {isWithdrawingNewToken ? (
                      <>
                        <RefreshCw className="animate-spin mr-2" size={20} />
                        Withdrawing New Token...
                      </>
                    ) : (
                      <>
                        <ArrowRightLeft size={20} className="mr-2" />
                        Withdraw New Token
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </GlowingCard>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default IcaSwap;
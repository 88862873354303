import React, { useState, useEffect } from 'react';
import { useAccount } from 'wagmi';
import {
  Feather, Gift, Lock, Zap, Download, DollarSign, ExternalLink, Image, Printer, FileText, 
  Shield, Sword, Brain, Heart, Save, Code, Cpu, Palette, Compass, Map, AlignLeft, Sparkles, 
  BookOpen, Target, Calendar, Users, Gamepad, Mic, Hammer, MapPin, Cat, Clock, TrendingUp,
  Wallet, Database, BarChart, VoteIcon
} from 'lucide-react';
import { config } from '../config';
import { motion } from 'framer-motion';
import LoadingAnimation from './LoadingAnimation';

const IPFS_GATEWAY = config.ipfsGateway;
const MORALIS_API_KEY = config.moralisApiKey;

const Acolytes = () => {
  const { address, isConnected } = useAccount();
  const [userAcolytes, setUserAcolytes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAcolytes = async () => {
      if (isConnected && address) {
        setIsLoading(true);
        setError(null);
        try {
          const response = await fetch(
            `https://deep-index.moralis.io/api/v2/nft/${config.acolyteNftAddress}/owners?chain=bsc&format=decimal`,
            {
              headers: {
                'X-API-Key': MORALIS_API_KEY,
              },
            }
          );

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          const userNFTs = data.result.filter(nft => nft.owner_of.toLowerCase() === address.toLowerCase());

          const acolytes = await Promise.all(
            userNFTs.map(async (nft) => {
              let metadata;
              try {
                metadata = JSON.parse(nft.metadata);
              } catch (error) {
                console.error('Error parsing NFT metadata:', error);
                metadata = null;
              }

              if (!metadata && nft.token_uri) {
                try {
                  const metadataResponse = await fetch(nft.token_uri);
                  if (!metadataResponse.ok) {
                    throw new Error(`HTTP error! status: ${metadataResponse.status}`);
                  }
                  metadata = await metadataResponse.json();
                } catch (error) {
                  console.error('Error fetching metadata from token_uri:', error);
                  metadata = null;
                }
              }

              let imageUrl = metadata?.image || '';
              if (imageUrl.startsWith('ipfs://')) {
                imageUrl = `${IPFS_GATEWAY}${imageUrl.slice(7)}`;
              }

              return {
                id: nft.token_id,
                name: metadata?.name || `Acolyte #${nft.token_id}`,
                image: imageUrl,
                attributes: metadata?.attributes || [],
                backstory: metadata?.description || 'No backstory available.',
              };
            })
          );

          setUserAcolytes(acolytes);
        } catch (error) {
          console.error('Error fetching Acolytes:', error);
          setError('Failed to fetch Acolytes. Please try again later.');
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    fetchAcolytes();
  }, [address, isConnected]);

  const AttributeCard = ({ trait_type, value, icon: Icon }) => (
    React.createElement(motion.div, 
      {
        className: "bg-gray-800 p-4 rounded-lg shadow-lg flex items-center",
        whileHover: { scale: 1.05 },
        transition: { type: "spring", stiffness: 300 }
      },
      React.createElement(Icon, { className: "text-orange-500 mr-3", size: 24 }),
      React.createElement('div', null,
        React.createElement('h4', { className: "text-sm font-semibold text-gray-400 mb-1" }, trait_type),
        React.createElement('p', { className: "text-lg font-bold text-orange-500" }, value)
      )
    )
  );

const StatCard = ({ stat, value, icon: Icon }) => (
  React.createElement(motion.div, 
    {
      className: "bg-gray-800 p-4 rounded-lg shadow-lg",
      whileHover: { scale: 1.05 },
      transition: { type: "spring", stiffness: 300 }
    },
    React.createElement('div', { className: "flex items-center justify-between mb-2" },
      React.createElement('div', { className: "flex items-center" },
        React.createElement(Icon, { className: "text-orange-500 mr-2", size: 24 }),
        React.createElement('h4', { className: "text-lg font-semibold text-white" }, stat)
      ),
      React.createElement('p', { className: "text-orange-500 font-bold" }, value)
    ),
    React.createElement('div', { className: "w-full bg-gray-700 rounded-full h-2.5" },
      React.createElement('div', 
        {
          className: "bg-orange-500 h-2.5 rounded-full", 
          style: { width: `${((value - 10) / 90) * 100}%` }
        }
      )
    )
  )
);
  
  const attributeIcons = {
    Alliance: Users,
    Class: Sword,
    Trade: Hammer,
    Gear: Shield,
    "Mystical Artifacts": Sparkles,
    Location: Map,
    Alignment: Compass,
    Skills: Brain,
    Origin: MapPin,
    "Personality Trait": BookOpen,
    Companion: Cat,
    "Ultimate Goal": Target
  };

  const UtilityCard = ({ title, description, icon: Icon, value, buttonIcon: ButtonIcon }) => (
    React.createElement(motion.div, 
      {
        className: "bg-gray-800 p-4 rounded-lg shadow-lg",
        whileHover: { scale: 1.05 },
        transition: { type: "spring", stiffness: 300 }
      },
      React.createElement('div', { className: "flex items-center justify-between mb-2" },
        React.createElement('div', { className: "flex items-center" },
          React.createElement(Icon, { className: "text-orange-500 mr-2", size: 24 }),
          React.createElement('h4', { className: "text-lg font-bold text-white" }, title)
        ),
        ButtonIcon && React.createElement('button', { className: "bg-orange-500 hover:bg-orange-600 text-white p-2 rounded-full" },
          React.createElement(ButtonIcon, { size: 20 })
        )
      ),
      React.createElement('p', { className: "text-gray-300 mb-2" }, description),
      value && React.createElement('p', { className: "text-xl font-bold text-orange-500" }, value)
    )
  );

  const AcolyteMember = ({ acolyte }) => {
    const dndStats = [
      { name: 'Strength', icon: Shield },
      { name: 'Dexterity', icon: Sword },
      { name: 'Constitution', icon: Heart },
      { name: 'Intelligence', icon: Brain },
      { name: 'Wisdom', icon: FileText },
      { name: 'Charisma', icon: Zap }
    ];
    const otherAttributes = acolyte.attributes.filter(attr => !dndStats.map(stat => stat.name).includes(attr.trait_type));

    const calculateTimeLeft = () => {
      const difference = +new Date('2024-12-12T17:00:00') - +new Date();
      let timeLeft = {};

      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60)
        };
      }

      return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);

      return () => clearTimeout(timer);
    });

    return (
      React.createElement('div', { className: "bg-gray-900 p-6 rounded-lg shadow-lg mb-8" },
        React.createElement('div', { className: "flex flex-col lg:flex-row gap-8" },
          React.createElement('div', { className: "lg:w-3/4" },
            React.createElement('h3', { className: "text-4xl font-bold text-orange-500 mb-4 uppercase" }, acolyte.name),
            React.createElement('div', { className: "flex flex-col md:flex-row gap-8 mb-8" },
              React.createElement('div', { className: "md:w-1/3" },
                React.createElement('img', { src: acolyte.image, alt: acolyte.name, className: "w-full rounded-lg" })
              ),
              React.createElement('div', { className: "md:w-1/3" },
                React.createElement('h4', { className: "text-xl font-bold text-orange-500 mb-4" }, "Backstory"),
                React.createElement('p', { className: "text-gray-300" }, acolyte.backstory)
              ),
              React.createElement('div', { className: "md:w-1/3" },
                React.createElement('h4', { className: "text-xl font-bold text-orange-500 mb-4" }, "Stats"),
                React.createElement('div', { className: "space-y-4" },
                  dndStats.map(stat => {
                    const attrValue = acolyte.attributes.find(attr => attr.trait_type === stat.name)?.value || 10;
                    return React.createElement(StatCard, 
                      { 
                        key: stat.name, 
                        stat: stat.name, 
                        value: attrValue, 
                        icon: stat.icon 
                      }
                    );
                  })
                )
              )
            ),
            React.createElement('div', { className: "mb-8" },
              React.createElement('h4', { className: "text-xl font-bold text-orange-500 mb-4" }, "Attributes"),
              React.createElement('div', { className: "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4" },
                otherAttributes.map((attr, index) => (
                  React.createElement(AttributeCard, 
                    {
                      key: index, 
                      trait_type: attr.trait_type, 
                      value: attr.value, 
                      icon: attributeIcons[attr.trait_type] || Shield 
                    }
                  )
                ))
              )
            )
          ),
          React.createElement('div', { className: "lg:w-1/4 space-y-6" },
            React.createElement(motion.div, 
              {
                className: "bg-gray-800 p-6 rounded-lg shadow-lg",
                whileHover: { scale: 1.05 },
                transition: { type: "spring", stiffness: 300 }
              },
              React.createElement('div', { className: "flex items-center justify-between mb-4" },
                React.createElement('div', { className: "flex items-center" },
                  React.createElement(Gift, { className: "text-orange-500 mr-2", size: 24 }),
                  React.createElement('h4', { className: "text-xl font-bold text-white" }, "Exclusive Airdrop")
                ),
                React.createElement('button', { className: "bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded" }, "Claim")
              ),
              React.createElement('p', { className: "text-gray-300 mb-4" }, "Claim your exclusive ICARUS tokens airdrop, only for Acolyte holders!"),
              React.createElement('div', { className: "bg-gray-700 p-4 rounded-lg" },
                React.createElement('h5', { className: "text-lg font-semibold text-orange-500 mb-2" }, "Next Airdrop In:"),
                React.createElement('div', { className: "grid grid-cols-4 gap-2" },
                  Object.entries(timeLeft).map(([unit, value]) => (
                    React.createElement('div', { key: unit, className: "text-center" },
                      React.createElement('div', { className: "bg-orange-500 text-white text-2xl font-bold rounded-lg p-2" }, value.toString().padStart(2, '0')),
                      React.createElement('div', { className: "text-gray-400 text-xs mt-1" }, unit.toUpperCase())
                    )
                  ))
                )
              )
            ),
            React.createElement(UtilityCard, 
              {
                title: "High-Res Downloads", 
                description: "Download printable high-resolution images of your Acolyte.", 
                icon: Printer,
                buttonIcon: Save
              }
            ),
            React.createElement(UtilityCard, 
              {
                title: "Backgrounds & Avatars", 
                description: "Access exclusive backgrounds and avatar images.", 
                icon: Image,
                buttonIcon: Save
              }
            ),
            React.createElement(motion.div, 
              {
                className: "bg-gradient-to-r from-blue-500 to-purple-500 p-4 rounded-lg shadow-lg",
                whileHover: { scale: 1.05 },
                transition: { type: "spring", stiffness: 300 }
              },
              React.createElement('h4', { className: "text-xl font-bold text-white mb-2" }, "BNB Dividends"),
              React.createElement('p', { className: "text-gray-200 mb-2" }, "Total BNB dividends received:"),
              React.createElement('p', { className: "text-2xl font-bold text-white" }, "0.5 BNB")
            ),
            React.createElement(UtilityCard, 
              {
                title: "Tax Reflections", 
                description: "Total BNB tax reflections received:", 
                icon: Zap,
                value: "1.2 BNB"
              }
            ),
            React.createElement(UtilityCard, 
              {
                title: "Upcoming Events", 
                description: "View and participate in exclusive Acolyte events.", 
                icon: Calendar
              }
            )
          )
        )
      )
    );
  };

  const NonAcolyte = () => (
    React.createElement('div', { className: "bg-gray-900 p-6 rounded-lg shadow-lg" },
      React.createElement('div', { className: "mb-8" },
        React.createElement('img', { src: "/acolytes.png", alt: "ICARUS Acolytes", className: "w-full h-auto rounded-lg shadow-lg" })
      ),
      React.createElement('h2', { className: "text-4xl font-bold text-orange-500 mb-6" }, "Become an ICARUS Acolyte"),
      React.createElement('p', { className: "text-gray-300 mb-8 text-lg" },
        "Step into the extraordinary world of ICARUS Acolytes. Own a piece of digital art that grants you unparalleled access to exclusive benefits, content, and opportunities in the ICARUS ecosystem."
      ),
      React.createElement('div', { className: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-10" },
        React.createElement(motion.div, 
          {
            className: "bg-gray-800 p-6 rounded-lg shadow-lg",
            whileHover: { scale: 1.05 },
            transition: { type: "spring", stiffness: 300 }
          },
          React.createElement(Gift, { className: "text-orange-500 mb-4", size: 36 }),
          React.createElement('h3', { className: "text-xl font-bold text-white mb-3" }, "Exclusive Rewards"),
          React.createElement('p', { className: "text-gray-300" }, "Gain access to special airdrops, bonus rewards, and unique digital assets.")
        ),
        React.createElement(motion.div, 
          {
            className: "bg-gray-800 p-6 rounded-lg shadow-lg",
            whileHover: { scale: 1.05 },
            transition: { type: "spring", stiffness: 300 }
          },
          React.createElement(Lock, { className: "text-orange-500 mb-4", size: 36 }),
          React.createElement('h3', { className: "text-xl font-bold text-white mb-3" }, "VIP Access"),
          React.createElement('p', { className: "text-gray-300" }, "Participate in exclusive events, decision-making processes, and inner circle discussions.")
        ),
        React.createElement(motion.div, 
          {
            className: "bg-gray-800 p-6 rounded-lg shadow-lg",
            whileHover: { scale: 1.05 },
            transition: { type: "spring", stiffness: 300 }
          },
          React.createElement(Zap, { className: "text-orange-500 mb-4", size: 36 }),
          React.createElement('h3', { className: "text-xl font-bold text-white mb-3" }, "Boosted Yields"),
          React.createElement('p', { className: "text-gray-300" }, "Enjoy increased reward rates in staking pools and earn BNB reflections.")
        ),
        React.createElement(motion.div, 
          {
            className: "bg-gray-800 p-6 rounded-lg shadow-lg",
            whileHover: { scale: 1.05 },
            transition: { type: "spring", stiffness: 300 }
          },
          React.createElement(Image, { className: "text-orange-500 mb-4", size: 36 }),
          React.createElement('h3', { className: "text-xl font-bold text-white mb-3" }, "Unique Digital Art"),
          React.createElement('p', { className: "text-gray-300" }, "Own a high-quality, limited edition digital artwork with a rich backstory.")
        ),
        React.createElement(motion.div, 
          {
            className: "bg-gray-800 p-6 rounded-lg shadow-lg",
            whileHover: { scale: 1.05 },
            transition: { type: "spring", stiffness: 300 }
          },
          React.createElement(DollarSign, { className: "text-orange-500 mb-4", size: 36 }),
          React.createElement('h3', { className: "text-xl font-bold text-white mb-3" }, "Passive Income"),
          React.createElement('p', { className: "text-gray-300" }, "Earn BNB dividends and tax reflections just by holding your Acolyte NFT.")
        ),
        React.createElement(motion.div, 
          {
            className: "bg-gray-800 p-6 rounded-lg shadow-lg",
            whileHover: { scale: 1.05 },
            transition: { type: "spring", stiffness: 300 }
          },
          React.createElement(Feather, { className: "text-orange-500 mb-4", size: 36 }),
          React.createElement('h3', { className: "text-xl font-bold text-white mb-3" }, "Community Status"),
          React.createElement('p', { className: "text-gray-300" }, "Gain recognition and influence within the ICARUS community.")
        ),
        React.createElement(motion.div, 
          {
            className: "bg-gray-800 p-6 rounded-lg shadow-lg",
            whileHover: { scale: 1.05 },
            transition: { type: "spring", stiffness: 300 }
          },
          React.createElement(TrendingUp, { className: "text-orange-500 mb-4", size: 36 }),
          React.createElement('h3', { className: "text-xl font-bold text-white mb-3" }, "Increasing Value"),
          React.createElement('p', { className: "text-gray-300" }, "Price increases by 10% with every sale, ensuring growing value for early adopters.")
        ),
        React.createElement(motion.div, 
          {
            className: "bg-gray-800 p-6 rounded-lg shadow-lg",
            whileHover: { scale: 1.05 },
            transition: { type: "spring", stiffness: 300 }
          },
          React.createElement(Wallet, { className: "text-orange-500 mb-4", size: 36 }),
          React.createElement('h3', { className: "text-xl font-bold text-white mb-3" }, "ICA Buybacks"),
          React.createElement('p', { className: "text-gray-300" }, "20% of sales used for ICA buybacks, distributed to Acolytes over 24 months.")
        ),
        React.createElement(motion.div, 
          {
            className: "bg-gray-800 p-6 rounded-lg shadow-lg",
            whileHover: { scale: 1.05 },
            transition: { type: "spring", stiffness: 300 }
          },
          React.createElement(Database, { className: "text-orange-500 mb-4", size: 36 }),
          React.createElement('h3', { className: "text-xl font-bold text-white mb-3" }, "Treasury"),
          React.createElement('p', { className: "text-gray-300" }, "20% allocated to treasury for access to pre-sales and platforms.")
        ),
        React.createElement(motion.div, 
          {
            className: "bg-gray-800 p-6 rounded-lg shadow-lg",
            whileHover: { scale: 1.05 },
            transition: { type: "spring", stiffness: 300 }
          },
          React.createElement(BarChart, { className: "text-orange-500 mb-4", size: 36 }),
          React.createElement('h3', { className: "text-xl font-bold text-white mb-3" }, "Marketing & Promotion"),
          React.createElement('p', { className: "text-gray-300" }, "30% dedicated to marketing efforts to grow the ICARUS ecosystem.")
        ),
        React.createElement(motion.div, 
          {
            className: "bg-gray-800 p-6 rounded-lg shadow-lg",
            whileHover: { scale: 1.05 },
            transition: { type: "spring", stiffness: 300 }
          },
          React.createElement('VoteIcon', { className: "text-orange-500 mb-4", size: 36 }),
          React.createElement('h3', { className: "text-xl font-bold text-white mb-3" }, "DAO Voting Power"),
          React.createElement('p', { className: "text-gray-300" }, "Acolytes receive voting weight in the ICARUS DAO, shaping the future of the project.")
        )
      ),
      React.createElement('div', { className: "bg-gray-800 p-6 rounded-lg shadow-lg mb-10" },
        React.createElement('h3', { className: "text-2xl font-bold text-orange-500 mb-4" }, "Tokenomics"),
        React.createElement('ul', { className: "list-disc list-inside text-gray-300 space-y-2" },
          React.createElement('li', null, "10% of each NFT sale gets distributed to Acolyte holders"),
          React.createElement('li', null, "20% of sales are used for ICA buybacks and distributed over 24 months to Acolytes in ICA"),
          React.createElement('li', null, "20% will be invested in the development of Icarus"),
          React.createElement('li', null, "20% will go towards our treasury for access to pre-sales and platforms"),
          React.createElement('li', null, "30% will go to marketing and promotion"),
        )
      ),
      React.createElement('div', { className: "text-center" },
        React.createElement('a',
          {
            href: "/nft-mint",
            className: "inline-block bg-orange-500 hover:bg-orange-600 text-white font-bold py-4 px-8 rounded-lg text-xl transition duration-300 ease-in-out transform hover:scale-105 shadow-lg"
          },
          "Mint Your Acolyte NFT Now"
        ),
        React.createElement('p', { className: "text-gray-400 mt-4" }, "Limited supply available. Don't miss your chance to be part of the ICARUS elite!")
      )
    )
  );

  if (isLoading) {
    return React.createElement(LoadingAnimation);
  }

  return (
    React.createElement('div', { className: "w-full px-4 sm:px-6 lg:px-8 py-8" },
      React.createElement('h1', { className: "text-4xl font-bold text-orange-500 flex items-center mb-8" },
        React.createElement(Feather, { className: "mr-2" }), " ICARUS Acolytes"
      ),
      error ? (
        React.createElement('p', { className: "text-red-500" }, error)
      ) : isConnected && userAcolytes.length > 0 ? (
        userAcolytes.map(acolyte => (
          React.createElement(AcolyteMember, { key: acolyte.id, acolyte: acolyte })
        ))
      ) : (
        React.createElement(NonAcolyte)
      )
    )
  );
};

export default Acolytes;

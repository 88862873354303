import React from 'react';
import { motion } from 'framer-motion';

const LoadingAnimation = () => {
  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-gray-900">
      <motion.div
        className="relative w-16 h-16 mb-8"
        animate={{ rotate: 360 }}
        transition={{ duration: 4, repeat: Infinity, ease: "linear" }}
      >
        {[...Array(8)].map((_, index) => (
          <motion.span
            key={index}
            className="absolute w-full h-full"
            style={{
              transformOrigin: "50% 50%",
              rotate: `${index * 45}deg`,
            }}
          >
            <motion.span
              className="absolute w-2 h-2 bg-orange-500 rounded-full"
              initial={{ scale: 0.6, opacity: 0.3 }}
              animate={{ scale: [0.6, 1, 0.6], opacity: [0.3, 1, 0.3] }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
                delay: index * 0.2,
              }}
              style={{
                left: "50%",
                top: "-4px", // Adjust this value to position the spots correctly
                transform: "translateX(-50%)",
              }}
            />
          </motion.span>
        ))}
        <motion.div
          className="absolute inset-0 bg-orange-500 rounded-full"
          initial={{ scale: 0.6, opacity: 0.3 }}
          animate={{ scale: [0.6, 0.8, 0.6], opacity: [0.3, 1, 0.3] }}
          transition={{ duration: 1.5, repeat: Infinity }}
        />
      </motion.div>
      <motion.div className="text-orange-500 text-xl font-bold mt-4">
        Loading ICARUS
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: [0, 1, 0] }}
          transition={{ duration: 1.5, repeat: Infinity, times: [0, 0.2, 1] }}
        >
          ...
        </motion.span>
      </motion.div>
    </div>
  );
};

export default LoadingAnimation;
import React, { useState, useEffect, useCallback } from 'react';
import {
  Activity,
  AlertTriangle,
  Clock,
  BarChart2,
  TrendingUp,
  Users,
  Coins,
  DollarSign,
  RefreshCw,
  Zap,
} from 'lucide-react';
import { format } from 'date-fns';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts';
import { formatNumber } from '../../utils/numberUtils';
import { config } from '../../config';
import QuarkUtils from '../../utils/QuarkUtils';
import AlveyUtils from '../../utils/AlveyUtils';
import LoadingAnimation from '../LoadingAnimation';
import ErrorBoundary from '../ErrorBoundary';
import BridgeFlowTool from './BridgeFlowTool';
import AlveyHolders from './AlveyHolders';

const WALV_CONTRACT = '0xCb3e9919C56efF1004E54175a01e39163a352129';

const AlveyChainMonitor = () => {
  const [chainData, setChainData] = useState({
    chainStats: {
      totalTransactions: 0,
      totalAccounts: 0,
      averageBlockTime: 0,
      networkUtilization: 0,
    },
    alvPrice: 0,
    transactionChart: [],
    mainCoinStats: {
      totalSupply: 0,
      circulatingSupply: 0,
      totalHolders: 0,
      marketCap: 0,
    },
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const fetchData = useCallback(async () => {
    setIsRefreshing(true);
    setError(null);
    try {
      const [statsResponse, walvTokenResponse, transactionChartResponse] = await Promise.all([
        AlveyUtils.getStatsCounters(),
        QuarkUtils.getToken(config.alveychainDec, WALV_CONTRACT),
        AlveyUtils.getTransactionsChart(),
      ]);

      setChainData({
        chainStats: {
          totalTransactions: statsResponse?.total_transactions || 0,
          totalAccounts: statsResponse?.total_addresses || 0,
          averageBlockTime: statsResponse?.average_block_time || 0,
          networkUtilization: statsResponse?.network_utilization_percentage || 0,
        },
        alvPrice: walvTokenResponse?.price_usd || 0,
        transactionChart: transactionChartResponse?.chart_data || [],
        mainCoinStats: {
          totalSupply: walvTokenResponse?.ttl_supply || 0,
          circulatingSupply: walvTokenResponse?.crc_supply || 0,
          totalHolders: walvTokenResponse?.holders || 0,
          marketCap: walvTokenResponse?.mcap || 0,
        },
      });

      setLastUpdated(new Date());
    } catch (err) {
      console.error('Error fetching Alvey chain data:', err);
      setError('Failed to fetch chain data. Please try again later.');
    } finally {
      setLoading(false);
      setIsRefreshing(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 600000); // Update every 10 minutes
    return () => clearInterval(intervalId);
  }, [fetchData]);

  if (loading) {
    return <LoadingAnimation />;
  }

  const { chainStats, alvPrice, transactionChart, mainCoinStats } = chainData;

  return (
    <ErrorBoundary>
      <div className="bg-gray-900 text-white p-6">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-4xl font-bold text-orange-500 flex items-center">
            <Activity className="mr-4" size={36} /> AlveyChain Monitor
          </h2>
          <button
            onClick={fetchData}
            className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded flex items-center transition-colors duration-300"
            disabled={isRefreshing}
          >
            <RefreshCw className={`mr-2 ${isRefreshing ? 'animate-spin' : ''}`} size={20} />
            Refresh Data
          </button>
        </div>

        {error && (
          <div className="bg-red-900 border-l-4 border-red-500 text-red-300 p-4 mb-6 rounded-lg" role="alert">
            <AlertTriangle className="inline-block mr-2" size={20} />
            {error}
          </div>
        )}

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
          <div className="bg-gradient-to-r from-orange-500 to-yellow-500 p-6 rounded-lg shadow-lg">
            <h3 className="text-2xl font-bold text-white mb-2 flex items-center">
              <DollarSign className="mr-2" size={24} /> ALV Price
            </h3>
            <p className="text-4xl font-bold text-white">{formatNumber(alvPrice, 'lowPriceUSD')}</p>
          </div>
          {/* Updated Chain & Coin Statistics Card */}
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
            <h3 className="text-2xl font-bold text-orange-500 mb-4 flex items-center">
              <BarChart2 className="mr-2" size={24} /> Chain & Coin Statistics
            </h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
              {/* Total Transactions */}
              <div className="bg-gray-700 p-4 rounded-lg">
                <p className="text-sm text-gray-400 flex items-center">
                  <Activity className="mr-2" size={16} /> Total Transactions
                </p>
                <p className="text-xl font-bold text-white">{formatNumber(chainStats.totalTransactions)}</p>
              </div>
              {/* Total Accounts */}
              <div className="bg-gray-700 p-4 rounded-lg">
                <p className="text-sm text-gray-400 flex items-center">
                  <Users className="mr-2" size={16} /> Total Accounts
                </p>
                <p className="text-xl font-bold text-white">{formatNumber(chainStats.totalAccounts)}</p>
              </div>
              {/* Avg Block Time */}
              <div className="bg-gray-700 p-4 rounded-lg">
                <p className="text-sm text-gray-400 flex items-center">
                  <Clock className="mr-2" size={16} /> Avg Block Time
                </p>
                <p className="text-xl font-bold text-white">
                  {chainStats.averageBlockTime.toFixed(2)} ms
                </p>
              </div>
              {/* Market Cap */}
              <div className="bg-gray-700 p-4 rounded-lg">
                <p className="text-sm text-gray-400 flex items-center">
                  <DollarSign className="mr-2" size={16} /> Market Cap
                </p>
                <p className="text-xl font-bold text-white">${formatNumber(mainCoinStats.marketCap)}</p>
              </div>
              {/* Total Supply */}
              <div className="bg-gray-700 p-4 rounded-lg">
                <p className="text-sm text-gray-400 flex items-center">
                  <Coins className="mr-2" size={16} /> Total Supply
                </p>
                <p className="text-xl font-bold text-white">
                  {formatNumber(mainCoinStats.totalSupply)} ALV
                </p>
              </div>
              {/* Circulating Supply */}
              <div className="bg-gray-700 p-4 rounded-lg">
                <p className="text-sm text-gray-400 flex items-center">
                  <TrendingUp className="mr-2" size={16} /> Circulating Supply
                </p>
                <p className="text-xl font-bold text-white">
                  {formatNumber(mainCoinStats.circulatingSupply)} ALV
                </p>
              </div>
              {/* Total Holders */}
              <div className="bg-gray-700 p-4 rounded-lg">
                <p className="text-sm text-gray-400 flex items-center">
                  <Users className="mr-2" size={16} /> Total Holders
                </p>
                <p className="text-xl font-bold text-white">
                  {formatNumber(mainCoinStats.totalHolders)}
                </p>
              </div>
              {/* Network Utilization */}
              <div className="bg-gray-700 p-4 rounded-lg">
                <p className="text-sm text-gray-400 flex items-center">
                  <Zap className="mr-2" size={16} /> Network Utilization
                </p>
                <p className="text-xl font-bold text-white">
                  {chainStats.networkUtilization.toFixed(2)}%
                </p>
              </div>
            </div>
          </div>
        </div>

        <ErrorBoundary>
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg mt-6">
            <h3 className="text-2xl font-bold text-orange-500 mb-4 flex items-center">
              <BarChart2 className="mr-2" size={24} /> Daily Transactions
            </h3>
            {transactionChart.length > 0 ? (
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={transactionChart}>
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="tx_count" fill="#FF9800" />
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <p className="text-gray-300">No transaction data available.</p>
            )}
          </div>
        </ErrorBoundary>

        <ErrorBoundary>
          <AlveyHolders />
        </ErrorBoundary>

        <ErrorBoundary>
          <BridgeFlowTool />
        </ErrorBoundary>

        {lastUpdated && (
          <div className="text-gray-400 text-sm mt-6 flex items-center justify-end">
            <Clock size={14} className="mr-2" />
            Last updated: {format(lastUpdated, 'yyyy-MM-dd HH:mm:ss')}
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default AlveyChainMonitor;

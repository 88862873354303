// QuarkUtils.js

import { config } from '../config';

const BASE_URL = 'https://cryptoapi.quarkproject.com/api/v1';

const headers = {
  'api-key': config.quarkApikey,
  'Content-Type': 'application/json',
};

const handleResponse = async (response) => {
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'An error occurred');
  }
  return response.json();
};

const createApiCall = (endpoint) => async (chainId, ...params) => {
  const url = `${BASE_URL}${endpoint.replace('{chain_id}', chainId)}`;
  const response = await fetch(url.replace(/\{.*?\}/g, () => params.shift()), { headers });
  return handleResponse(response);
};

// New function to wrap API calls with fallback
const withFallback = (apiCall, fallbackValue = 'N/A') => async (...args) => {
  try {
    return await apiCall(...args);
  } catch (error) {
    console.error(`API call failed: ${error.message}`);
    return fallbackValue;
  }
};


const QuarkUtils = {
  // Blockchain
  getNetwork: withFallback(createApiCall('/chain/{chain_id}')),
  getAllNetworks: withFallback(createApiCall('/chains')),

  // Token
  getTokenPrice: withFallback(createApiCall('/chain/{chain_id}/token/{contract}/price')),
  getTokenAbi: withFallback(createApiCall('/chain/{chain_id}/token/{contract}/abi')),
  getToken: withFallback(createApiCall('/chain/{chain_id}/token/{contract}')),
  getAllTokens: withFallback(createApiCall('/chain/{chain_id}/tokens')),

  // Pair
  getPair: withFallback(createApiCall('/chain/{chain_id}/pair/{address}')),
  getPairOhlcv: withFallback(createApiCall('/chain/{chain_id}/pair/{address}/ohlcv')),
  getPairAbi: withFallback(createApiCall('/chain/{chain_id}/pair/{address}/abi')),
  getTokenPairs: withFallback(createApiCall('/chain/{chain_id}/token/{contract}/pairs')),
  getAllPairs: withFallback(createApiCall('/chain/{chain_id}/pairs')),
  getTrendingPairs: withFallback(createApiCall('/chain/{chain_id}/pairs/trending')),

  // Block
  getBlock: withFallback(createApiCall('/chain/{chain_id}/block/{hash}')),
  getAllBlocks: withFallback(createApiCall('/chain/{chain_id}/blocks')),

  // Signature
  getSignature: withFallback(createApiCall('/signature')),
  getAllSignatures: withFallback(createApiCall('/signatures')),

  // Factory
  getFactoryAbi: withFallback(createApiCall('/chain/{chain_id}/factory/{address}/abi')),
  getFactory: withFallback(createApiCall('/chain/{chain_id}/factory/{address}')),
  getAllFactories: withFallback(createApiCall('/chain/{chain_id}/factories')),

  // Router
  getRouterAbi: withFallback(createApiCall('/chain/{chain_id}/router/{address}/abi')),
  getRouter: withFallback(createApiCall('/chain/{chain_id}/router/{address}')),
  getFactoryRouters: withFallback(createApiCall('/chain/{chain_id}/factory/{address}/routers')),
  getAllRouters: withFallback(createApiCall('/chain/{chain_id}/routers')),
};



export default QuarkUtils;
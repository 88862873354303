import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { ArrowRightLeft, BarChart2, ArrowDownLeft, ArrowUpRight, TrendingUp, TrendingDown, Clock } from 'lucide-react';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { formatNumber, formatAddress } from '../../utils/numberUtils';
import { format, subMonths, startOfWeek, startOfMonth, subDays, parseISO } from 'date-fns';
import { EvmChain } from '@moralisweb3/common-evm-utils';
import Moralis from 'moralis';
import { config } from '../../config';
import ErrorBoundary from '../ErrorBoundary';
import LoadingAnimation from '../LoadingAnimation';

const BRIDGE_WALLET = '0xe641dE87F5b3539BE6C6F717069FA90F105B5B5f';
const WALV_DECIMALS = 18;
const MAX_TRANSACTIONS_DISPLAY = 5;

const BridgeFlowTool = () => {
  const [bridgeData, setBridgeData] = useState({
    transactions: [],
    balanceHistory: [],
    bscFlow: {},
    ethFlow: {},
    mainStats: {}
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timeframe, setTimeframe] = useState('daily');

  useEffect(() => {
    let isMounted = true;

    const fetchBridgeData = async () => {
      try {
        if (!Moralis.Core.isStarted) {
          await Moralis.start({ apiKey: config.moralisApiKey });
        }

        const [ethTransfers, bscTransfers] = await Promise.all([
          Moralis.EvmApi.token.getWalletTokenTransfers({
            address: BRIDGE_WALLET,
            chain: EvmChain.ETHEREUM,
          }),
          Moralis.EvmApi.token.getWalletTokenTransfers({
            address: BRIDGE_WALLET,
            chain: EvmChain.BSC,
          }),
        ]);

        if (!isMounted) return;

        const allTransfers = [...(ethTransfers.result || []), ...(bscTransfers.result || [])];

        const processedTransactions = allTransfers
          .map(tx => ({
            hash: tx.transactionHash,
            from: tx.fromAddress?.lowercase,
            to: tx.toAddress?.lowercase,
            value: parseFloat(tx.value) / Math.pow(10, WALV_DECIMALS),
            isInflow: tx.toAddress?.lowercase === BRIDGE_WALLET.toLowerCase(),
            timestamp: new Date(tx.blockTimestamp),
            chain: tx.chain?.hex
          }))
          .filter(tx => tx.from && tx.to && tx.value)
          .sort((a, b) => b.timestamp - a.timestamp);

        const history = calculateBridgeHistory(processedTransactions);
        const { bscFlow, ethFlow } = calculateChainFlows(processedTransactions);
        const mainStats = calculateMainStats(processedTransactions);

        setBridgeData({
          transactions: processedTransactions,
          balanceHistory: history,
          bscFlow,
          ethFlow,
          mainStats
        });

        setLoading(false);
      } catch (err) {
        console.error('Error fetching bridge data:', err);
        if (isMounted) {
          setError('Failed to fetch bridge data. Please try again later.');
          setLoading(false);
        }
      }
    };

    fetchBridgeData();

    return () => {
      isMounted = false;
    };
  }, []);

  const calculateBridgeHistory = (transactions) => {
    const startDate = subMonths(new Date(), 1);
    let balance = 0;
    const dailyData = {};

    transactions.forEach(tx => {
      if (tx.timestamp >= startDate) {
        const dateKey = format(tx.timestamp, 'yyyy-MM-dd');
        if (!dailyData[dateKey]) {
          dailyData[dateKey] = { inflow: 0, outflow: 0, balance: 0 };
        }
        if (tx.isInflow) {
          balance += tx.value;
          dailyData[dateKey].inflow += tx.value;
        } else {
          balance -= tx.value;
          dailyData[dateKey].outflow += tx.value;
        }
        dailyData[dateKey].balance = balance;
      }
    });

    return Object.entries(dailyData).map(([date, data]) => ({
      date,
      balance: data.balance,
      inflow: data.inflow,
      outflow: data.outflow,
      netFlow: data.inflow - data.outflow
    }));
  };

  const calculateChainFlows = (transactions) => {
    const startDate = subMonths(new Date(), 1);
    const bscFlow = {};
    const ethFlow = {};

    transactions.forEach(tx => {
      if (tx.timestamp >= startDate) {
        const dateKey = format(tx.timestamp, 'yyyy-MM-dd');
        const flow = tx.chain === '0x38' ? bscFlow : ethFlow;
        if (!flow[dateKey]) {
          flow[dateKey] = { inflow: 0, outflow: 0 };
        }
        if (tx.isInflow) {
          flow[dateKey].inflow += tx.value;
        } else {
          flow[dateKey].outflow += tx.value;
        }
      }
    });

    return { bscFlow, ethFlow };
  };

  const calculateMainStats = (transactions) => {
    const now = new Date();
    const dayAgo = subDays(now, 1);
    const weekAgo = subDays(now, 7);
    const monthAgo = subDays(now, 30);

    let dailyInflow = 0, dailyOutflow = 0;
    let weeklyInflow = 0, weeklyOutflow = 0;
    let monthlyInflow = 0, monthlyOutflow = 0;
    let totalInflow = 0, totalOutflow = 0;

    transactions.forEach(tx => {
      if (tx.timestamp >= monthAgo) {
        if (tx.isInflow) {
          totalInflow += tx.value;
          if (tx.timestamp >= dayAgo) dailyInflow += tx.value;
          if (tx.timestamp >= weekAgo) weeklyInflow += tx.value;
          monthlyInflow += tx.value;
        } else {
          totalOutflow += tx.value;
          if (tx.timestamp >= dayAgo) dailyOutflow += tx.value;
          if (tx.timestamp >= weekAgo) weeklyOutflow += tx.value;
          monthlyOutflow += tx.value;
        }
      }
    });

    const avgDailyInflow = monthlyInflow / 30;
    const avgDailyOutflow = monthlyOutflow / 30;

    return {
      dailyInflow,
      dailyOutflow,
      weeklyInflow,
      weeklyOutflow,
      monthlyInflow,
      monthlyOutflow,
      avgDailyInflow,
      avgDailyOutflow
    };
  };

  const getAggregatedData = (flowData) => {
    switch (timeframe) {
      case 'weekly':
        return calculateAggregatedFlow(flowData, date => format(startOfWeek(new Date(date)), 'yyyy-MM-dd'));
      case 'monthly':
        return calculateAggregatedFlow(flowData, date => format(startOfMonth(new Date(date)), 'yyyy-MM'));
      default:
        return flowData.reduce((acc, day) => {
          acc[day.date] = { inflow: day.inflow, outflow: day.outflow, netFlow: day.netFlow };
          return acc;
        }, {});
    }
  };

  const calculateAggregatedFlow = (data, aggregateFunc) => {
    return data.reduce((acc, day) => {
      const key = aggregateFunc(new Date(day.date));
      if (!acc[key]) {
        acc[key] = { inflow: 0, outflow: 0, netFlow: 0 };
      }
      acc[key].inflow += day.inflow;
      acc[key].outflow += day.outflow;
      acc[key].netFlow += day.netFlow;
      return acc;
    }, {});
  };

  const aggregatedBscData = useMemo(() => 
    getAggregatedData(Object.entries(bridgeData.bscFlow).map(([date, data]) => ({ date, ...data }))),
    [bridgeData.bscFlow, timeframe]
  );

  const aggregatedEthData = useMemo(() => 
    getAggregatedData(Object.entries(bridgeData.ethFlow).map(([date, data]) => ({ date, ...data }))),
    [bridgeData.ethFlow, timeframe]
  );

  const bscChartData = useMemo(() => 
    Object.entries(aggregatedBscData).map(([key, value]) => ({
      date: key,
      inflow: value.inflow,
      outflow: value.outflow,
      netFlow: value.inflow - value.outflow
    })),
    [aggregatedBscData]
  );

  const ethChartData = useMemo(() => 
    Object.entries(aggregatedEthData).map(([key, value]) => ({
      date: key,
      inflow: value.inflow,
      outflow: value.outflow,
      netFlow: value.inflow - value.outflow
    })),
    [aggregatedEthData]
  );

  const recentBscTransactions = useMemo(() => 
    bridgeData.transactions.filter(tx => tx.chain === '0x38').slice(0, MAX_TRANSACTIONS_DISPLAY),
    [bridgeData.transactions]
  );

  const recentEthTransactions = useMemo(() => 
    bridgeData.transactions.filter(tx => tx.chain === '0x1').slice(0, MAX_TRANSACTIONS_DISPLAY),
    [bridgeData.transactions]
  );

  if (loading) {
    return <LoadingAnimation />;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  const renderFlowCard = (title, inflow, outflow) => {
    const netFlow = inflow - outflow;
    const isPositive = netFlow >= 0;
    return (
      <div className="bg-gray-800 p-4 rounded-lg shadow-lg">
        <h5 className="text-lg font-semibold text-white mb-2">{title}</h5>
        <div className="flex flex-col items-center">
          <div className={`text-2xl font-bold ${isPositive ? 'text-green-500' : 'text-red-500'} flex items-center`}>
            {isPositive ? <ArrowDownLeft size={24} className="mr-2" /> : <ArrowUpRight size={24} className="mr-2" />}
            {formatNumber(Math.abs(netFlow), 'ALV')} ALV
          </div>
          <div className="flex justify-between w-full mt-2">
            <div className="flex items-center">
              <ArrowDownLeft className="text-green-500 mr-2" size={16} />
              <span className="text-green-500 text-sm">{formatNumber(inflow, 'ALV')} ALV</span>
            </div>
            <div className="flex items-center">
              <ArrowUpRight className="text-red-500 mr-2" size={16} />
              <span className="text-red-500 text-sm">{formatNumber(outflow, 'ALV')} ALV</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <ErrorBoundary>
      <div className="mt-8 bg-gray-900 p-6 rounded-lg">
        <h3 className="text-2xl font-bold text-blue-400 mb-4 flex items-center">
          <ArrowRightLeft className="mr-2" size={24} /> Bridge Flow Tool
        </h3>

        {/* Main Bridge Stats Card */}
        <div className="bg-gradient-to-r from-orange-500 to-yellow-500 p-6 rounded-lg shadow-lg mb-6">
          <h4 className="text-xl font-bold text-white mb-4">Bridge Overview</h4>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {renderFlowCard("Last 24 Hours", bridgeData.mainStats.dailyInflow, bridgeData.mainStats.dailyOutflow)}
            {renderFlowCard("Last 7 Days", bridgeData.mainStats.weeklyInflow, bridgeData.mainStats.weeklyOutflow)}
            {renderFlowCard("Last 30 Days", bridgeData.mainStats.monthlyInflow, bridgeData.mainStats.monthlyOutflow)}
            <div className="bg-gray-800 p-4 rounded-lg shadow-lg">
              <h5 className="text-lg font-semibold text-white mb-2">30-Day Average</h5>
              <div className="flex justify-between">
                <div className="flex items-center">
                  <TrendingUp className="text-green-500 mr-2" size={16} />
                  <span className="text-green-500 text-sm">{formatNumber(bridgeData.mainStats.avgDailyInflow, 'ALV')} ALV</span>
                </div>
                <div className="flex items-center">
                  <TrendingDown className="text-red-500 mr-2" size={16} />
                  <span className="text-red-500 text-sm">{formatNumber(bridgeData.mainStats.avgDailyOutflow, 'ALV')} ALV</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* BSC and ETH Flow Cards */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
            <h4 className="text-xl font-bold text-white mb-4 flex items-center">
              <BarChart2 className="mr-2" size={20} /> BSC Bridge Flow
            </h4>
            <div className="mb-4">
              {['daily', 'weekly', 'monthly'].map((tf) => (
                <button
                  key={tf}
                  onClick={() => setTimeframe(tf)}
                  className={`mr-2 px-3 py-1 rounded ${timeframe === tf ? 'bg-blue-500' : 'bg-gray-600'}`}
                >
                  {tf.charAt(0).toUpperCase() + tf.slice(1)}
                </button>
              ))}
            </div>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={bscChartData}>
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="inflow" stroke="#10B981" name="Inflow" />
                <Line type="monotone" dataKey="outflow" stroke="#EF4444" name="Outflow" />
                <Line type="monotone" dataKey="netFlow" stroke="#3B82F6" name="Net Flow" />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
            <h4 className="text-xl font-bold text-white mb-4 flex items-center">
              <BarChart2 className="mr-2" size={20} /> ETH Bridge Flow
            </h4>
            <div className="mb-4">
              {['daily', 'weekly', 'monthly'].map((tf) => (
                <button
                  key={tf}
                  onClick={() => setTimeframe(tf)}
                  className={`mr-2 px-3 py-1 rounded ${timeframe === tf ? 'bg-blue-500' : 'bg-gray-600'}`}
                >
                  {tf.charAt(0).toUpperCase() + tf.slice(1)}
                </button>
              ))}
            </div>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={ethChartData}>
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="inflow" stroke="#10B981" name="Inflow" />
                <Line type="monotone" dataKey="outflow" stroke="#EF4444" name="Outflow" />
                <Line type="monotone" dataKey="netFlow" stroke="#3B82F6" name="Net Flow" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Recent BSC Transactions */}
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg mb-6">
          <h4 className="text-xl font-bold text-white mb-4 flex items-center">
            <ArrowRightLeft className="mr-2" size={20} /> Recent BSC Bridge Transactions
          </h4>
          <div className="overflow-x-auto">
            <table className="min-w-full">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left">Time</th>
                  <th className="px-4 py-2 text-left">Transaction</th>
                  <th className="px-4 py-2 text-left">From/To</th>
                  <th className="px-4 py-2 text-right">wALV Amount</th>
                </tr>
              </thead>
              <tbody>
                {recentBscTransactions.map((tx) => (
                  <tr key={tx.hash} className="bg-gray-700">
                    <td className="px-4 py-2">{format(tx.timestamp, 'yyyy-MM-dd HH:mm:ss')}</td>
                    <td className="px-4 py-2">
                      <a href={`https://bscscan.com/tx/${tx.hash}`} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
                        {formatAddress(tx.hash)}
                      </a>
                    </td>
                    <td className="px-4 py-2 flex items-center">
                      {tx.isInflow ? <ArrowDownLeft className="text-green-500 mr-2" size={16} /> : <ArrowUpRight className="text-red-500 mr-2" size={16} />}
                      <a href={`https://bscscan.com/address/${tx.isInflow ? tx.from : tx.to}`} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
                        {formatAddress(tx.isInflow ? tx.from : tx.to)}
                      </a>
                    </td>
                    <td className="px-4 py-2 text-right">{formatNumber(tx.value, 'wALV')} wALV</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Recent ETH Transactions */}
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
          <h4 className="text-xl font-bold text-white mb-4 flex items-center">
            <ArrowRightLeft className="mr-2" size={20} /> Recent ETH Bridge Transactions
          </h4>
          <div className="overflow-x-auto">
            <table className="min-w-full">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left">Time</th>
                  <th className="px-4 py-2 text-left">Transaction</th>
                  <th className="px-4 py-2 text-left">From/To</th>
                  <th className="px-4 py-2 text-right">wALV Amount</th>
                </tr>
              </thead>
              <tbody>
                {recentEthTransactions.map((tx) => (
                  <tr key={tx.hash} className="bg-gray-700">
                    <td className="px-4 py-2">{format(tx.timestamp, 'yyyy-MM-dd HH:mm:ss')}</td>
                    <td className="px-4 py-2">
                      <a href={`https://etherscan.io/tx/${tx.hash}`} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
                        {formatAddress(tx.hash)}
                      </a>
                    </td>
                    <td className="px-4 py-2 flex items-center">
                     {tx.isInflow ? <ArrowDownLeft className="text-green-500 mr-2" size={16} /> : <ArrowUpRight className="text-red-500 mr-2" size={16} />}
                      <a href={`https://etherscan.io/address/${tx.isInflow ? tx.from : tx.to}`} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
                        {formatAddress(tx.isInflow ? tx.from : tx.to)}
                      </a>
                    </td>
                    <td className="px-4 py-2 text-right">{formatNumber(tx.value, 'wALV')} wALV</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default BridgeFlowTool;
import React, { useState, useEffect, useMemo } from 'react';
import { motion } from 'framer-motion';
import {
  Users,
  Wallet,
  DollarSign,
  BarChart2,
  Info,
  RefreshCw,
  ArrowDownLeft,
  ArrowUpRight,
  TrendingUp,
  TrendingDown,
  Clock,
  ArrowRightLeft,
} from 'lucide-react';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  Legend,
  LineChart,
  Line,
  CartesianGrid,
  AreaChart,
  Area,
  ScatterChart,
  Scatter,
  ZAxis,
} from 'recharts';
import { formatNumber, formatAddress } from '../../utils/numberUtils';
import AlveyUtils from '../../utils/AlveyUtils';
import QuarkUtils from '../../utils/QuarkUtils';
import { config } from '../../config';
import ErrorBoundary from '../ErrorBoundary';
import LoadingAnimation from '../LoadingAnimation';
import { useTable, useSortBy, usePagination, useGlobalFilter } from 'react-table';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const DECIMALS = 18;
const COLORS = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F7DC6F'];
const WALV_CONTRACT = '0x256d1fce1b1221e8398f65f9b36033ce50b2d497';
const EXCLUDED_WALLETS = [
  { address: '0xCb3e9919C56efF1004E54175a01e39163a352129', label: 'Team Wallet' },
  { address: '0xe641dE87F5b3539BE6C6F717069FA90F105B5B5f', label: 'Bridge Wallet' },
  { address: '0x000000000000000000000000000000000000dEaD', label: 'Burn Address' },
];
const EXCLUDED_ADDRESSES = EXCLUDED_WALLETS.map((wallet) => wallet.address.toLowerCase());
const CACHE_KEY = 'alveyHoldersData';
const CACHE_DURATION = 8 * 60 * 60 * 1000;

const GlowingCard = ({ children, glowColor = 'rgba(255, 152, 0, 0.5)', className = '' }) => (
  <motion.div
    className={`relative bg-gray-800 rounded-xl overflow-hidden ${className}`}
    whileHover={{ scale: 1.02 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <div className="absolute inset-0 bg-gradient-to-r from-transparent via-gray-700 to-transparent opacity-50" />
    <div className="relative z-10 p-4">
      {children}
    </div>
    <div
      className="absolute inset-0 opacity-0 hover:opacity-100 transition-opacity duration-300"
      style={{
        boxShadow: `0 0 20px ${glowColor}`,
        filter: 'blur(15px)',
        zIndex: -1,
      }}
    />
  </motion.div>
);

const AlveyHolders = () => {
  const [holderData, setHolderData] = useState({
    topHolders: [],
    distribution: [],
    totalSupply: 0,
    giniCoefficient: 0,
    alvPrice: 0,
    totalHolders: 0,
    averageBalance: 0,
    medianBalance: 0,
    topHoldersPercentage: 0,
    bridgeBalance: 0,
    burnBalance: 0,
    teamBalances: {},
    lorenzData: [],
    histogramData: [],
    cumulativeData: [],
    mostActiveAddresses: [],
    balanceTxCountData: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchHolderData = async (forceRefresh = false) => {
    try {
      const cachedData = localStorage.getItem(CACHE_KEY);
      const cacheTimestamp = localStorage.getItem(`${CACHE_KEY}_timestamp`);
      const now = new Date().getTime();

      if (!forceRefresh && cachedData && cacheTimestamp && now - cacheTimestamp < CACHE_DURATION) {
        setHolderData(JSON.parse(cachedData));
        setLoading(false);
        return;
      }

      setLoading(true);

      const tokenData = await QuarkUtils.getToken(config.bscChainId, WALV_CONTRACT);
      const totalSupplyValue = parseFloat(tokenData?.ttl_supply) || 0;
      const price = tokenData?.price_usd || 0;

      if (totalSupplyValue === 0) {
        throw new Error('Total supply is zero or invalid');
      }

      let holdersData = [];
      let fetchedCount = 0;
      const limit = 50;
      let nextPageParams = null;

      while (fetchedCount < 5000) {
        const holdersResponse = await AlveyUtils.getNativeCoinHolders({
          sort: 'coin_balance',
          order: 'desc',
          limit,
          ...nextPageParams,
        });

        const pageData = holdersResponse?.items || [];
        if (!Array.isArray(pageData) || pageData.length === 0) break;

        holdersData = holdersData.concat(pageData);
        fetchedCount += pageData.length;
        nextPageParams = holdersResponse.next_page_params;
        if (!nextPageParams) break;
      }

      holdersData = holdersData.slice(0, 5000);

      const processedData = processHolderData(holdersData, totalSupplyValue, price);
      setHolderData(processedData);
      localStorage.setItem(CACHE_KEY, JSON.stringify(processedData));
      localStorage.setItem(`${CACHE_KEY}_timestamp`, new Date().getTime());
    } catch (error) {
      console.error('Error fetching holder data:', error);
      setError(`Failed to fetch holder data: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHolderData();
  }, []);

  const processHolderData = (holdersData, totalSupplyValue, price) => {
    const balances = [];
    const topHolders = [];
    let topHoldersTotalBalance = 0;
    let totalBalance = 0;
    let bridgeBalance = 0;
    let burnBalance = 0;
    let teamBalances = {};
    const txCounts = [];
    const balanceTxCountData = [];

    holdersData.forEach((holder, index) => {
      const address = holder.hash.toLowerCase();
      const balanceInALV = (parseFloat(holder.coin_balance) || 0) / Math.pow(10, DECIMALS);
      const txCount = holder.tx_count || 0;

      const excludedWallet = EXCLUDED_WALLETS.find(
        (wallet) => wallet.address.toLowerCase() === address
      );

      if (excludedWallet) {
        if (excludedWallet.label === 'Bridge Wallet') {
          bridgeBalance = balanceInALV;
        } else if (excludedWallet.label === 'Burn Address') {
          burnBalance = balanceInALV;
        } else if (excludedWallet.label === 'Team Wallet') {
          teamBalances[address] = balanceInALV;
        }
        return;
      }

      balances.push(balanceInALV);
      totalBalance += balanceInALV;
      txCounts.push({ address, txCount });
      balanceTxCountData.push({ address, balance: balanceInALV, txCount });

      if (index < 10) {
        topHoldersTotalBalance += balanceInALV;
      }

      if (index < 100) {
        topHolders.push({
          address: holder.hash,
          balance: balanceInALV,
          percentage: (balanceInALV / totalSupplyValue) * 100,
          value: balanceInALV * price,
        });
      }
    });

    const totalHolders = balances.length;
    const averageBalance = totalBalance / totalHolders || 0;
    const sortedBalances = [...balances].sort((a, b) => a - b);
    const medianBalance = totalHolders % 2 === 0
      ? (sortedBalances[totalHolders / 2 - 1] + sortedBalances[totalHolders / 2]) / 2
      : sortedBalances[Math.floor(totalHolders / 2)];

    const topHoldersPercentage = (topHoldersTotalBalance / totalSupplyValue) * 100;
    const distribution = calculateHolderDistribution(balances, totalSupplyValue);
    const gini = calculateGiniCoefficient(balances);
    const lorenzData = calculateLorenzCurve(balances);
    const histogramData = calculateHistogramData(balances);
    const cumulativeData = calculateCumulativeTopHolders(balances, totalSupplyValue);
    const mostActiveAddresses = txCounts
      .sort((a, b) => b.txCount - a.txCount)
      .slice(0, 10)
      .map(item => ({ address: item.address, txCount: item.txCount }));

    return {
      topHolders,
      distribution,
      totalSupply: totalSupplyValue,
      giniCoefficient: gini,
      alvPrice: price,
      totalHolders,
      averageBalance,
      medianBalance,
      topHoldersPercentage,
      bridgeBalance,
      burnBalance,
      teamBalances,
      lorenzData,
      histogramData,
      cumulativeData,
      mostActiveAddresses,
      balanceTxCountData,
    };
  };

  const calculateHolderDistribution = (balances, totalSupply) => {
    const totalHolders = balances.length;
    const brackets = [
      { name: 'Top 1%', percentage: 0.01 },
      { name: 'Top 1-5%', percentage: 0.04 },
      { name: 'Top 5-15%', percentage: 0.10 },
      { name: 'Top 15-30%', percentage: 0.15 },
      { name: 'Top 30-60%', percentage: 0.30 },
      { name: 'Bottom 40%', percentage: 0.40 },
    ];

    let cumulativePercentage = 0;
    return brackets.map((bracket) => {
      const startIndex = Math.floor(totalHolders * cumulativePercentage);
      cumulativePercentage += bracket.percentage;
      const endIndex = Math.floor(totalHolders * cumulativePercentage);

      let bracketBalance = 0;
      for (let i = startIndex; i < endIndex && i < totalHolders; i++) {
        bracketBalance += balances[i];
      }

      return {
        name: bracket.name,
        value: bracketBalance,
        percentage: (bracketBalance / totalSupply) * 100,
      };
    });
  };

  const calculateGiniCoefficient = (balances) => {
    const n = balances.length;
    if (n === 0) return 0;

    const sortedBalances = [...balances].sort((a, b) => a - b);
    const mean = sortedBalances.reduce((sum, value) => sum + value, 0) / n;
    let cumulativeDifference = 0;

    for (let i = 0; i < n; i++) {
      cumulativeDifference += (i + 1) * sortedBalances[i];
    }

    const numerator = 2 * cumulativeDifference - (n + 1) * mean * n;
    const denominator = n * n * mean;

    return denominator !== 0 ? numerator / denominator : 0;
  };

  const calculateLorenzCurve = (balances) => {
    const total = balances.reduce((sum, val) => sum + val, 0);
    const sortedBalances = [...balances].sort((a, b) => a - b);
    let cumulativeSum = 0;
    return sortedBalances.map((balance, index) => {
      cumulativeSum += balance;
      return {
        percentPopulation: ((index + 1) / balances.length) * 100,
        percentWealth: (cumulativeSum / total) * 100,
      };
    });
  };

  const calculateHistogramData = (balances) => {
    const bins = [
      { name: '<1 ALV', range: [0, 1], count: 0 },
      { name: '1-10 ALV', range: [1, 10], count: 0 },
      { name: '10-100 ALV', range: [10, 100], count: 0 },
      { name: '100-1K ALV', range: [100, 1000], count: 0 },
      { name: '1K-10K ALV', range: [1000, 10000], count: 0 },
      { name: '10K-100K ALV', range: [10000, 100000], count: 0 },
      { name: '>100K ALV', range: [100000, Infinity], count: 0 },
    ];

    balances.forEach((balance) => {
      for (let bin of bins) {
        if (balance >= bin.range[0] && balance < bin.range[1]) {
          bin.count += 1;
          break;
        }
      }
    });

    return bins.map((bin) => ({ name: bin.name, count: bin.count }));
  };

  const calculateCumulativeTopHolders = (balances, totalSupply) => {
    const sortedBalances = [...balances].sort((a, b) => b - a);
    let cumulativeBalance = 0;
    return sortedBalances.map((balance, index) => {
      cumulativeBalance += balance;
      return {
        holderRank: index + 1,
        cumulativePercentage: (cumulativeBalance / totalSupply) * 100,
      };
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Address',
        accessor: 'address',
        Cell: ({ value }) => (
          <a
            href={`https://alveyscan.com/address/${value}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-400 hover:text-blue-300"
          >
            {formatAddress(value)}
          </a>
        ),
      },
      {
        Header: 'Balance',
        accessor: 'balance',
        Cell: ({ value }) => formatNumber(value || 0, 'ALV'),
        isNumeric: true,
      },
      {
        Header: '% of Supply',
        accessor: 'percentage',
		Cell: ({ value }) => `${(value || 0).toFixed(4)}%`,
        isNumeric: true,
      },
      {
        Header: 'Value',
        accessor: 'value',
        Cell: ({ value }) => formatNumber(value || 0, 'USD'),
        isNumeric: true,
		},
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: holderData.topHolders,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  if (loading) {
    return <LoadingAnimation />;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

 return (
    <ErrorBoundary>
      <div className="mt-8 bg-gray-900 p-6 rounded-lg">
        <h3 className="text-2xl font-bold text-orange-500 mb-4 flex items-center">
          <Users className="mr-2" size={24} /> Alvey Holders
        </h3>

        {/* Main Statistics */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mb-6">
          <GlowingCard>
            <h4 className="text-xl font-bold text-blue-400 mb-2 flex items-center">
              <Users className="mr-2" size={20} /> Holder Statistics
              <Info
                className="ml-2 text-gray-400 cursor-pointer"
                size={18}
                data-tooltip-id="holderStatsTooltip"
              />
            </h4>
            <ReactTooltip
              id="holderStatsTooltip"
              place="top"
              effect="solid"
              multiline={true}
            >
              Total holders fetched: {holderData.totalHolders}
              <br />
              Excluded wallets are not included in calculations.
            </ReactTooltip>
            <p className="text-lg text-white">Total Holders: {formatNumber(holderData.totalHolders)}</p>
            <p className="text-lg text-white">Average Balance: {formatNumber(holderData.averageBalance, 'ALV')}</p>
            <p className="text-lg text-white">Median Balance: {formatNumber(holderData.medianBalance, 'ALV')}</p>
          </GlowingCard>

          <GlowingCard>
            <h4 className="text-xl font-bold text-green-400 mb-2 flex items-center">
              <DollarSign className="mr-2" size={20} /> ALV Price and Supply
            </h4>
            <p className="text-lg text-white">Price: {formatNumber(holderData.alvPrice, 'USD')}</p>
            <p className="text-lg text-white">Total Supply: {formatNumber(holderData.totalSupply, 'ALV')}</p>
            <p className="text-lg text-white">Market Cap: {formatNumber(holderData.alvPrice * holderData.totalSupply, 'USD')}</p>
          </GlowingCard>

          <GlowingCard>
            <h4 className="text-xl font-bold text-purple-400 mb-2 flex items-center">
              <BarChart2 className="mr-2" size={20} /> Distribution Metrics
            </h4>
            <p className="text-lg text-white">Gini Coefficient: {holderData.giniCoefficient.toFixed(4)}</p>
            <p className="text-lg text-white">Top 10 Holders: {holderData.topHoldersPercentage.toFixed(2)}% of supply</p>
          </GlowingCard>
        </div>

        {/* Charts */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-6">
          {/* Holder Distribution Chart */}
          <GlowingCard>
            <h4 className="text-xl font-bold text-orange-500 mb-2 flex items-center">
              <BarChart2 className="mr-2" size={20} /> Holder Distribution
              <Info
                className="ml-2 text-gray-400 cursor-pointer"
                size={18}
                data-tooltip-id="holderDistributionTooltip"
              />
            </h4>
            <ReactTooltip
              id="holderDistributionTooltip"
              place="top"
              effect="solid"
              content="This chart shows the distribution of ALV tokens among different holder groups."
            />
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={holderData.distribution}>
                <XAxis dataKey="name" stroke="#ccc" />
                <YAxis stroke="#ccc" />
                <RechartsTooltip
                  formatter={(value) => `${formatNumber(value, 'ALV')} (${(value / holderData.totalSupply * 100).toFixed(2)}%)`}
                />
                <Bar dataKey="value" fill="#FF9800" />
              </BarChart>
            </ResponsiveContainer>
          </GlowingCard>

          {/* Lorenz Curve */}
          <GlowingCard>
            <h4 className="text-xl font-bold text-blue-400 mb-2 flex items-center">
              <BarChart2 className="mr-2" size={20} /> Lorenz Curve
              <Info
                className="ml-2 text-gray-400 cursor-pointer"
                size={18}
                data-tooltip-id="lorenzCurveTooltip"
              />
            </h4>
            <ReactTooltip
              id="lorenzCurveTooltip"
              place="top"
              effect="solid"
              content="The Lorenz Curve illustrates the degree of inequality in token distribution. A perfectly equal distribution would follow the diagonal line."
            />
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={holderData.lorenzData}>
                <CartesianGrid stroke="#ccc" />
                <XAxis dataKey="percentPopulation" tickFormatter={(value) => `${value.toFixed(0)}%`} stroke="#ccc" />
                <YAxis tickFormatter={(value) => `${value.toFixed(0)}%`} stroke="#ccc" />
                <RechartsTooltip
                  formatter={(value) => `${value.toFixed(2)}%`}
                  labelFormatter={(label) => `Population: ${label.toFixed(2)}%`}
                />
                <Line type="monotone" dataKey="percentWealth" stroke="#FF9800" strokeWidth={2} />
                <Line type="monotone" dataKey="percentPopulation" stroke="#ccc" strokeDasharray="5 5" />
              </LineChart>
            </ResponsiveContainer>
          </GlowingCard>

          {/* Balance Histogram */}
          <GlowingCard>
            <h4 className="text-xl font-bold text-green-400 mb-2 flex items-center">
              <BarChart2 className="mr-2" size={20} /> Holder Balance Histogram
              <Info
                className="ml-2 text-gray-400 cursor-pointer"
                size={18}
                data-tooltip-id="balanceHistogramTooltip"
              />
            </h4>
            <ReactTooltip
              id="balanceHistogramTooltip"
              place="top"
              effect="solid"
              content="This histogram shows the distribution of wallet balances, grouped into different balance ranges."
            />
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={holderData.histogramData}>
                <XAxis dataKey="name" stroke="#ccc" />
                <YAxis stroke="#ccc" />
                <RechartsTooltip />
                <Bar dataKey="count" fill="#4ECDC4" />
              </BarChart>
            </ResponsiveContainer>
          </GlowingCard>

          {/* Cumulative Top Holders */}
          <GlowingCard>
            <h4 className="text-xl font-bold text-purple-400 mb-2 flex items-center">
              <BarChart2 className="mr-2" size={20} /> Cumulative Top Holders
              <Info
                className="ml-2 text-gray-400 cursor-pointer"
                size={18}
                data-tooltip-id="cumulativeTopHoldersTooltip"
              />
            </h4>
            <ReactTooltip
              id="cumulativeTopHoldersTooltip"
              place="top"
              effect="solid"
              content="This chart shows the cumulative percentage of tokens held by top holders, ranked from largest to smallest."
            />
            <ResponsiveContainer width="100%" height={300}>
              <AreaChart data={holderData.cumulativeData}>
                <CartesianGrid stroke="#ccc" />
                <XAxis dataKey="holderRank" stroke="#ccc" />
                <YAxis tickFormatter={(value) => `${value.toFixed(2)}%`} stroke="#ccc" />
                <RechartsTooltip
                  formatter={(value) => `${value.toFixed(2)}%`}
                  labelFormatter={(label) => `Holder Rank: ${label}`}
                />
                <Area type="monotone" dataKey="cumulativePercentage" stroke="#FF9800" fill="#FF9800" />
              </AreaChart>
            </ResponsiveContainer>
          </GlowingCard>
        </div>

       {/* Balance vs. Transaction Count Scatter Plot */}
<GlowingCard className="mb-6">
  <h4 className="text-xl font-bold text-orange-500 mb-2 flex items-center">
    <BarChart2 className="mr-2" size={20} /> Balance vs. Transaction Count
    <Info
      className="ml-2 text-gray-400 cursor-pointer"
      size={18}
      data-tooltip-id="balanceVsTxCountTooltip"
    />
  </h4>
  <ReactTooltip
    id="balanceVsTxCountTooltip"
    place="top"
    effect="solid"
    multiline={true}
    content="This scatter plot shows the relationship between wallet balances and their transaction counts. Each point represents a wallet. Note that due to the wide range of values, some data points may overlap or be clustered."
  />
  <ResponsiveContainer width="100%" height={300}>
    <ScatterChart>
      <CartesianGrid stroke="#ccc" />
      <XAxis
        dataKey="balance"
        name="Balance"
        stroke="#ccc"
        tickFormatter={(value) => formatNumber(value, 'ALV')}
        type="number"
        domain={[0, 1000000]}
        ticks={[0, 25000, 50000, 75000, 100000, 125000, 250000, 500000, 1000000, 2000000, 5000000]}
      />
      <YAxis
        dataKey="txCount"
        name="Transaction Count"
        stroke="#ccc"
        type="number"
        domain={[0, 'dataMax + 100']}
        ticks={[0, 100, 200, 300, 400, 500, 750, 1000, 1500]}
      />
      <ZAxis range={[60]} />
      <RechartsTooltip
        formatter={(value, name) =>
          name === 'Balance'
            ? [formatNumber(value, 'ALV'), name]
            : [value, name]
        }
        labelFormatter={(label) => `Address: ${formatAddress(label)}`}
      />
      <Scatter data={holderData.balanceTxCountData} fill="#FF9800" />
    </ScatterChart>
  </ResponsiveContainer>
</GlowingCard>

        {/* Top Holders Table */}
        <GlowingCard>
          <h4 className="text-xl font-bold text-blue-400 mb-4 flex items-center">
            <Wallet className="mr-2" size={20} /> Top Holders
          </h4>
          <div className="overflow-x-auto">
            <div className="mb-2">
              <input
                value={globalFilter || ''}
                onChange={(e) => setGlobalFilter(e.target.value)}
                placeholder="Search address"
                className="p-2 border rounded text-black"
              />
            </div>
            <table {...getTableProps()} className="min-w-full">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        className="px-4 py-2 text-left cursor-pointer"
                        key={column.id}
                      >
                        {column.render('Header')}
                        <span>
                          {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className="border-t border-gray-700" key={row.id}>
                      {row.cells.map((cell) => (
                        <td
                          {...cell.getCellProps()}
                          className="px-4 py-2 text-left"
                          key={cell.column.id}
                        >
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* Pagination */}
            <div className="pagination mt-4 flex items-center justify-between">
              <div>
                <button
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                  className="p-2 border rounded mr-2 bg-gray-700 text-white"
                >
                  {'<<'}
                </button>
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  className="p-2 border rounded mr-2 bg-gray-700 text-white"
                >
                  {'<'}
                </button>
                <button
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  className="p-2 border rounded mr-2 bg-gray-700 text-white"
                >
                  {'>'}
                </button>
                <button
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                  className="p-2 border rounded bg-gray-700 text-white"
                >
                  {'>>'}
                </button>
              </div>
              <div className="text-white">
                Page{' '}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
              </div>
              <div>
                <select
                  value={pageSize}
                  onChange={(e) => setPageSize(Number(e.target.value))}
                  className="p-2 border rounded ml-2 bg-gray-700 text-white"
                >
                  {[10, 20, 30, 40, 50].map((size) => (
                    <option key={size} value={size}>
                      Show {size}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </GlowingCard>

        {/* Refresh Data Button */}
        <div className="mt-6 flex justify-end">
          <button
            onClick={() => fetchHolderData(true)}
            className="flex items-center px-4 py-2 bg-orange-500 hover:bg-orange-600 text-white rounded transition-colors duration-200"
          >
            <RefreshCw className="mr-2" size={18} />
            Refresh Data
          </button>
        </div>

        {/* Additional Information */}
        <div className="mt-6 text-gray-400 text-sm">
          <p>* Holder data is cached for 8 hours to reduce API calls.</p>
          <p>* Excluded wallets are not included in distribution calculations.</p>
          <p>* Last updated: {new Date().toLocaleString()}</p>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default AlveyHolders;
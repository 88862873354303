import React from 'react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { User, TrendingUp, Crown, Feather, Bird } from 'lucide-react';
import { useBalance, useAccount } from 'wagmi';
import { config } from '../config';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { useUserRole } from '../hooks/useUserRole';
import { formatNumberCompact } from '../utils/numberUtils';

const CustomConnectButton = () => {
  const { address } = useAccount();
  const { data: balance } = useBalance({ address });
  const { userRole } = useUserRole();

  const getRoleIcon = (role) => {
    switch (role) {
      case 'Flock': return <Bird className="text-white" size={24} />;
      case 'Soar': return <TrendingUp className="text-white" size={24} />;
      case 'CORE': return <Crown className="text-white" size={24} />;
      case 'Acolyte': return <Feather className="text-white" size={24} />;
      default: return <User className="text-white" size={24} />;
    }
  };

  const getRoleGradient = (role) => {
    switch (role) {
      case 'Flock': return 'bg-gradient-to-r from-blue-400 to-blue-600';
      case 'Soar': return 'bg-gradient-to-r from-green-400 to-green-600';
      case 'CORE': return 'bg-gradient-to-r from-yellow-400 to-yellow-600';
      case 'Acolyte': return 'bg-gradient-to-r from-purple-400 to-purple-600';
      default: return 'bg-gradient-to-r from-gray-400 to-gray-600';
    }
  };

  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        const ready = mounted && authenticationStatus !== 'loading';
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === 'authenticated');

        return (
          <div
            {...(!ready && {
              'aria-hidden': true,
              'style': {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <button onClick={openConnectModal} type="button" className="bg-orange-500 w-full hover:bg-orange-600 text-white font-bold py-2 px-4 rounded">
                    Connect
                  </button>
                );
              }

              if (chain.unsupported) {
                return (
                  <button onClick={openChainModal} type="button" className="bg-red-500 w-full hover:bg-red-600 text-white font-bold py-2 px-4 rounded">
                    Wrong network
                  </button>
                );
              }

              return (
                <div className="flex items-center space-x-2 w-full">
                  <button
                    onClick={openChainModal}
                    className="bg-orange-500 hover:bg-orange-600 text-white font-medium p-2 rounded flex items-center justify-center"
                    type="button"
                    data-tooltip-id="chain-tooltip"
                    data-tooltip-content={`Connected to ${chain.name}`}
                  >
                    {chain.hasIcon && (
                      <div>
                        {chain.iconUrl ? (
                          <img
                            alt={chain.name ?? 'Chain icon'}
                            src={chain.iconUrl}
                            className="w-6 h-6"
                          />
                        ) : chain.id === 3797 ? (
                          <img
                            alt="Alvey Chain"
                            src="/alvey-logo.png"
                            className="w-6 h-6"
                          />
                        ) : null}
                      </div>
                    )}
                  </button>

                  <button 
                    onClick={openAccountModal} 
                    type="button" 
                    className="bg-orange-500 flex-grow hover:bg-orange-600 text-white font-medium py-2 px-4 rounded flex items-center justify-center"
                    data-tooltip-id="balance-tooltip"
                    data-tooltip-content={`Connected: ${account.address}`}
                  >
                    <span className="font-bold">
                      {balance 
                        ? `${formatNumberCompact(parseFloat(balance.formatted))} ${balance.symbol}`
                        : 'Loading...'}
                    </span>
                  </button>

                  <Link
                    to="/profile"
                    className={`${getRoleGradient(userRole)} hover:opacity-90 text-white font-medium p-2 rounded-full flex items-center justify-center`}
                    data-tooltip-id="profile-tooltip"
                    data-tooltip-content={`Open your ${userRole} Profile`}
                  >
                    {getRoleIcon(userRole)}
                  </Link>
                </div>
              );
            })()}

            <Tooltip id="profile-tooltip" />
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};

export default CustomConnectButton;
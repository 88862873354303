import { useState, useEffect } from 'react';
import { useAccount } from 'wagmi';
import { createPublicClient, http, formatUnits } from 'viem';
import { bscChain, alveyChain } from '../utils/wagmi';
import { config } from '../config';
import { ICARUS_ABI } from '../abi';
import { ACOLYTE_NFT_ABI } from '../utils/acolyteNftAbi';

const FLOCK_THRESHOLD = 1000000000n;
const SOAR_THRESHOLD = 5000000000n;
const CORE_THRESHOLD = 9500000000n;
const DECIMALS = 10n ** 18n; // 10^18 for 18 decimal places

const bscClient = createPublicClient({
  chain: bscChain,
  transport: http(bscChain.rpcUrls.default.http[0])
});

const alveyClient = createPublicClient({
  chain: alveyChain,
  transport: http(alveyChain.rpcUrls.default.http[0])
});

const fetchContractData = async (client, address, contractAddress, abi, functionName) => {
  try {
    const result = await client.readContract({
      address: contractAddress,
      abi,
      functionName,
      args: [address],
    });
    console.log(`${functionName} result for ${contractAddress}:`, result);
    return result;
  } catch (error) {
    console.error(`Error fetching ${functionName} for ${contractAddress}:`, error);
    return 0n; // Return 0 as BigInt in case of error
  }
};

const fetchChainData = async (client, address, icaAddress, acolyteAddress) => {
  const [icaBalance, acolyteBalance] = await Promise.all([
    fetchContractData(client, address, icaAddress, ICARUS_ABI, 'balanceOf'),
    fetchContractData(client, address, acolyteAddress, ACOLYTE_NFT_ABI, 'balanceOf')
  ]);
  return { icaBalance, acolyteBalance };
};

const determineRole = (amount) => {
  const adjustedAmount = amount / DECIMALS; // Adjust balance by dividing by 10^18
  console.log('Determining role for adjusted amount:', adjustedAmount.toString());

  if (adjustedAmount >= CORE_THRESHOLD) return 'CORE';
  if (adjustedAmount >= SOAR_THRESHOLD) return 'Soar';
  if (adjustedAmount >= FLOCK_THRESHOLD) return 'Flock';

  return 'User';
};

const getHighestRole = (role1, role2) => {
  const roleOrder = ['User', 'Flock', 'Soar', 'CORE'];
  return roleOrder.indexOf(role1) > roleOrder.indexOf(role2) ? role1 : role2;
};

export const useUserRole = () => {
  const { address, isConnected } = useAccount();
  const [userRole, setUserRole] = useState('User');
  const [bscRole, setBscRole] = useState('User');
  const [alveyRole, setAlveyRole] = useState('User');
  const [isLoading, setIsLoading] = useState(true);
  const [bscBalance, setBscBalance] = useState(0n);
  const [alveyBalance, setAlveyBalance] = useState(0n);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!isConnected || !address) {
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      setError(null);

      try {
        console.log('Fetching data for address:', address);
        const [bscData, alveyData] = await Promise.all([
          fetchChainData(bscClient, address, config.contractAddress, config.acolyteNftAddress),
          fetchChainData(alveyClient, address, config.alveyContractAddress, config.alveyAcolyteNftAddress)
        ]);

        console.log('BSC Data:', bscData);
        console.log('Alvey Data:', alveyData);

        setBscBalance(bscData.icaBalance);
        setAlveyBalance(alveyData.icaBalance);

        const bscRoleResult = determineRole(bscData.icaBalance);
        const alveyRoleResult = determineRole(alveyData.icaBalance);
        
        console.log('BSC Role:', bscRoleResult);
        console.log('Alvey Role:', alveyRoleResult);

        setBscRole(bscRoleResult);
        setAlveyRole(alveyRoleResult);

        const hasAcolyteNFT = (bscData.acolyteBalance > 0n) || (alveyData.acolyteBalance > 0n);

        if (hasAcolyteNFT) {
          setUserRole('Acolyte');
        } else {
          const highestRole = getHighestRole(bscRoleResult, alveyRoleResult);
          console.log('Highest Role:', highestRole);
          setUserRole(highestRole);
        }
      } catch (err) {
        console.error('Error fetching user role data:', err);
        setError('Failed to fetch user data. Please try again.');
        setUserRole('User');
        setBscRole('User');
        setAlveyRole('User');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [isConnected, address]);

  console.log('Final User Role:', userRole);
  console.log('Final BSC Role:', bscRole);
  console.log('Final Alvey Role:', alveyRole);

  return {
    userRole,
    bscRole,
    alveyRole,
    isLoading,
    error,
    bscBalance: formatUnits(bscBalance, 18),
    alveyBalance: formatUnits(alveyBalance, 18)
  };
};

export const config = {
  projectId: 'b8a8616c2a7d5fc1d235282ca59b0c6f',
  alchemyApiKey: 'n1LUO3Buti78CtZodw91NmWoY6gNZAg1',
  quarkApikey: '9D11959bFa3A837f0a7A8C0Afb0F26EA7da92C2256190E90B441F8a201970C83',
  contractAddress: '0xf012f402747e1e4715b7e496650243a29be204c7', // BSC contract
  alveyContractAddress: '0xf4EA0B25C393DD7e92189899F4D6665CA9eADdb7', // Alvey contract
  bscRpcUrl: 'https://bsc-dataseed.binance.org/',
  moralisApiKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjBlMTI5MjY1LWM4YWMtNDBhOS04ZDkxLTA1NzE0MGRmYjY5NyIsIm9yZ0lkIjoiNDAyMjU3IiwidXNlcklkIjoiNDEzMzUyIiwidHlwZUlkIjoiYTQxMGYxYzctNmE1ZC00YjIxLWIzZmEtZDIxMGM4ZWUzNjc3IiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MjI1MTk3NTIsImV4cCI6NDg3ODI3OTc1Mn0.B1QmO-cqHIP46lSeIsj6tka_XdZVvp6OdGbPGLUbQNY',
  chainId: 'bsc',
  version: 'BETA v0.13',
  hexChain: '0x38', // Hexadecimal representation of 56 for BSC
  bscHex: '0x38',
  ethHex: 	'0x1', // eth hex value 1 
  decChain: 56, // Decimal representation for other APIs that might need it
  geckoTerminalNetwork: 'bsc',
  alveychainDec: '3797', //decimal alveychain 
  alveychainHex: '0xED5', // Hexadecimal representation of Alvey Chain ID
  bscChainId: '56', // or the correct ID for BSC
  alveyChainId: '3797', // or the correct ID for Alvey

  alveyPoolPairAddress: '0x5f448c703610b8a908cbec092c1ed6cec5a94f79',
  alveyStakingWallet: '0x6c77f8B49C79E131B860F30bC7803BE58c12E308',
  alveyLockWallet: '0x09E7FD24951538220b721b1b5a7452B6A4d927bE',
  alveyTeamWallets: {
  marketing: '0xAa5ABF02Cf3031E1E8AB0262ed0FCE35337d5b15',
  community: '0x2cf3724B3b0D2C48e0de7BdD115D384C280c3c8C',
  reserve: '0xAf7b59Ce94A7625c7f04624565edE0E64EEbF71e',
  liquidity: '0x09E7FD24951538220b721b1b5a7452B6A4d927bE',
  team: '0xf1701a7DDbF47265f75a4E656f9Ecb7Ec994D88e'
    // ... add other team wallets as needed
  },
  
  // DexGuru API configuration
  dexGuruApiKey: 'axYF8WW21Al6zFxwN1tGjkhvAPihTr6vuXUNU6cCE7w',
  dexGuruApiUrl: 'https://api.dev.dex.guru',
  dexGuruNetwork: '56',

  // GeckoTerminal API configuration
  geckoTerminalApiUrl: 'https://api.geckoterminal.com/api/v2',
  
  // CoinGecko API configuration
  coingeckoApiUrl: 'https://api.coingecko.com/api/v3',
  coingeckoTokenId: 'icarus',
  
  // ICA pool pair address on PancakeSwap
  icaPoolPairAddress: '0xf9c399975a332d911c6f90201fcab4569b72c98e',
  
  // API cache duration in milliseconds (15 minutes)
  apiCacheDuration: 15 * 60 * 1000,

  // BSCScan API configuration
  bscscanApiKey: 'AMMEA3QKF5IG8PWZGR9V3GFQAIKN4AB3UB',
  bscscanApiUrl: 'https://api.bscscan.com/api',
  
  stakingWallets: [
    '0x01864EC020105E6d5E8987A34e84Dba747F77eBA',
    // Add more staking wallets here as needed
  ],

  teamWallets: {
    marketing: '0xAa5ABF02Cf3031E1E8AB0262ed0FCE35337d5b15',
    community: '0x2cf3724B3b0D2C48e0de7BdD115D384C280c3c8C',
    reserve: '0xAf7b59Ce94A7625c7f04624565edE0E64EEbF71e',
    liquidity: '0x09E7FD24951538220b721b1b5a7452B6A4d927bE',
    team: '0xf1701a7DDbF47265f75a4E656f9Ecb7Ec994D88e'
  },

  // Locked wallet address
  lockedWallet: '0x10B5F02956d242aB770605D59B7D27E51E45774C',

  // Acolyte NFT contract address
  acolyteNftAddress: '0xd8b5daad30c2d46f60857b2c7cbb004661ea5db2', //BSC
  alveyAcolyteNftAddress: '0x02c49ae57810cdad54a76af82950fe31ca333e46', // alvey

  // IPFS Gateway
  ipfsGateway: 'https://ipfs.io/ipfs/',
  
   treasuryWallet: '0xAa5ABF02Cf3031E1E8AB0262ed0FCE35337d5b15', // Add the treasury wallet address here
   airdropContractAddress: '0x13B4429E44564531f20707716aF72a3A467E55Dc', // airdrop contract adress 
   
     // New configuration for raiding feature
  apiBaseUrl: 'https://api.icarus.com', // Replace with your actual API base URL
  telegramBotToken: 'YOUR_TELEGRAM_BOT_TOKEN', // Replace with your Telegram bot token
  telegramChannel : 'icarus_community', // replace with telegram channel
  
  //Zealy API 
    zealyApiKey: 'f4f02fNJOA8RH1Q5h1A9RcwRZK_',
  zealyApiUrl: 'https://api.zealy.io/communities/icaruscommunity',
  
   // Admin wallets
  adminWallets: ['0x91CAC128C0cfC76A2992cc0373e60b3841DE736F'],
};
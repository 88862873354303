import { useEffect, useState } from 'react';

export const useNotifications = () => {
  const [permissionGranted, setPermissionGranted] = useState(false);

  useEffect(() => {
    requestNotificationPermission();
  }, []);

  const requestNotificationPermission = async () => {
    if ('Notification' in window) {
      const permission = await Notification.requestPermission();
      setPermissionGranted(permission === 'granted');
    }
  };

  const triggerNotification = (title, options) => {
    if ('Notification' in window && permissionGranted) {
      new Notification(title, options);
    }
  };

  return { triggerNotification, permissionGranted };
};
import React, { useState, useEffect, lazy, Suspense } from 'react';
import { useAccount } from 'wagmi';
import { Gem, Info, AlertTriangle, Coins, Sparkles, Crown, Gift, Star, Lock, Zap, Trophy, Image, MessageCircle, Users, Book, Palette, DollarSign, Rocket, Cog } from 'lucide-react';
import { motion } from 'framer-motion';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import { formatNumber } from '../utils/numberUtils';
import ErrorBoundary from './ErrorBoundary';
import LoadingAnimation from './LoadingAnimation';

const LazyVideoPlayer = lazy(() => import('./LazyVideoPlayer'));

const GlowingCard = ({ children, glowColor = 'rgba(255, 152, 0, 0.5)', className = '' }) => (
  <motion.div
    className={`relative bg-gray-900 rounded-xl overflow-hidden ${className}`}
    whileHover={{ scale: 1.02 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <div className="absolute inset-0 bg-gradient-to-r from-transparent via-gray-800 to-transparent opacity-50" />
    <div className="relative z-10 p-6">
      {children}
    </div>
    <div
      className="absolute inset-0 opacity-0 hover:opacity-100 transition-opacity duration-300"
      style={{
        boxShadow: `0 0 20px ${glowColor}`,
        filter: 'blur(15px)',
        zIndex: -1,
      }}
    />
  </motion.div>
);

const NFTMint = () => {
  const { address } = useAccount();
  const [nftData, setNftData] = useState({
    totalSupply: 25,
    minted: 8,
    price: 1.83, // BNB
    reflections: {
      total: 1.2,
      lastMonth: 0.3,
    },
    icarusReflections: {
      total: 5.567,
      lastMonth: 1.2,
    }
  });

  const handleMint = () => {
    console.log(`Minting 1 NFT`);
  };

  const BenefitCard = ({ icon: Icon, title, description }) => (
    <GlowingCard className="flex items-start">
      <Icon className="text-orange-500 mr-3 flex-shrink-0" size={24} />
      <div>
        <h4 className="text-lg font-semibold text-white mb-1">{title}</h4>
        <p className="text-sm text-gray-300">{description}</p>
      </div>
    </GlowingCard>
  );

  const MintingCard = () => (
    <GlowingCard className="mb-6">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-2xl font-bold text-orange-500 flex items-center">
          <Gem className="mr-2" size={28} />
          Mint Your ICARUS ACOLYTE NFT
        </h3>
        <div className="bg-gradient-to-r from-yellow-200 to-orange-600 text-white px-4 py-2 rounded-full flex items-center">
          <Crown className="mr-2" size={24} />
          <span className="font-bold text-lg">CORE MEMBERS</span>
        </div>
      </div>
      <div className="mb-4">
        <div className="flex justify-between text-white mb-2">
          <span>Minted:</span>
          <span>{nftData.minted} / {nftData.totalSupply}</span>
        </div>
        <div className="w-full bg-gray-700 rounded-full h-2.5">
          <div className="bg-orange-500 h-2.5 rounded-full" style={{ width: `${(nftData.minted / nftData.totalSupply) * 100}%` }}></div>
        </div>
      </div>
      <div className="bg-gradient-to-r from-blue-600 to-purple-600 p-4 rounded-lg mb-4">
        <div className="flex items-center justify-between">
          <span className="text-white font-bold">Price:</span>
          <div className="flex items-center">
            <Coins className="text-yellow-300 mr-2" size={24} />
            <span className="text-3xl font-bold text-white">{nftData.price} BNB</span>
          </div>
        </div>
      </div>
      <button
        onClick={handleMint}
        className="w-full bg-gradient-to-r from-orange-500 to-yellow-500 hover:from-orange-600 hover:to-yellow-600 text-white font-bold py-3 px-4 rounded-lg text-lg transition duration-300 ease-in-out transform hover:scale-105"
        disabled={!address}
      >
        MINT 1 NFT
      </button>
      {!address && (
        <p className="text-yellow-500 flex items-center mt-2">
          <AlertTriangle className="mr-2" size={16} />
          Please connect your wallet to mint
        </p>
      )}
      <p className="text-white flex items-center mt-2">
        <Info className="mr-2" size={16} />
        Only 1 NFT can be minted per wallet
      </p>
    </GlowingCard>
  );

  const ReflectionsCard = () => (
    <GlowingCard className="mb-6">
      <h3 className="text-2xl font-bold text-orange-500 mb-4 flex items-center">
        <DollarSign className="mr-2" size={28} />
        Reflections & Dividends
      </h3>
      <div className="space-y-6">
        <GlowingCard className="bg-gradient-to-r from-orange-500 to-yellow-500">
          <h4 className="text-lg font-semibold text-white mb-2">NFT Sales Reflections</h4>
          <div className="flex items-end">
            <Trophy className="text-white mr-2" size={24} />
            <span className="text-3xl font-bold text-white">{nftData.reflections.total} BNB</span>
          </div>
          <p className="text-sm text-white mt-2">Last month: {nftData.reflections.lastMonth} BNB</p>
        </GlowingCard>

        <GlowingCard className="bg-gradient-to-r from-blue-500 to-purple-500">
          <h4 className="text-lg font-semibold text-white mb-2">ICARUS Token Reflections</h4>
          <div className="flex items-end">
            <Sparkles className="text-white mr-2" size={24} />
            <span className="text-3xl font-bold text-white">{nftData.icarusReflections.total} BNB</span>
          </div>
          <p className="text-sm text-white mt-2">Last month: {nftData.icarusReflections.lastMonth} BNB</p>
        </GlowingCard>
      </div>
    </GlowingCard>
  );

  const distributionData = [
    { name: 'Acolyte Holders', value: 10, color: '#FF6B6B' },
    { name: 'Buybacks', value: 20, color: '#4ECDC4' },
    { name: 'Development', value: 20, color: '#45B7D1' },
    { name: 'Treasury', value: 20, color: '#FFA07A' },
    { name: 'Marketing', value: 30, color: '#98D8C8' },
  ];

  const DistributionChart = () => (
    <GlowingCard className="mb-6">
      <h3 className="text-2xl font-bold text-orange-500 mb-4 flex items-center">
        <Cog className="mr-2" size={28} />
        NFT Sale Profit Distribution
      </h3>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={distributionData}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={80}
            fill="#8884d8"
            label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
          >
            {distributionData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <Tooltip
            formatter={(value, name) => [`${value}%`, name]}
            contentStyle={{ backgroundColor: '#374151', border: 'none' }}
            itemStyle={{ color: '#ffffff' }}
          />
          <Legend verticalAlign="bottom" height={36} />
        </PieChart>
      </ResponsiveContainer>
    </GlowingCard>
  );

  const MintedAcolytesCard = () => (
    <GlowingCard className="mt-6">
      <h3 className="text-2xl font-bold text-orange-500 mb-4 flex items-center">
        <Users className="mr-2" size={28} />
        Minted Acolytes
      </h3>
      <div className="flex gap-4">
        <Suspense fallback={<LoadingAnimation />}>
          <LazyVideoPlayer src="/Acolyte-test1.mp4" poster="/Acolyte-test1-poster.jpg" maxHeight="360px" />
          <LazyVideoPlayer src="/Acolyte-test2.mp4" poster="/Acolyte-test2-poster.jpg" maxHeight="360px" />
        </Suspense>
      </div>
    </GlowingCard>
  );

  return (
    <ErrorBoundary>
      <div className="w-full bg-gray-900 p-6">   
        <div className="flex flex-col lg:flex-row gap-6">
          <div className="lg:w-4/12 lg:order-2">
            <div className="lg:sticky lg:top-6">
              <MintingCard />
              <ReflectionsCard />
              <DistributionChart />
              <GlowingCard className="mb-6">
                <h3 className="text-2xl font-bold text-orange-500 mb-4 flex items-center">
                  <Rocket className="mr-2" size={28} />
                  Collection Promo
                </h3>
                <Suspense fallback={<LoadingAnimation />}>
                  <LazyVideoPlayer src="/ATHENA-PROMO.mp4" poster="/ATHENA-PROMO-poster.jpg" />
                </Suspense>
              </GlowingCard>
            </div>
          </div>
          
          <div className="lg:w-8/12 lg:order-1">
            <GlowingCard className="mb-6">
              <img src="/acolytes.png" alt="Acolytes" className="w-full h-auto rounded-lg shadow-lg" />
            </GlowingCard>
            <h1 className="text-3xl font-bold text-orange-500 mb-6 flex items-center">
              <Gem className="mr-2" size={32} /> ICARUS ACOLYTE NFT Collection
            </h1>
            <GlowingCard className="mb-6">
              <p className="text-white mb-6">
                Embark on a journey of mythical proportions with the ICARUS ACOLYTES COLLECTION, an elite series of NFTs. Only 25 will be available. Each NFT is a digital tapestry woven with the unique essence and backstory of an Acolyte, integral to the Icarus narrative. These are not mere tokens of art; they are keys to a kingdom of perpetual rewards, granting their bearers passive income through the alchemy of token reflections. As the saga of Icarus unfolds, these NFTs will unlock further treasures: airdrops, governance through the Icarus DAO, and an invitation to the inner sanctum of the community. Holders become champions of the Icarus cause, sharing in the glory and gains of its ascendancy.
              </p>
              <h3 className="text-2xl font-bold text-orange-500 mb-4 flex items-center">
                <Star className="mr-2" size={28} />
                ICARUS ACOLYTE NFT Benefits
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <BenefitCard
                  icon={Gift}
                  title="Reflections"
                  description="Receive 10% from BNB ICARUS Token TAX for life!"
                />
                <BenefitCard
                  icon={Star}
                  title="Limited Edition"
                  description="Only 25 NFTs exist, with 5 special editions"
                />
                <BenefitCard
                  icon={Zap}
                  title="High-Quality Art"
                  description="High-resolution H.264 MP4 video NFTs"
                />
                <BenefitCard
                  icon={Lock}
                  title="Exclusive Access"
                  description="Whitelist privileges and early access to launches"
                />
                <BenefitCard
                  icon={Coins}
                  title="Community Empowerment"
                  description="Stake in the project with profit sharing"
                />
                <BenefitCard
                  icon={Trophy}
                  title="Event Participation"
                  description="Qualify as max wallets in ICARUS events"
                />
                <BenefitCard
                  icon={Image}
                  title="Exclusive Content"
                  description="Gain an Avatar and printable high-resolution images"
                />
                <BenefitCard
                  icon={MessageCircle}
                  title="Acolyte Status"
                  description="Extra influence in our Telegram community"
                />
                <BenefitCard
                  icon={Gift}
                  title="Mint Reflections"
                  description="Gain 10% reflected from each Acolyte mint"
                />
                <BenefitCard
                  icon={Crown}
                  title="CORE Membership"
                  description="All Acolytes are CORE members"
                />
                <BenefitCard
                  icon={Book}
                  title="Unique Lore"
                  description="Each Acolyte has its own backstory, D&D stats, traits, weapon, and profession"
                />
                <BenefitCard
                  icon={Palette}
                  title="Personally Crafted"
                  description="Each Acolyte is unique and personally crafted by our artist"
                />
              </div>
            </GlowingCard>
            <MintedAcolytesCard />
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default NFTMint;
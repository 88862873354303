import React from 'react';
import { Coins } from 'lucide-react';
import { useAccount, useNetwork, useBalance } from 'wagmi';
import StakingCard from './StakingCard';
import GemPadStaking from './GemPadStaking';
import { config } from '../config'; // Assuming you have a config file with contract addresses

const Staking = () => {
  const { address, isConnected } = useAccount();
  const { chain } = useNetwork();
  const { data: balance } = useBalance({
    address,
    token: config.contractAddress, // Your staking token address
  });

  if (!isConnected) {
    return (
      <div className="w-full text-center py-8">
        <Coins className="mx-auto mb-4" size={48} />
        <p className="text-lg font-semibold">Please connect your wallet to view staking options.</p>
      </div>
    );
  }

  return (
    <div className="w-full">
      <h1 className="text-3xl font-bold text-orange-500 mb-6 flex items-center">
        <Coins className="mr-2" size={32} /> Staking Dashboard
      </h1>
      {balance && (
        <div className="mb-6 p-4 bg-gray-800 rounded-lg">
          <p className="text-lg">Your Balance: {balance.formatted} {balance.symbol}</p>
        </div>
      )}
      <div className="space-y-8">
        <StakingCard />
        <GemPadStaking />
      </div>
    </div>
  );
};

export default Staking;
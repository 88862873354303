import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { User, Coins, Wallet, Gift, CreditCard, Clock, TrendingUp, DollarSign, Feather, Crown, ExternalLink, Bird } from 'lucide-react';
import { useAccount, useContractRead, useContractWrite, usePrepareContractWrite, useNetwork, useSwitchNetwork } from 'wagmi';
import { formatEther, parseEther } from 'viem';
import { config } from '../config';
import { ICARUS_ABI } from '../abi';
import { ALVEY_ABI } from '../utils/alveyAbi';
import LoadingAnimation from './LoadingAnimation';
import { formatNumber } from '../utils/numberUtils';
import ErrorBoundary from '../components/ErrorBoundary';
import QuarkUtils from '../utils/QuarkUtils';
import { useUserRole } from '../hooks/useUserRole';

const ROLE_THRESHOLDS = {
  User: 0,
  Flock: 1000000000,
  Soar: 5000000000,
  CORE: 9500000000,
};

const GlowingCard = ({ children, glowColor = 'rgba(255, 152, 0, 0.5)', className = '' }) => (
  <motion.div
    className={`relative bg-gray-900 rounded-xl overflow-hidden ${className}`}
    whileHover={{ scale: 1.02 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <div className="absolute inset-0 bg-gradient-to-r from-transparent via-gray-800 to-transparent opacity-50" />
    <div className="relative z-10 p-6">
      {children}
    </div>
    <div
      className="absolute inset-0 opacity-0 hover:opacity-100 transition-opacity duration-300"
      style={{
        boxShadow: `0 0 20px ${glowColor}`,
        filter: 'blur(15px)',
        zIndex: -1,
      }}
    />
  </motion.div>
);

const InfoCard = ({ title, value, subValue, icon: Icon, accentColor, className = '' }) => (
  <GlowingCard glowColor={accentColor} className={className}>
    <div className="flex items-center justify-between mb-4">
      <h3 className="text-lg font-bold text-gray-300">{title}</h3>
      <Icon className={`text-${accentColor}`} size={24} />
    </div>
    <p className={`text-3xl font-bold text-${accentColor}`}>{value}</p>
    {subValue && <p className="text-sm text-gray-400 mt-1">{subValue}</p>}
  </GlowingCard>
);

const RoleCard = ({ role, bscRole, alveyRole, acolyteRole, gradient }) => {
  const getRoleIcon = (role) => {
    switch (role) {
      case 'CORE': return <Crown size={48} />;
      case 'Soar': return <TrendingUp size={48} />;
      case 'Flock': return <Bird size={48} />;
      case 'Acolyte': return <Feather size={48} />;
      default: return <User size={48} />;
    }
  };

  return (
    <GlowingCard className={`${gradient} text-white`}>
      <div className="flex items-center justify-between">
        <div>
          <h3 className="text-2xl font-bold mb-2">Your Role</h3>
          <p className="text-4xl font-bold">{role}</p>
          <div className="mt-2 text-sm">
            <p>BSC: {bscRole}</p>
            <p>Alvey: {alveyRole}</p>
            {acolyteRole && <p>Acolyte: {acolyteRole}</p>}
          </div>
        </div>
        {getRoleIcon(role)}
      </div>
    </GlowingCard>
  );
};

const TierProgressCard = ({ balance, chainName, gradient, price, totalSupply }) => {
  const balanceValue = parseFloat(balance);
  const tiers = Object.entries(ROLE_THRESHOLDS);
  const currentTierIndex = tiers.findIndex(([tier, threshold], index) => 
    balanceValue >= threshold && (index === tiers.length - 1 || balanceValue < tiers[index + 1][1])
  );
  const currentTier = tiers[currentTierIndex][0];
  const nextTier = tiers[currentTierIndex + 1];
  
  const currentTierThreshold = ROLE_THRESHOLDS[currentTier];
  const nextTierThreshold = nextTier ? nextTier[1] : ROLE_THRESHOLDS.CORE;
  
  const progressInCurrentTier = ((balanceValue - currentTierThreshold) / (nextTierThreshold - currentTierThreshold)) * 100;
  
  const getRoleIcon = (role) => {
    switch (role) {
      case 'CORE': return <Crown size={24} />;
      case 'Soar': return <TrendingUp size={24} />;
      case 'Flock': return <Bird size={24} />;
      default: return <User size={24} />;
    }
  };

  const costToNextTier = nextTier ? (nextTier[1] - balanceValue) * price : 0;

  return (
    <GlowingCard>
      <h3 className="text-xl font-bold mb-4 text-gray-300">{chainName} Tier Progress</h3>
      <div className="flex justify-between items-center mb-2">
        {getRoleIcon(currentTier)}
        <div className="w-full bg-gray-700 rounded-full h-3 mx-4">
          <div 
            className={`h-full ${gradient} rounded-full relative`} 
            style={{ width: `${Math.min(progressInCurrentTier, 100)}%` }}
          >
            <span className="absolute top-4 right-0 text-xs text-white">
              {progressInCurrentTier.toFixed(2)}%
            </span>
          </div>
        </div>
        {getRoleIcon(nextTier ? nextTier[0] : 'CORE')}
      </div>
      <p className="text-sm text-gray-400 mb-4">
        {formatNumber(balanceValue, 'ICA')} / {formatNumber(nextTierThreshold, 'ICA')} ICA
      </p>
      {nextTier ? (
        <>
          <p className="text-sm text-gray-400 mb-2">
            Current Tier: {currentTier} | Next Tier: {nextTier[0]}
          </p>
          <p className="text-sm text-gray-400 mb-2">
            To reach {nextTier[0]}, you need {formatNumber(nextTierThreshold - balanceValue, 'ICA')} more ICA
          </p>
          <p className="text-sm text-gray-400 mb-4">
            Estimated cost to next tier: {formatNumber(costToNextTier, 'USD')}
          </p>
          <div className="flex justify-end">
            <a
              href={chainName === 'BSC' ? 
                `https://pancakeswap.finance/swap?outputCurrency=${config.contractAddress}` : 
                `https://elvesdex.com/swap?outputCurrency=${config.alveyContractAddress}`}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-gradient-to-r from-blue-500 to-purple-500  hover:from-blue-600 hover:to-purple-600 text-white font-bold py-2 px-4 rounded-lg inline-flex items-center transition-colors duration-200"
            >
              Buy ICA on {chainName} <ExternalLink size={16} className="ml-2" />
            </a>
          </div>
        </>
      ) : (
        <p className="text-sm text-green-400 font-bold">
          Congratulations! You've achieved CORE status, the highest tier in our community. 
          You are part of the select few who hold 1% of total {chainName} supply.
        </p>
      )}
    </GlowingCard>
  );
};

const ChainCard = ({ chainName, logo, balance, distributed, pending, totalDistributed, price, gradient, chainId, contractAddress }) => {
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();

  const { config: claimConfig } = usePrepareContractWrite({
    address: contractAddress,
    abi: chainName === 'BSC' ? ICARUS_ABI : ALVEY_ABI,
    functionName: 'claimDividend',
    chainId: chainId,
  });

  const { write: claimRewards, isLoading: isClaimLoading } = useContractWrite(claimConfig);

  const handleClaimRewards = () => {
    if (chain?.id !== chainId) {
      switchNetwork?.(chainId);
    } else {
      claimRewards?.();
    }
  };

  const balanceValue = parseFloat(balance);

  return (
    <GlowingCard>
      <div className="flex items-center mb-6">
        <img src={logo} alt={`${chainName} Logo`} className="w-8 h-8 mr-3" />
        <h3 className="text-2xl font-bold text-gray-300">{chainName} Chain</h3>
      </div>
      <div className="grid grid-cols-2 gap-4 mb-6">
        <InfoCard 
          title="ICA Balance"
          value={formatNumber(balanceValue, 'ICA')} 
          subValue={`≈ ${formatNumber(balanceValue * price, 'USD')}`}
          icon={CreditCard}
          accentColor="blue-400"
        />
        <InfoCard 
          title={`Distributed ${chainName === 'BSC' ? 'BNB' : 'ALV'}`}
          value={formatNumber(distributed ? formatEther(distributed) : '0', chainName === 'BSC' ? 'BNB' : 'ALV')} 
          icon={Gift}
          accentColor="green-400"
        />
        <InfoCard 
          title={`Pending ${chainName === 'BSC' ? 'BNB' : 'ALV'}`}
          value={formatNumber(pending ? formatEther(pending) : '0', chainName === 'BSC' ? 'BNB' : 'ALV')} 
          icon={Clock}
          accentColor="yellow-400"
        />
        <InfoCard 
          title={`Total Distributed ${chainName === 'BSC' ? 'BNB' : 'ALV'}`}
          value={formatNumber(totalDistributed ? formatEther(totalDistributed) : '0', chainName === 'BSC' ? 'BNB' : 'ALV')} 
          icon={Gift}
          accentColor="purple-400"
        />
      </div>
      <div className="mt-4 bg-gray-800 p-4 rounded-lg flex justify-between items-center">
        <div>
          <p className="text-gray-300 mb-2">ICA Price: <span className="font-bold text-orange-400">{formatNumber(price, 'ICAPrice')}</span></p>
          <p className="text-gray-300">Holdings Value: <span className="font-bold text-orange-400">{formatNumber(balanceValue * price, 'USD')}</span></p>
        </div>
        <button
          onClick={handleClaimRewards}
          disabled={isClaimLoading || !pending || parseFloat(formatEther(pending)) === 0}
          className="bg-gradient-to-r from-green-500 to-green-700 hover:from-green-600 hover:to-green-800 text-white font-bold py-2 px-4 rounded-lg inline-flex items-center transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isClaimLoading ? 'Claiming...' : 'Claim Rewards'}
        </button>
      </div>
    </GlowingCard>
  );
};

const UserProfile = () => {
  const { address } = useAccount();
  const { 
    userRole, 
    bscRole, 
    alveyRole, 
    isLoading: roleLoading, 
    bscBalance, 
    alveyBalance,
    error: roleError
  } = useUserRole();
  const [isLoading, setIsLoading] = useState(true);
  const [prices, setPrices] = useState({ bsc: 0, alvey: 0 });
  const [totalSupply, setTotalSupply] = useState({ bsc: 0, alvey: 0 });

  const bscChainId = 56;
  const alveyChainId = 3797;

  const { data: bscDistributed } = useContractRead({
    address: config.contractAddress,
    abi: ICARUS_ABI,
    functionName: 'totalDistributedPerPerson',
    args: [address],
    chainId: bscChainId,
  });

  const { data: bscPending } = useContractRead({
    address: config.contractAddress,
    abi: ICARUS_ABI,
    functionName: 'pendingRewards',
    args: [address],
    chainId: bscChainId,
  });

  const { data: bscTotalDistributed } = useContractRead({
    address: config.contractAddress,
    abi: ICARUS_ABI,
    functionName: 'totalDistributedAll',
    chainId: bscChainId,
  });

  const { data: alveyDistributed } = useContractRead({
    address: config.alveyContractAddress,
    abi: ALVEY_ABI,
    functionName: 'totalDistributedPerPerson',
    args: [address],
    chainId: alveyChainId,
  });

  const { data: alveyPending } = useContractRead({
    address: config.alveyContractAddress,
    abi: ALVEY_ABI,
    functionName: 'pendingRewards',
    args: [address],
    chainId: alveyChainId,
  });

  const { data: alveyTotalDistributed } = useContractRead({
    address: config.alveyContractAddress,
    abi: ALVEY_ABI,
    functionName: 'totalDistributedAll',
    chainId: alveyChainId,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const bscToken = await QuarkUtils.getToken(bscChainId, config.contractAddress);
        const alveyToken = await QuarkUtils.getToken(alveyChainId, config.alveyContractAddress);
        setPrices({ bsc: bscToken.price_usd, alvey: alveyToken.price_usd });
        setTotalSupply({ bsc: bscToken.total_supply, alvey: alveyToken.total_supply });
      } catch (error) {
        console.error("Error fetching token data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading || roleLoading) return <LoadingAnimation />;

  if (roleError) {
    return <div className="text-red-500">Error: {roleError}</div>;
  }

 const bscBalanceValue = parseFloat(bscBalance);
  const alveyBalanceValue = parseFloat(alveyBalance);
  const totalBalance = bscBalanceValue + alveyBalanceValue;
  const totalValue = (bscBalanceValue * prices.bsc) + (alveyBalanceValue * prices.alvey);

  const getRoleGradient = (role) => {
    switch (role) {
      case 'CORE': return 'bg-gradient-to-r from-[#FFD54F] to-[#FFA000]';
      case 'Soar': return 'bg-gradient-to-r from-[#81C784] to-[#4CAF50]';
      case 'Flock': return 'bg-gradient-to-r from-[#4FC3F7] to-[#03A9F4]';
      case 'Acolyte': return 'bg-gradient-to-r from-[#9575CD] to-[#673AB7]';
      default: return 'bg-gradient-to-r from-[#B0BEC5] to-[#78909C]';
    }
  };

  const hasAcolyteNFT = userRole === 'Acolyte';

  return (
    <ErrorBoundary>
      <div className="bg-gray-900 text-white p-6 sm:p-8 rounded-xl shadow-2xl">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
            <RoleCard 
              role={userRole} 
              bscRole={bscRole}
              alveyRole={alveyRole}
              acolyteRole={hasAcolyteNFT ? 'Acolyte' : null}
              gradient={getRoleGradient(userRole)} 
            />
            <InfoCard 
              title="Total Balance" 
              value={formatNumber(totalBalance, 'ICA')} 
              icon={Wallet}
              accentColor="blue-400"
              className="bg-gradient-to-br from-gray-800 to-gray-700"
            />
            <InfoCard 
              title="Total Value" 
              value={formatNumber(totalValue, 'USD')} 
              icon={DollarSign}
              accentColor="green-400"
              className="bg-gradient-to-br from-gray-800 to-gray-700"
            />
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
            <ChainCard 
              chainName="BSC"
              logo="/bnb-logo.png"
              balance={bscBalance}
              distributed={bscDistributed}
              pending={bscPending}
              totalDistributed={bscTotalDistributed}
              price={prices.bsc}
              gradient="bg-gradient-to-r from-yellow-400 to-orange-500"
              chainId={bscChainId}
              contractAddress={config.contractAddress}
            />
            <ChainCard 
              chainName="Alvey"
              logo="/alvey-logo.png"
              balance={alveyBalance}
              distributed={alveyDistributed}
              pending={alveyPending}
              totalDistributed={alveyTotalDistributed}
              price={prices.alvey}
              gradient="bg-gradient-to-r from-blue-400 to-purple-500"
              chainId={alveyChainId}
              contractAddress={config.alveyContractAddress}
            />
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
            <TierProgressCard 
              balance={bscBalanceValue}
              chainName="BSC"
              gradient="bg-gradient-to-r from-yellow-400 to-orange-500"
              price={prices.bsc}
              totalSupply={totalSupply.bsc}
            />
            <TierProgressCard 
              balance={alveyBalanceValue}
              chainName="Alvey"
              gradient="bg-gradient-to-r from-blue-400 to-purple-500"
              price={prices.alvey}
              totalSupply={totalSupply.alvey}
            />
          </div>
        </motion.div>
      </div>
    </ErrorBoundary>
  );
};

export default UserProfile;
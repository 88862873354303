import React, { useEffect, useState } from 'react';
import { Wallet, DollarSign, TrendingUp, TrendingDown, Droplet, Activity, BarChart2, Zap, AlertTriangle, Clock, ChevronDown, ChevronUp } from 'lucide-react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { config } from '../config';
import LoadingAnimation from './LoadingAnimation';
import { formatNumber } from '../utils/numberUtils';

const COLORS = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F7DC6F', '#B19CD9'];
const CACHE_KEY = 'treasuryNetWorth';
const CACHE_DURATION = 6 * 60 * 60 * 1000; // 6 hours in milliseconds

const Treasury = () => {
  const [treasuryData, setTreasuryData] = useState(null);
  const [netWorth, setNetWorth] = useState(null);
  const [walletStats, setWalletStats] = useState(null);
  const [recentTransactions, setRecentTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [expandedRows, setExpandedRows] = useState({});

  const getCachedData = (key) => {
    const cachedData = localStorage.getItem(key);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      if (Date.now() - timestamp < CACHE_DURATION) {
        return data;
      }
    }
    return null;
  };

  const setCachedData = (key, data) => {
    const cacheData = {
      data,
      timestamp: Date.now(),
    };
    localStorage.setItem(key, JSON.stringify(cacheData));
  };

  const fetchWithErrorHandling = async (url, params, errorKey) => {
    const queryString = new URLSearchParams(params).toString();
    const fullUrl = `${url}?${queryString}`;
    try {
      const response = await fetch(fullUrl, {
        headers: {
          accept: 'application/json',
          'X-API-Key': config.moralisApiKey,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log(`${errorKey} response:`, data);
      return data;
    } catch (error) {
      console.error(`Error fetching ${errorKey}:`, error);
      setErrors(prev => ({ ...prev, [errorKey]: error.message }));
      return null;
    }
  };

  const toggleRowExpansion = (id) => {
    setExpandedRows(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setErrors({});

      // Always fetch fresh token data
      const tokensData = await fetchWithErrorHandling(
        `https://deep-index.moralis.io/api/v2.2/wallets/${config.treasuryWallet}/tokens`,
        { chain: config.hexChain, exclude_spam: true, exclude_unverified_contracts: true },
        'tokens'
      );

      // Try to get cached netWorth data, if not available, fetch it
      let netWorthData = getCachedData(CACHE_KEY);
      if (!netWorthData) {
        netWorthData = await fetchWithErrorHandling(
          `https://deep-index.moralis.io/api/v2.2/wallets/${config.treasuryWallet}/net-worth`,
          { chains: [config.hexChain], exclude_spam: true, exclude_unverified_contracts: true },
          'netWorth'
        );
        if (netWorthData) {
          setCachedData(CACHE_KEY, netWorthData);
        }
      }

      const walletStatsData = await fetchWithErrorHandling(
        `https://deep-index.moralis.io/api/v2.2/wallets/${config.treasuryWallet}/stats`,
        { chain: config.hexChain },
        'walletStats'
      );

      const transactionsData = await fetchWithErrorHandling(
        `https://deep-index.moralis.io/api/v2.2/${config.treasuryWallet}/erc20/transfers`,
        { chain: config.hexChain, limit: 10, order: 'DESC' },
        'recentTransactions'
      );

      if (tokensData && Array.isArray(tokensData.result)) {
        const tokens = tokensData.result.map(token => ({
          ...token,
          totalValue: parseFloat(token.balance) / Math.pow(10, token.decimals) * (token.usd_price || 0),
          profit24h: ((token.usd_price || 0) - (token.usd_price_24h_ago || 0)) * (parseFloat(token.balance) / Math.pow(10, token.decimals)),
          percentChange24h: ((token.usd_price || 0) - (token.usd_price_24h_ago || 0)) / (token.usd_price_24h_ago || 1) * 100
        }));

        const filteredTokens = tokens.filter(token => token.totalValue > 10);
        const sortedTokens = filteredTokens.sort((a, b) => b.totalValue - a.totalValue);

        setTreasuryData(sortedTokens);
      } else {
        setErrors(prev => ({ ...prev, tokens: 'Invalid token data received' }));
      }

      if (netWorthData && typeof netWorthData === 'object') setNetWorth(netWorthData);
      else setErrors(prev => ({ ...prev, netWorth: 'Invalid net worth data received' }));

      if (walletStatsData && typeof walletStatsData === 'object') setWalletStats(walletStatsData);
      else setErrors(prev => ({ ...prev, walletStats: 'Invalid wallet stats data received' }));

      if (transactionsData && Array.isArray(transactionsData.result)) {
        setRecentTransactions(transactionsData.result);
      } else {
        setErrors(prev => ({ ...prev, recentTransactions: 'Invalid transactions data received' }));
      }

      setIsLoading(false);
    };

    fetchData();
  }, []);

  if (isLoading) return <LoadingAnimation />;

  const renderErrorMessages = () => (
    <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
      <p className="font-bold">Errors occurred while fetching data:</p>
      <ul>
        {Object.entries(errors).map(([key, value]) => (
          <li key={key}>{key}: {value}</li>
        ))}
      </ul>
    </div>
  );

  const pieChartData = treasuryData ? treasuryData.slice(0, 7).map((token, index) => ({
    name: token.symbol,
    value: token.totalValue,
    color: COLORS[index % COLORS.length]
  })) : [];

  return (
    <div className="flex flex-col w-full bg-gray-900 text-white p-4">
      <h1 className="text-2xl font-bold text-orange-500 mb-6 flex items-center">
        <Wallet className="mr-2" /> ICARUS Treasury Dashboard
      </h1>

      {Object.keys(errors).length > 0 && renderErrorMessages()}

      <div className="flex flex-col space-y-4">
        {/* Utility Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {netWorth && (
            <div className="bg-gradient-to-br from-blue-600 to-purple-600 rounded-lg shadow-lg p-4">
              <h2 className="text-lg font-bold mb-2">Treasury Net Worth</h2>
              <div className="flex items-center">
                <DollarSign className="mr-2" size={24} />
                <span className="text-xl font-bold">{formatNumber(netWorth.total_networth_usd, 'dollar')}</span>
              </div>
            </div>
          )}

          {netWorth && (
            <div className="bg-gradient-to-br from-green-500 to-teal-500 rounded-lg shadow-lg p-4">
              <h2 className="text-lg font-bold mb-2">24h Change</h2>
              <div className="flex items-center">
                {netWorth.total_usd_24h_change >= 0 ? (
                  <TrendingUp className="mr-2" size={24} />
                ) : (
                  <TrendingDown className="mr-2" size={24} />
                )}
                <span className="text-xl font-bold">{formatNumber(Math.abs(netWorth.total_usd_24h_change), 'dollar')}</span>
              </div>
              <p className="text-sm mt-2">
                {formatNumber(netWorth.total_usd_24h_change_percent, 'percent')}% in the last 24h
              </p>
            </div>
          )}

          {treasuryData && (
            <div className="bg-gradient-to-br from-yellow-500 to-orange-500 rounded-lg shadow-lg p-4">
              <h2 className="text-lg font-bold mb-2">Total Tokens</h2>
              <div className="flex items-center">
                <Droplet className="mr-2" size={24} />
                <span className="text-xl font-bold">{treasuryData.length}</span>
              </div>
            </div>
          )}

          {walletStats && (
            <div className="bg-gradient-to-br from-pink-500 to-red-500 rounded-lg shadow-lg p-4">
              <h2 className="text-lg font-bold mb-2">Wallet Stats</h2>
              <div className="flex flex-col space-y-2">
                <div className="flex items-center justify-between">
                  <span className="flex items-center"><BarChart2 size={16} className="mr-2" /> Transactions</span>
                  <span className="font-bold">{walletStats.transactions.total}</span>
                </div>
                <div className="flex items-center justify-between">
                  <span className="flex items-center"><Zap size={16} className="mr-2" /> NFTs</span>
                  <span className="font-bold">{formatNumber(walletStats.nfts)}</span>
                </div>
                <div className="flex items-center justify-between">
                  <span className="flex items-center"><Activity size={16} className="mr-2" /> Collections</span>
                  <span className="font-bold">{formatNumber(walletStats.collections)}</span>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Portfolio Distribution and Top Performers */}
        {treasuryData && (
          <div className="flex flex-col lg:flex-row gap-4">
            <div className="flex-grow bg-gray-800 rounded-lg shadow-lg p-4">
              <h2 className="text-xl font-bold mb-4">Portfolio Distribution</h2>
              <div className="h-64 lg:h-80">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={pieChartData}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      outerRadius="80%"
                      fill="#8884d8"
                      label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                    >
                      {pieChartData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                    <Tooltip 
                      formatter={(value, name, props) => [formatNumber(value, 'dollar'), props.payload.name]}
                      contentStyle={{ backgroundColor: '#374151', border: 'none' }}
                      itemStyle={{ color: '#ffffff' }}
                    />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>

            <div className="w-full lg:w-1/3 bg-gray-800 rounded-lg shadow-lg p-4">
              <h2 className="text-xl font-bold mb-4">Top Performers (24h)</h2>
              <div className="space-y-4">
                {treasuryData
                  .sort((a, b) => b.percentChange24h - a.percentChange24h)
                  .slice(0, 5)
                  .map((token, index) => (
                    <div key={index} className="flex items-center justify-between">
                      <div className="flex items-center">
                        <img src={token.thumbnail || 'https://placeholder.com/30'} alt={token.name} className="w-8 h-8 rounded-full mr-2" />
                        <span>{token.symbol}</span>
                      </div>
                      <div className="flex items-center text-green-500">
                        <TrendingUp className="mr-1" size={16} />
                        {formatNumber(Math.abs(token.percentChange24h), 'percent')}%
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}

        {/* Token Holdings */}
        {treasuryData && (
          <div className="bg-gray-800 rounded-lg shadow-lg p-4">
            <h2 className="text-xl font-bold mb-4">Token Holdings</h2>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-700">
                <thead className="bg-gray-700">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Token</th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-300 uppercase tracking-wider hidden sm:table-cell">Balance</th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-300 uppercase tracking-wider hidden sm:table-cell">Price</th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-300 uppercase tracking-wider hidden sm:table-cell">Value</th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-300 uppercase tracking-wider">24h Change</th>
                  </tr>
                </thead>
                <tbody className="bg-gray-800 divide-y divide-gray-700">
                  {treasuryData.map((token, index) => (
                    <React.Fragment key={index}>
                      <tr className="cursor-pointer" onClick={() => toggleRowExpansion(`token-${index}`)}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <img className="h-8 w-8 rounded-full" src={token.thumbnail || 'https://placeholder.com/30'} alt="" />
                            <div className="ml-4">
                              <div className="text-sm font-medium text-white">{token.symbol}</div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-white hidden sm:table-cell">
                          {formatNumber(parseFloat(token.balance) / Math.pow(10, token.decimals), token.symbol)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-white hidden sm:table-cell">
                          {formatNumber(token.usd_price, 'dollar')}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-white hidden sm:table-cell">
                          {formatNumber(token.totalValue, 'dollar')}
                        </td>
                        <td className={`px-6 py-4 whitespace-nowrap text-right text-sm ${token.percentChange24h >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                          {token.percentChange24h >= 0 ? <TrendingUp className="inline mr-1" size={16} /> : <TrendingDown className="inline mr-1" size={16} />}
                          {formatNumber(Math.abs(token.percentChange24h), 'percent')}%
                        </td>
                      </tr>
                      {expandedRows[`token-${index}`] && (
                        <tr className="bg-gray-700 sm:hidden">
                          <td colSpan="5" className="px-6 py-4">
                            <div className="text-sm text-white">
                              <p>Balance: {formatNumber(parseFloat(token.balance) / Math.pow(10, token.decimals), token.symbol)}</p>
                              <p>Price: {formatNumber(token.usd_price, 'dollar')}</p>
                              <p>Value: {formatNumber(token.totalValue, 'dollar')}</p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {/* Recent Transactions */}
        {recentTransactions.length > 0 && (
          <div className="bg-gray-800 rounded-lg shadow-lg p-4 mt-4">
            <h2 className="text-xl font-bold mb-4 flex items-center"><Clock className="mr-2" />Recent Transactions</h2>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-700">
                <thead className="bg-gray-700">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Transaction Hash</th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-300 uppercase tracking-wider hidden sm:table-cell">From</th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-300 uppercase tracking-wider hidden sm:table-cell">To</th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-300 uppercase tracking-wider">Token</th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-300 uppercase tracking-wider">Value</th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-300 uppercase tracking-wider hidden sm:table-cell">Date</th>
                  </tr>
                </thead>
                <tbody className="bg-gray-800 divide-y divide-gray-700">
                  {recentTransactions.map((tx, index) => (
                    <React.Fragment key={index}>
                      <tr className="cursor-pointer" onClick={() => toggleRowExpansion(`tx-${index}`)}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                          <a href={`https://bscscan.com/tx/${tx.transaction_hash}`} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-400">
                            {tx.transaction_hash.slice(0, 6)}...{tx.transaction_hash.slice(-4)}
                          </a>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-white hidden sm:table-cell">{tx.from_address.slice(0, 6)}...{tx.from_address.slice(-4)}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-white hidden sm:table-cell">{tx.to_address.slice(0, 6)}...{tx.to_address.slice(-4)}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-white">
                          <div className="flex items-center justify-end">
                            <img className="h-5 w-5 rounded-full mr-2" src={tx.token_thumbnail || 'https://placeholder.com/30'} alt="" />
                            <span>{tx.token_symbol}</span>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-white">
                          {formatNumber(tx.value / Math.pow(10, tx.token_decimals), tx.token_symbol)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-white hidden sm:table-cell">
                          {new Date(tx.block_timestamp).toLocaleString()}
                        </td>
                      </tr>
                      {expandedRows[`tx-${index}`] && (
                        <tr className="bg-gray-700 sm:hidden">
                          <td colSpan="5" className="px-6 py-4">
                            <div className="text-sm text-white">
                              <p>From: {tx.from_address.slice(0, 6)}...{tx.from_address.slice(-4)}</p>
                              <p>To: {tx.to_address.slice(0, 6)}...{tx.to_address.slice(-4)}</p>
                              <p>Date: {new Date(tx.block_timestamp).toLocaleString()}</p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Treasury;
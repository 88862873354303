import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import ProjectOverview from "./ProjectOverview";
import IcarusChart from "./IcarusChart";
import LoadingAnimation from "./LoadingAnimation";
import ProjectOverviewAlvey from "./ProjectOverviewAlvey";

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay (remove this in production and use actual data loading)
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <LoadingAnimation />;
  }

  return (
    <div className="flex flex-col space-y-6">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <ProjectOverview />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <ProjectOverviewAlvey />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        <IcarusChart />
      </motion.div>
    </div>
  );
};

export default Dashboard;

import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Network } from 'vis-network/standalone';
import { GitBranch, Info, Calendar, Filter, Coins, ExternalLink, RefreshCw, PaintBucket } from 'lucide-react';
import { formatNumber, formatAddress } from '../../utils/numberUtils';
import { fetchTokenPrice } from '../../utils/moralisUtils';
import styles from './NetworkOfInteractions.module.css';

const ALVEY_API_BASE_URL = 'https://api.alveyscan.com/api/v2';
const TOKEN_CACHE_KEY = 'alvey_token_list';
const TOKEN_CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
const ALVEY_BRIDGE_ADDRESS = '0xe641dE87F5b3539BE6C6F717069FA90F105B5B5f';
const NAUR_WALLET_ADDRESS = '0x05e55e63aD4D0fb2cAAE8BBCb4d34Ebb29185Ada';
const ELVESDEX_WALLET_ADDRESS = '0x154a76D1095c69a942411CaB9aEef6a155EbF90E';
const ALV_DECIMALS = 18;

const MARK_COLORS = [
  '#FF6B6B', // Bright Red
  '#4ECDC4', // Turquoise
  '#45B7D1', // Sky Blue
  '#FFA07A', // Light Salmon
  '#98D8C8', // Light Green
  '#F7DC6F'  // Light Yellow
];

const NetworkOfInteractions = ({ initialTransactions, initialTokenTransfers, alvContractAddress, onError }) => {
  const visJsRef = useRef(null);
  const networkRef = useRef(null);
  const [transactions, setTransactions] = useState(initialTransactions);
  const [tokenTransfers, setTokenTransfers] = useState(initialTokenTransfers);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedEdge, setSelectedEdge] = useState(null);
  const [contracts, setContracts] = useState({});
  const [alvPrice, setAlvPrice] = useState(0);
  const [tokenDecimals, setTokenDecimals] = useState({});
  const [settings, setSettings] = useState({
    days: 30,
    minTransactions: 1,
    includeTokenTransfers: true,
    alvThreshold: 10000,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [markedNodes, setMarkedNodes] = useState({});

  useEffect(() => {
    setTransactions(initialTransactions);
    setTokenTransfers(initialTokenTransfers);
  }, [initialTransactions, initialTokenTransfers]);

  const handleSettingsChange = (key, value) => {
    setSettings(prev => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const cachedTokenData = getCachedTokenData();
        if (cachedTokenData) {
          setContracts(cachedTokenData);
        } else {
          const priceData = await fetchTokenPrice(alvContractAddress);
          setAlvPrice(priceData.usdPrice);

          const response = await fetch(`${ALVEY_API_BASE_URL}/tokens?type=ERC-20`);
          const data = await response.json();

          if (data && data.items && Array.isArray(data.items)) {
            const contractMap = {};
            const decimalsMap = {};
            data.items.forEach(token => {
              contractMap[token.address.toLowerCase()] = token.name;
              decimalsMap[token.address.toLowerCase()] = token.decimals;
            });
            setContracts(contractMap);
            setTokenDecimals(decimalsMap);
            setCachedTokenData(contractMap);
          } else {
            throw new Error("Unexpected data format");
          }
        }
      } catch (error) {
        console.error("Failed to fetch initial data:", error);
        onError("Failed to load initial data. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [alvContractAddress, onError]);

  const fetchWalletData = useCallback(async (address) => {
    setIsLoading(true);
    try {
      const [newTransactions, newTokenTransfers, alvBalance] = await Promise.all([
        fetch(`${ALVEY_API_BASE_URL}/addresses/${address}/transactions`).then(res => res.json()),
        fetch(`${ALVEY_API_BASE_URL}/addresses/${address}/token-transfers`).then(res => res.json()),
        fetch(`${ALVEY_API_BASE_URL}/addresses/${address}/balance`).then(res => res.json())
      ]);

      setTransactions(prev => [...prev, ...newTransactions.items]);
      setTokenTransfers(prev => [...prev, ...newTokenTransfers.items]);
      setSelectedNode(prevNode => ({
        ...prevNode,
        alvBalance: alvBalance.result
      }));
    } catch (error) {
      console.error("Failed to fetch wallet data:", error);
      setError("Failed to fetch wallet data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getCachedTokenData = () => {
    const cachedData = localStorage.getItem(TOKEN_CACHE_KEY);
    if (!cachedData) return null;

    const { data, timestamp } = JSON.parse(cachedData);
    const now = new Date().getTime();

    if (now - timestamp < TOKEN_CACHE_DURATION) {
      return data;
    }

    localStorage.removeItem(TOKEN_CACHE_KEY);
    return null;
  };

  const setCachedTokenData = (data) => {
    const timestamp = new Date().getTime();
    localStorage.setItem(TOKEN_CACHE_KEY, JSON.stringify({ data, timestamp }));
  };

  const networkData = useMemo(() => {
    console.log("Recalculating network data");
    console.log("Transactions:", transactions.length);
    console.log("Token Transfers:", tokenTransfers.length);
    console.log("Settings:", settings);

    const nodes = new Map();
    const edges = [];

    const addNode = (address, isContract = false, label = '') => {
      if (!nodes.has(address)) {
        const lowercaseAddress = address.toLowerCase();
        const contractName = contracts[lowercaseAddress] || label;
        const isLiquidityPool = contractName.includes('ElvesLP');
        const isBridge = lowercaseAddress === ALVEY_BRIDGE_ADDRESS.toLowerCase();
        const isNaurWallet = lowercaseAddress === NAUR_WALLET_ADDRESS.toLowerCase();
        const isElvesDexWallet = lowercaseAddress === ELVESDEX_WALLET_ADDRESS.toLowerCase();

        let nodeColor, nodeShape, nodeLabel;
        if (isBridge) {
          nodeColor = '#FF0000';
          nodeShape = 'triangle';
          nodeLabel = 'BRIDGE';
        } else if (isNaurWallet) {
          nodeColor = '#FFA500';
          nodeShape = 'square';
          nodeLabel = 'NAUR';
        } else if (isElvesDexWallet) {
          nodeColor = '#800080'; // Purple
          nodeShape = 'triangle';
          nodeLabel = 'ELVESDEX';
        } else if (isLiquidityPool) {
          nodeColor = '#FFA500';
          nodeShape = 'dot';
          nodeLabel = contractName;
        } else if (isContract) {
          nodeColor = '#4e79a7';
          nodeShape = 'dot';
          nodeLabel = contractName || formatAddress(address);
        } else {
          nodeColor = '#9c755f';
          nodeShape = 'dot';
          nodeLabel = formatAddress(address);
        }

        nodes.set(address, { 
          id: address, 
          label: nodeLabel,
          value: 0, 
          color: nodeColor,
          shape: nodeShape,
          font: { color: 'white', size: 14, face: 'Arial' }
        });
      }
      nodes.get(address).value += 1;
    };

    const addEdge = (from, to, value, type, tokenSymbol, txHash, tokenAddress) => {
      const decimals = tokenDecimals[tokenAddress.toLowerCase()] || ALV_DECIMALS;
      const adjustedValue = parseFloat(value) / Math.pow(10, decimals);
      const isHighValue = adjustedValue >= settings.alvThreshold;
      edges.push({ 
        from, 
        to, 
        value: adjustedValue,
        title: `${tokenSymbol || 'ALV'}: ${formatNumber(adjustedValue, tokenSymbol || 'ALV')}`,
        txHash: txHash,
        color: isHighValue ? '#ff0000' : (type === 'trade' ? '#59a14f' : '#9c755f'),
        width: isHighValue ? 3 : Math.log10(adjustedValue + 1) + 1,
        isHighValue: isHighValue
      });
    };

    const now = new Date();
    const startDate = new Date(now.setDate(now.getDate() - settings.days));

    console.log("Processing transactions");
    let transactionsAdded = 0;
    transactions.forEach(tx => {
      if (tx && tx.from && tx.to && new Date(tx.timestamp) >= startDate) {
        const adjustedValue = parseFloat(tx.value) / Math.pow(10, ALV_DECIMALS);
        if (adjustedValue >= settings.minTransactions) {
          const isContractFrom = tx.from.is_contract;
          const isContractTo = tx.to.is_contract;

          addNode(tx.from.hash, isContractFrom);
          addNode(tx.to.hash, isContractTo);

          addEdge(tx.from.hash, tx.to.hash, tx.value, 'trade', 'ALV', tx.hash, alvContractAddress);
          transactionsAdded++;
        }
      }
    });
    console.log("Transactions added:", transactionsAdded);

    console.log("Processing token transfers");
    let transfersAdded = 0;
    if (settings.includeTokenTransfers) {
      tokenTransfers.forEach(transfer => {
        if (transfer && transfer.from && transfer.to && transfer.total && new Date(transfer.timestamp) >= startDate) {
          addNode(transfer.from.hash, transfer.from.is_contract);
          addNode(transfer.to.hash, transfer.to.is_contract);
          addEdge(transfer.from.hash, transfer.to.hash, transfer.total.value, 'transfer', transfer.token.symbol, transfer.transaction_hash, transfer.token.address);
          transfersAdded++;
        }
      });
    }
    console.log("Token transfers added:", transfersAdded);

    console.log("Total nodes:", nodes.size);
    console.log("Total edges:", edges.length);

    return {
      nodes: Array.from(nodes.values()),
      edges: edges,
    };
  }, [transactions, tokenTransfers, contracts, settings, tokenDecimals, alvContractAddress]);

  useEffect(() => {
    if (!visJsRef.current || !networkData.nodes.length) return;

    console.log("Recreating network visualization");
    console.log("Nodes:", networkData.nodes.length);
    console.log("Edges:", networkData.edges.length);

    const options = {
      nodes: {
        shape: 'dot',
        scaling: {
          min: 10,
          max: 30,
          label: {
            min: 8,
            max: 30,
            drawThreshold: 1,
            maxVisible: 30
          }
        },
        font: {
          size: 14,
          face: 'Arial',
          color: 'white'
        }
      },
      edges: {
        width: 0.15,
        color: { inherit: 'from' },
        smooth: {
          type: 'continuous'
        }
      },
      physics: {
        stabilization: false,
        barnesHut: {
          gravitationalConstant: -80000,
          springConstant: 0.001,
          springLength: 200
        }
      },
      interaction: { hover: true },
      layout: {
        improvedLayout: true
      }
    };

    networkRef.current = new Network(visJsRef.current, networkData, options);

    networkRef.current.on('stabilizationProgress', function(params) {
      console.log('Stabilization progress:', params.iterations, '/', params.total);
    });

    networkRef.current.on('stabilizationIterationsDone', function() {
      console.log('Stabilization finished');
    });

    networkRef.current.on('click', function(params) {
      if (params.nodes.length > 0) {
        const nodeId = params.nodes[0];
        const node = networkData.nodes.find(n => n.id === nodeId);
        setSelectedNode(node);
        setSelectedEdge(null);
        networkRef.current.selectNodes([nodeId]);
      } else if (params.edges.length > 0) {
        const edgeId = params.edges[0];
        const edge = networkData.edges.find(e => e.id === edgeId);
        setSelectedEdge(edge);
        setSelectedNode(null);
        networkRef.current.selectEdges([edgeId]);
      } else {
        setSelectedNode(null);
        setSelectedEdge(null);
        networkRef.current.unselectAll();
      }
    });

    return () => {
      if (networkRef.current) {
        networkRef.current.destroy();
        networkRef.current = null;
      }
    };
  }, [networkData]);

  const handleRefresh = useCallback(() => {
    if (networkRef.current) {
      networkRef.current.setData(networkData);
      networkRef.current.redraw();
    }
  }, [networkData]);

  const handleMarkNode = useCallback((color) => {
    if (selectedNode) {
      setMarkedNodes(prev => ({
        ...prev,
        [selectedNode.id]: color
      }));
      
      if (networkRef.current) {
        networkRef.current.body.data.nodes.update({
          id: selectedNode.id,
          color: {
            background: color,
            border: color,
            highlight: {
              background: color,
              border: color
            }
          }
        });
      }
    }
  }, [selectedNode]);

  return (
    <div className={styles.networkContainer}>
      <h3 className="text-2xl font-bold text-orange-500 mb-4 flex items-center">
        <GitBranch className="mr-2" size={24} /> Network of Interactions
      </h3>
      <div className="mb-4 flex flex-wrap gap-4">
        <div className="flex items-center">
          <Calendar className="mr-2" size={16} />
          <select 
            value={settings.days} 
            onChange={(e) => handleSettingsChange('days', parseInt(e.target.value))}
            className="bg-gray-700 text-white p-2 rounded"
          >
            <option value={1}>1 day</option>
            <option value={7}>7 days</option>
            <option value={30}>30 days</option>
            <option value={90}>90 days</option>
          </select>
        </div>
        <div className="flex items-center">
          <Filter className="mr-2" size={16} />
          <input 
            type="number" 
            value={settings.minTransactions} 
            onChange={(e) => handleSettingsChange('minTransactions', parseFloat(e.target.value))}
            className="bg-gray-700 text-white p-2 rounded w-24"
            placeholder="Min transactions"
          />
        </div>
        <div className="flex items-center">
          <Coins className="mr-2" size={16} />
          <input 
            type="number" 
            value={settings.alvThreshold} 
            onChange={(e) => handleSettingsChange('alvThreshold', parseFloat(e.target.value))}
            className="bg-gray-700 text-white p-2 rounded w-24"
            placeholder="ALV threshold"
          />
          <span className="ml-1 text-white">ALV</span>
        </div>
        <div className="flex items-center">
          <input 
            type="checkbox" 
            checked={settings.includeTokenTransfers} 
            onChange={(e) => handleSettingsChange('includeTokenTransfers', e.target.checked)}
            className="mr-2"
          />
          <span className="text-white">Include token transfers</span>
        </div>
      </div>
      <div ref={visJsRef} className={styles.networkVisualization} />
      <button
        onClick={handleRefresh}
        className="mt-4 bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded flex items-center"
      >
        <RefreshCw className="mr-2" size={16} /> Refresh Network
      </button>
      {error && (
        <div className="mt-4 p-4 bg-red-900 text-white rounded-lg">
          {error}
        </div>
      )}
      {selectedNode && (
        <div className={`${styles.infoBox} ${styles.selectedNode}`}>
          <h4 className="text-xl font-bold text-orange-500 mb-2 flex items-center">
            <Info className="mr-2" size={20} /> Selected Node Information
          </h4>
          <p className="text-white"><strong>Address:</strong> {selectedNode.id}</p>
          <p className="text-white"><strong>Label:</strong> {selectedNode.label}</p>
          <p className="text-white"><strong>Interactions:</strong> {selectedNode.value}</p>
          <p className="text-white"><strong>Type:</strong> {
            selectedNode.shape === 'triangle' && selectedNode.color === '#FF0000' ? 'Bridge' :
            selectedNode.shape === 'square' ? 'NAUR Wallet' :
            selectedNode.shape === 'triangle' && selectedNode.color === '#800080' ? 'ELVESDEX' :
            selectedNode.color === '#FFA500' ? 'Liquidity Pool' :
            selectedNode.color === '#4e79a7' ? 'Smart Contract' : 'Wallet'
          }</p>
          {selectedNode.alvBalance && (
            <p className="text-white"><strong>ALV Balance:</strong> {formatNumber(parseFloat(selectedNode.alvBalance) / Math.pow(10, ALV_DECIMALS), 'ALV')}</p>
          )}
          <div className="flex space-x-4 mt-2">
            <a 
              href={`https://alveyscan.com/${selectedNode.color === '#4e79a7' ? 'token' : 'address'}/${selectedNode.id}`} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-blue-400 hover:text-blue-300 flex items-center"
            >
              View on Alveyscan <ExternalLink size={16} className="ml-1" />
            </a>
            <button
              onClick={() => fetchWalletData(selectedNode.id)}
              disabled={isLoading}
              className="text-blue-400 hover:text-blue-300 flex items-center disabled:opacity-50"
            >
              {isLoading ? 'Fetching...' : 'Fetch Wallet Data'}
              <RefreshCw size={16} className={`ml-1 ${isLoading ? 'animate-spin' : ''}`} />
            </button>
          </div>
          <div className="mt-4">
            <h5 className="text-lg font-bold text-orange-500 mb-2 flex items-center">
              <PaintBucket className="mr-2" size={16} /> Mark Node
            </h5>
            <div className="flex space-x-2">
              {MARK_COLORS.map((color, index) => (
                <button
                  key={index}
                  onClick={() => handleMarkNode(color)}
                  className={`${styles.colorButton}`}
                  style={{ backgroundColor: color }}
                  aria-label={`Mark node with ${color}`}
                />
              ))}
            </div>
          </div>
        </div>
      )}
      {selectedEdge && (
        <div className={styles.infoBox}>
          <h4 className="text-xl font-bold text-orange-500 mb-2 flex items-center">
            <Info className="mr-2" size={20} /> Selected Transaction Information
          </h4>
          <p className="text-white"><strong>From:</strong> {selectedEdge.from}</p>
          <p className="text-white"><strong>To:</strong> {selectedEdge.to}</p>
          <p className="text-white"><strong>Value:</strong> {selectedEdge.title}</p>
          <p className="text-white"><strong>Type:</strong> {selectedEdge.color === '#59a14f' ? 'Trade' : 'Token Transfer'}</p>
          {selectedEdge.isHighValue && (
            <p className="text-red-500 font-bold">High-Value Transfer</p>
          )}
          <p className="text-white"><strong>Transaction Hash:</strong> {selectedEdge.txHash}</p>
          <div className="flex space-x-4 mt-2">
            <a 
              href={`https://alveyscan.com/tx/${selectedEdge.txHash}`} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-blue-400 hover:text-blue-300 flex items-center"
            >
              View on Alveyscan <ExternalLink size={16} className="ml-1" />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default NetworkOfInteractions;
import { getDefaultWallets } from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, Chain } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { config as appConfig } from '../config';

export const bscChain: Chain = {
  id: 56,
  name: 'Binance Smart Chain',
  network: 'bsc',
  nativeCurrency: {
    decimals: 18,
    name: 'BNB',
    symbol: 'BNB',
  },
  rpcUrls: {
    public: { http: ['https://bsc-dataseed1.ninicoin.io/'] },
    default: { http: ['https://bsc-dataseed1.ninicoin.io/'] },
  },
  blockExplorers: {
    default: { name: 'BscScan', url: 'https://bscscan.com' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 15921452,
    },
  },
};

export const alveyChain: Chain = {
  id: 3797,
  name: 'Alvey Chain Mainnet',
  network: 'alvey',
  nativeCurrency: {
    decimals: 18,
    name: 'ALV',
    symbol: 'ALV',
  },
  rpcUrls: {
    public: { http: ['https://elves-core3.alvey.io/'] },
    default: { http: ['https://elves-core3.alvey.io/'] },
  },
  blockExplorers: {
    default: { name: 'AlveyScan', url: 'https://alveyscan.com/' },
  },
  iconUrl: '/alvey-logo.png',
};

export const { chains, publicClient, webSocketPublicClient } = configureChains(
  [bscChain, alveyChain],
  [publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: 'Icarus App',
  projectId: appConfig.projectId,
  chains
});

export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient
});
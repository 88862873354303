import React, { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';
import { BarChart, Coins, Flame, Users, DollarSign, TrendingUp, Droplet, PieChart as PieChartIcon, Lock, Clock, GiftIcon } from 'lucide-react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import { config } from '../config';
import { formatNumber } from '../utils/numberUtils';
import { fetchTokenHoldings, fetchTotalSupply, fetch24hData } from '../utils/geckoTerminalUtils';
import { fetchTokenPrice } from '../utils/moralisUtils';
import { ethers } from 'ethers';
import { ICARUS_ABI } from '../abi';
import ErrorBoundary from './ErrorBoundary';
import LoadingAnimation from './LoadingAnimation';

const COLORS = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F7DC6F', '#B19CD9'];

const GlowingCard = ({ children, glowColor = 'rgba(255, 152, 0, 0.5)', className = '' }) => (
  <motion.div
    className={`relative bg-gray-900 rounded-xl overflow-hidden ${className}`}
    whileHover={{ scale: 1.02 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <div className="absolute inset-0 bg-gradient-to-r from-transparent via-gray-800 to-transparent opacity-50" />
    <div className="relative z-10 p-6">
      {children}
    </div>
    <div
      className="absolute inset-0 opacity-0 hover:opacity-100 transition-opacity duration-300"
      style={{
        boxShadow: `0 0 20px ${glowColor}`,
        filter: 'blur(15px)',
        zIndex: -1,
      }}
    />
  </motion.div>
);

const InfoCard = ({ title, value, icon: Icon, tooltip }) => (
  <GlowingCard className="bg-gray-800 p-4 rounded-lg shadow relative group">
    <h3 className="text-sm font-bold text-gray-400 mb-1 flex items-center">
      <Icon className="mr-2 h-4 w-4 text-orange-500" /> {title}
    </h3>
    <p className="text-2xl font-bold text-white">{value}</p>
    {tooltip && (
      <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-900 text-white text-xs rounded py-1 px-2 hidden group-hover:block">
        {tooltip}
      </div>
    )}
  </GlowingCard>
);

const ProjectOverview = () => {
  const [tokenData, setTokenData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      const [
        totalSupply,
        priceData,
        walletHoldings,
        data24h,
        totalDistributed
      ] = await Promise.all([
        fetchTotalSupply(),
        fetchTokenPrice(),
        fetchTokenHoldings([
          ...config.stakingWallets,
          ...Object.values(config.teamWallets),
          config.icaPoolPairAddress,
          '0x000000000000000000000000000000000000dead',
          config.lockedWallet
        ]),
        fetch24hData(),
        fetchTotalDistributed()
      ]);

      const burnedTokens = walletHoldings.find(h => h.address.toLowerCase() === '0x000000000000000000000000000000000000dead')?.balance || '0';
      const liquidityTokens = walletHoldings.find(h => h.address.toLowerCase() === config.icaPoolPairAddress.toLowerCase())?.balance || '0';
      const stakedTokens = config.stakingWallets.reduce((acc, addr) => 
        acc + parseFloat(walletHoldings.find(h => h.address.toLowerCase() === addr.toLowerCase())?.balance || '0'), 0);
      const teamTokens = Object.values(config.teamWallets).reduce((acc, addr) => 
        acc + parseFloat(walletHoldings.find(h => h.address.toLowerCase() === addr.toLowerCase())?.balance || '0'), 0);
      const lockedTokens = parseFloat(walletHoldings.find(h => h.address.toLowerCase() === config.lockedWallet.toLowerCase())?.balance || '0');

      const trueCirculatingSupply = parseFloat(totalSupply) - parseFloat(burnedTokens) - parseFloat(liquidityTokens) - stakedTokens - teamTokens - lockedTokens;

      setTokenData({
        totalSupply: parseFloat(totalSupply),
        burnedTokens: parseFloat(burnedTokens),
        liquidityTokens: parseFloat(liquidityTokens),
        stakedTokens,
        teamTokens,
        lockedTokens,
        trueCirculatingSupply,
        price: priceData.usdPrice,
        priceChange24h: priceData.percentChange24h,
        marketCap: trueCirculatingSupply * priceData.usdPrice,
        liquidity: priceData.pairTotalLiquidityUsd,
        volume24h: data24h.volume24h,
        totalDistributed
      });
    } catch (err) {
      console.error('Error fetching token data:', err);
      setError(`Failed to fetch some token data. Displaying available information.`);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, config.apiCacheDuration);
    return () => clearInterval(interval);
  }, [fetchData]);

  const fetchTotalDistributed = async () => {
    const provider = new ethers.providers.JsonRpcProvider(config.bscRpcUrl);
    const contract = new ethers.Contract(config.contractAddress, ICARUS_ABI, provider);
    const totalDistributed = await contract.totalDistributedAll();
    return ethers.utils.formatEther(totalDistributed);
  };

  if (isLoading) return <LoadingAnimation />;

  const pieChartData = tokenData ? [
    { name: 'Burned', value: tokenData.burnedTokens },
    { name: 'Liquidity', value: tokenData.liquidityTokens },
    { name: 'Staked', value: tokenData.stakedTokens },
    { name: 'Team Controlled', value: tokenData.teamTokens },
    { name: 'Locked', value: tokenData.lockedTokens },
    { name: 'Circulating', value: tokenData.trueCirculatingSupply },
  ] : [];

  return (
    <ErrorBoundary>
      <section className="bg-gray-900 rounded-lg shadow-md p-6">
        <h2 className="text-2xl font-bold mb-6 text-orange-500 flex items-center">
          <BarChart className="mr-2" /> Project Overview BSC
        </h2>
        {error && <div className="text-yellow-500 mb-4">{error}</div>}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mb-6">
          <InfoCard 
            title="Current Price" 
            value={formatNumber(tokenData?.price || 0, 'ICAPrice')} 
            icon={DollarSign} 
              valueClassName="text-orange-500"
			
          />
          <InfoCard 
            title="True Circulating Supply" 
            value={formatNumber(tokenData?.trueCirculatingSupply, 'ICA')} 
            icon={TrendingUp} 
            tooltip="Total Supply - Burned - Liquidity - Staked - Team Controlled - Locked"
          />
          <InfoCard 
            title="True Market Cap" 
            value={formatNumber(tokenData?.marketCap || 0, 'USD')} 
            icon={PieChartIcon}
            tooltip="Calculated using the True Circulating Supply"
          />
          <InfoCard 
            title="24h Price Change" 
            value={`${formatNumber(tokenData?.priceChange24h || 0, 'percent')}`} 
            icon={Clock}
          />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <GlowingCard>
            <h3 className="text-xl font-bold text-orange-500 mb-4">Token Distribution</h3>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={pieChartData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                >
                  {pieChartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip formatter={(value) => formatNumber(value, 'ICA')} />
                <Legend formatter={(value, entry) => `${entry.payload.name} (${formatNumber(entry.payload.value, 'ICA')})`} />
              </PieChart>
            </ResponsiveContainer>
          </GlowingCard>
          <div className="grid grid-cols-2 gap-4">
            <InfoCard title="Total Supply" value={formatNumber(tokenData?.totalSupply, 'ICA')} icon={Coins} />
            <InfoCard title="Burned Tokens" value={formatNumber(tokenData?.burnedTokens, 'ICA')} icon={Flame} />
            <InfoCard title="Liquidity Tokens" value={formatNumber(tokenData?.liquidityTokens, 'ICA')} icon={Droplet} />
            <InfoCard title="Staked ICA" value={formatNumber(tokenData?.stakedTokens, 'ICA')} icon={Lock} />
            <InfoCard title="Team Controlled ICA" value={formatNumber(tokenData?.teamTokens, 'ICA')} icon={Users} />
            <InfoCard title="Locked ICA" value={formatNumber(tokenData?.lockedTokens, 'ICA')} icon={Lock} />
            <InfoCard 
              title="Total Distributed BNB" 
              value={formatNumber(tokenData?.totalDistributed || 0, 'BNB')} 
              icon={GiftIcon} 
              className="bg-gradient-to-br from-green-800 to-emerald-900 col-span-2"
              valueClassName="text-green-400"
            />
            <InfoCard title="Liquidity" value={formatNumber(tokenData?.liquidity || 0, 'USD')} icon={Droplet} />
          </div>
        </div>
      </section>
    </ErrorBoundary>
  );
};

export default ProjectOverview;
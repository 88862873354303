// src/components/tools/AirdropTool.js

import React, { useState, useEffect, useCallback } from 'react';
import { useAccount, useBalance, useNetwork, useContractRead, useContractWrite, usePrepareContractWrite } from 'wagmi';
import { parseUnits, formatUnits } from 'viem';
import { motion } from 'framer-motion';
import { ArrowRightLeft, AlertTriangle, Upload, RefreshCw, CheckCircle, Info, Lock, DollarSign, Repeat } from 'lucide-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formatNumber } from '../../utils/numberUtils';
import ErrorBoundary from '../ErrorBoundary';
import LoadingAnimation from '../LoadingAnimation';
import { config } from '../../config';

// Updated ABI based on the provided information
const AIRDROP_ABI = [
    {
        "inputs": [],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "token",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "address[]",
                "name": "recipients",
                "type": "address[]"
            },
            {
                "indexed": false,
                "internalType": "uint256[]",
                "name": "amounts",
                "type": "uint256[]"
            }
        ],
        "name": "ERC20AirdropPerformed",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address[]",
                "name": "recipients",
                "type": "address[]"
            },
            {
                "indexed": false,
                "internalType": "uint256[]",
                "name": "amounts",
                "type": "uint256[]"
            }
        ],
        "name": "EtherAirdropPerformed",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "previousOwner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "contract IERC20",
                "name": "_token",
                "type": "address"
            },
            {
                "internalType": "address[]",
                "name": "_recipients",
                "type": "address[]"
            },
            {
                "internalType": "uint256[]",
                "name": "_amounts",
                "type": "uint256[]"
            }
        ],
        "name": "batchTransferERC20",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address[]",
                "name": "_recipients",
                "type": "address[]"
            },
            {
                "internalType": "uint256[]",
                "name": "_amounts",
                "type": "uint256[]"
            }
        ],
        "name": "batchTransferEther",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_numRecipients",
                "type": "uint256"
            }
        ],
        "name": "estimateGasForBatchTransfer",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "estimatedGas",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getHelp",
        "outputs": [
            {
                "internalType": "string",
                "name": "instructions",
                "type": "string"
            }
        ],
        "stateMutability": "pure",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "icaToken",
        "outputs": [
            {
                "internalType": "contract IERC20",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "minIcaTokens",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_newIcaToken",
                "type": "address"
            }
        ],
        "name": "setIcaTokenAddress",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_newMinIcaTokens",
                "type": "uint256"
            }
        ],
        "name": "setMinIcaTokens",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_address",
                "type": "address"
            },
            {
                "internalType": "bool",
                "name": "_status",
                "type": "bool"
            }
        ],
        "name": "setWhitelistedAddress",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "whitelistedAddresses",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "contract IERC20",
                "name": "_token",
                "type": "address"
            }
        ],
        "name": "withdrawERC20",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "withdrawEther",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "stateMutability": "payable",
        "type": "receive"
    }
];


const AIRDROP_CONTRACT_ADDRESS = '0x9d77F9A59E6C1eB8F66245a90ADe29914cb59C0F';

const GlowingCard = ({ children, className = '' }) => (
  <motion.div
    className={`relative bg-gray-800 rounded-xl overflow-hidden ${className}`}
    whileHover={{ scale: 1.02 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <div className="absolute inset-0 bg-gradient-to-r from-transparent via-gray-700 to-transparent opacity-50" />
    <div className="relative z-10 p-6">
      {children}
    </div>
  </motion.div>
);

const AirdropTool = () => {
  const { address, isConnected } = useAccount();
  const { chain } = useNetwork();
  const [recipients, setRecipients] = useState('');
  const [amounts, setAmounts] = useState('');
  const [isSingleAmount, setIsSingleAmount] = useState(false);
  const [tokenAddress, setTokenAddress] = useState('');
  const [isEtherTransfer, setIsEtherTransfer] = useState(true);
  const [totalAmount, setTotalAmount] = useState('0');
  const [error, setError] = useState(null);
  const [estimatedGas, setEstimatedGas] = useState(null);

  // Contract reads
  const { data: icaTokenAddress } = useContractRead({
    address: AIRDROP_CONTRACT_ADDRESS,
    abi: AIRDROP_ABI,
    functionName: 'icaToken',
  });

  const { data: minIcaTokens } = useContractRead({
    address: AIRDROP_CONTRACT_ADDRESS,
    abi: AIRDROP_ABI,
    functionName: 'minIcaTokens',
  });

  const { data: whitelistStatus } = useContractRead({
    address: AIRDROP_CONTRACT_ADDRESS,
    abi: AIRDROP_ABI,
    functionName: 'whitelistedAddresses',
    args: [address],
    enabled: isConnected,
  });

  const { data: icaBalance } = useBalance({
    address,
    token: icaTokenAddress,
    enabled: isConnected && !!icaTokenAddress,
  });

  const isWhitelisted = whitelistStatus || (icaBalance && icaBalance.value >= (minIcaTokens || 0n));

  const { data: balance } = useBalance({
    address,
    token: isEtherTransfer ? undefined : tokenAddress,
    enabled: isConnected && (!isEtherTransfer ? !!tokenAddress : true),
  });

  const { data: estimatedGasData, refetch: refetchEstimatedGas } = useContractRead({
    address: AIRDROP_CONTRACT_ADDRESS,
    abi: AIRDROP_ABI,
    functionName: 'estimateGasForBatchTransfer',
    args: [recipients.split(',').length],
    enabled: isConnected && recipients.length > 0,
  });

  useEffect(() => {
    if (amounts) {
      const amountArray = amounts.split(',').map(a => a.trim());
      const total = amountArray.reduce((sum, amount) => sum + parseFloat(amount || '0'), 0);
      setTotalAmount(total.toString());
    } else if (isSingleAmount && recipients) {
      const recipientCount = recipients.split(',').length;
      const total = parseFloat(amounts) * recipientCount;
      setTotalAmount(total.toString());
    }
  }, [amounts, recipients, isSingleAmount]);

  // Prepare contract writes
  const { config: approveConfig } = usePrepareContractWrite({
    address: tokenAddress,
    abi: ['function approve(address spender, uint256 amount) returns (bool)'],
    functionName: 'approve',
    args: [AIRDROP_CONTRACT_ADDRESS, parseUnits(totalAmount, 18)],
    enabled: !isEtherTransfer && isConnected && !!tokenAddress && parseFloat(totalAmount) > 0,
  });

  const { write: approveWrite, isLoading: isApproving, isSuccess: isApproveSuccess } = useContractWrite(approveConfig);

  const { config: airdropConfig } = usePrepareContractWrite({
    address: AIRDROP_CONTRACT_ADDRESS,
    abi: AIRDROP_ABI,
    functionName: isEtherTransfer ? 'batchTransferEther' : 'batchTransferERC20',
    args: isEtherTransfer 
      ? [recipients.split(','), amounts.split(',').map(a => parseUnits(a, 18))]
      : [tokenAddress, recipients.split(','), amounts.split(',').map(a => parseUnits(a, 18))],
    enabled: isConnected && recipients.length > 0 && amounts.length > 0 && isWhitelisted,
    value: isEtherTransfer ? parseUnits(totalAmount, 18) : undefined,
  });

  const { write: performAirdrop, isLoading: isAirdropping, isSuccess: isAirdropSuccess } = useContractWrite(airdropConfig);

  const handleAirdrop = () => {
    if (!isConnected) {
      toast.error("Please connect your wallet to perform an airdrop.");
      return;
    }
    if (!recipients) {
      toast.error("Please enter recipient addresses.");
      return;
    }
    if (!amounts) {
      toast.error("Please enter amounts for the recipients.");
      return;
    }
    if (!isWhitelisted) {
      toast.error("You are not whitelisted to perform airdrops.");
      return;
    }
    setError(null);
    performAirdrop?.();
  };

  const handleApprove = () => {
    if (!isConnected) {
      toast.error("Please connect your wallet to approve token transfer.");
      return;
    }
    if (!tokenAddress) {
      toast.error("Please enter a valid token address.");
      return;
    }
    if (parseFloat(totalAmount) <= 0) {
      toast.error("Please enter a valid amount to approve.");
      return;
    }
    approveWrite?.();
  };

  const handleEstimateGas = useCallback(() => {
    refetchEstimatedGas();
  }, [refetchEstimatedGas]);

  useEffect(() => {
    if (estimatedGasData) {
      setEstimatedGas(estimatedGasData);
    }
  }, [estimatedGasData]);

  useEffect(() => {
    if (isAirdropSuccess) {
      toast.success("Airdrop completed successfully!");
    }
  }, [isAirdropSuccess]);

  const toggleAmountInput = () => {
    setIsSingleAmount(!isSingleAmount);
    setAmounts('');
  };

  return (
    <ErrorBoundary>
      <div className="space-y-6">
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />

        <GlowingCard className="bg-gradient-to-br from-orange-500 to-yellow-500">
          <h2 className="text-3xl font-bold text-white mb-2">Alveychain Airdrop Tool</h2>
          <p className="text-white">Perform batch transfers of ALV or ERC-20 tokens on the Alveychain network.</p>
        </GlowingCard>

        <GlowingCard>
          <div className="space-y-4">
            <div>
              <label className="block text-white mb-2">Transfer Type</label>
              <div className="flex space-x-4">
                <button
                  onClick={() => setIsEtherTransfer(true)}
                  className={`px-4 py-2 rounded ${isEtherTransfer ? 'bg-orange-500 text-white' : 'bg-gray-700 text-gray-300'}`}
                >
                  ALV
                </button>
                <button
                  onClick={() => setIsEtherTransfer(false)}
                  className={`px-4 py-2 rounded ${!isEtherTransfer ? 'bg-orange-500 text-white' : 'bg-gray-700 text-gray-300'}`}
                >
                  ERC-20 Token
                </button>
              </div>
            </div>

            {!isEtherTransfer && (
              <div>
                <label htmlFor="tokenAddress" className="block text-white mb-2">ERC-20 Token Address</label>
                <input
                  id="tokenAddress"
                  type="text"
                  value={tokenAddress}
                  onChange={(e) => setTokenAddress(e.target.value)}
                  placeholder="Enter ERC-20 token address"
                  className="w-full p-2 bg-gray-700 text-white rounded"
                />
              </div>
            )}

            <div>
              <label htmlFor="recipients" className="block text-white mb-2">Recipients (comma-separated)</label>
              <textarea
                id="recipients"
                value={recipients}
                onChange={(e) => setRecipients(e.target.value)}
                placeholder="Enter recipient addresses"
                className="w-full p-2 bg-gray-700 text-white rounded"
                rows={4}
              />
            </div>

            <div>
              <div className="flex justify-between items-center mb-2">
                <label htmlFor="amounts" className="block text-white">Amounts</label>
                <button
                  onClick={toggleAmountInput}
                  className="bg-gray-700 hover:bg-gray-600 text-white px-2 py-1 rounded flex items-center"
                >
                  <Repeat size={16} className="mr-1" />
                  {isSingleAmount ? 'Multiple Amounts' : 'Single Amount'}
                </button>
              </div>
              {isSingleAmount ? (
                <input
                  type="number"
                  value={amounts}
                  onChange={(e) => setAmounts(e.target.value)}
                  placeholder="Enter single amount for all recipients"
                  className="w-full p-2 bg-gray-700 text-white rounded"
                />
              ) : (
                <textarea
                  id="amounts"
                  value={amounts}
                  onChange={(e) => setAmounts(e.target.value)}
                  placeholder="Enter amounts for each recipient (comma-separated)"
                  className="w-full p-2 bg-gray-700 text-white rounded"
                  rows={4}
                />
              )}
            </div>

            <div className="bg-gray-700 p-4 rounded">
              <p className="text-white">Total Amount: {formatNumber(totalAmount, isEtherTransfer ? 'ALV' : 'Token')}</p>
              <p className="text-white">Your Balance: {balance ? formatNumber(formatUnits(balance.value, balance.decimals), balance.symbol) : 'Loading...'}</p>
              {estimatedGas && <p className="text-white">Estimated Gas: {formatNumber(estimatedGas, 'gas')}</p>}
            </div>

            <div className="flex space-x-4">
              {!isEtherTransfer && (
                <button
                  onClick={handleApprove}
                  disabled={!isConnected || isApproving || isApproveSuccess}
                  className="bg-purple-500 hover:bg-purple-600 text-white font-bold py-2 px-4 rounded transition-colors duration-200 flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isApproving ? (
                    <>
                      <RefreshCw className="animate-spin mr-2" size={20} />
                      Approving...
                    </>
                  ) : isApproveSuccess ? (
                    <>
                      <CheckCircle className="mr-2" size={20} />
                      Approved
                    </>
                  ) : (
                    <>
                      <ArrowRightLeft className="mr-2" size={20} />
                      Approve Tokens
                    </>
                  )}
                </button>
              )}
              <button
                onClick={handleAirdrop}
                disabled={!isConnected || isAirdropping || !isWhitelisted || (!isEtherTransfer && !isApproveSuccess)}
                className="flex-1 bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded transition-colors duration-200 flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isAirdropping ? (
                  <>
                    <RefreshCw className="animate-spin mr-2" size={20} />
                    Airdropping...
                  </>
                ) : (
                  <>
                    <Upload className="mr-2" size={20} />
                    Perform Airdrop
                  </>
                )}
              </button>
              <button
                onClick={handleEstimateGas}
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition-colors duration-200 flex items-center justify-center"
              >
                <Info className="mr-2" size={20} />
                Estimate Gas
              </button>
            </div>
          </div>
        </GlowingCard>

        <GlowingCard>
          <h3 className="text-xl font-bold text-white mb-2 flex items-center">
            <Lock className="mr-2" size={20} />
            Airdrop Requirements
          </h3>
          <p className="text-white">ICA Token Address: {icaTokenAddress || 'Loading...'}</p>
          <p className="text-white">Minimum ICA Tokens Required: {minIcaTokens ? formatNumber(formatUnits(minIcaTokens, 18), 'ICA') : 'Loading...'}</p>
          <p className="text-white">Your Whitelist Status: {isWhitelisted ? 'Whitelisted' : 'Not Whitelisted'}</p>
        </GlowingCard>
		
		 {error && (
          <div className="bg-red-500 text-white p-4 rounded-lg flex items-center">
            <AlertTriangle className="mr-2" size={20} />
            {error}
          </div>
        )}

        {isAirdropSuccess && (
          <div className="bg-green-500 text-white p-4 rounded-lg flex items-center">
            <CheckCircle className="mr-2" size={20} />
            Airdrop completed successfully!
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default AirdropTool;
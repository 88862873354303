import React, { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';
import { BarChart, Coins, Flame, Users, DollarSign, TrendingUp, Droplet, PieChart as PieChartIcon, Lock, Clock } from 'lucide-react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import { config } from '../config';
import { formatNumber } from '../utils/numberUtils';
import QuarkUtils from '../utils/QuarkUtils';
import ErrorBoundary from './ErrorBoundary';
import LoadingAnimation from './LoadingAnimation';

const COLORS = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F7DC6F', '#B19CD9'];

const GlowingCard = ({ children, glowColor = 'rgba(255, 152, 0, 0.5)', className = '' }) => (
  <motion.div
    className={`relative bg-gray-900 rounded-xl overflow-hidden ${className}`}
    whileHover={{ scale: 1.02 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <div className="absolute inset-0 bg-gradient-to-r from-transparent via-gray-800 to-transparent opacity-50" />
    <div className="relative z-10 p-6">
      {children}
    </div>
    <div
      className="absolute inset-0 opacity-0 hover:opacity-100 transition-opacity duration-300"
      style={{
        boxShadow: `0 0 20px ${glowColor}`,
        filter: 'blur(15px)',
        zIndex: -1,
      }}
    />
  </motion.div>
);

const InfoCard = ({ title, value, icon: Icon, tooltip }) => (
  <GlowingCard className="bg-gray-800 p-4 rounded-lg shadow relative group">
    <h3 className="text-sm font-bold text-gray-400 mb-1 flex items-center">
      <Icon className="mr-2 h-4 w-4 text-orange-500" /> {title}
    </h3>
    <p className="text-2xl font-bold text-white">{value}</p>
    {tooltip && (
      <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-900 text-white text-xs rounded py-1 px-2 hidden group-hover:block">
        {tooltip}
      </div>
    )}
  </GlowingCard>
);

const ProjectOverviewAlvey = () => {
  const [tokenData, setTokenData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      const response = await QuarkUtils.getToken(config.alveychainDec, config.alveyContractAddress);
      console.log('API Response:', response);

      if (response && typeof response === 'object') {
        const processedData = {
          price: response.price_usd || 0,
          circulatingSupply: parseFloat(response.crc_supply || '0'),
          totalSupply: parseFloat(response.ttl_supply || '0'),
          marketCap: response.mcap || 0,
          priceChange24h: response.price_change_24h || 0,
          burnedTokens: parseFloat(response.burned_tokens || '0'),
          liquidityTokens: parseFloat(response.liquidity_tokens || '0'),
          stakedTokens: parseFloat(response.staked_tokens || '0'),
          lockedTokens: parseFloat(response.locked_tokens || '0'),
          teamTokens: parseFloat(response.team_tokens || '0'),
          holders: response.holders_count || 0,
          transfers24h: response.transfers_24h || 0,
          liquidity: response.liquidity || 0,
        };

        setTokenData(processedData);
      } else {
        throw new Error('Invalid data format received from API');
      }
    } catch (err) {
      console.error('Error fetching token data:', err);
      setError(`Failed to fetch some token data. Displaying available information. ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, config.apiCacheDuration);
    return () => clearInterval(interval);
  }, [fetchData]);

  if (isLoading) return <LoadingAnimation />;

  const pieChartData = tokenData ? [
    { name: 'Burned', value: tokenData.burnedTokens },
    { name: 'Liquidity', value: tokenData.liquidityTokens },
    { name: 'Staked', value: tokenData.stakedTokens },
    { name: 'Locked', value: tokenData.lockedTokens },
    { name: 'Team', value: tokenData.teamTokens },
    { name: 'Circulating', value: tokenData.circulatingSupply },
  ] : [];

  return (
    <ErrorBoundary>
      <section className="bg-gray-900 rounded-lg shadow-md p-6">
        <h2 className="text-2xl font-bold mb-6 text-orange-500 flex items-center">
          <BarChart className="mr-2" /> Project Overview Alvey
        </h2>
        {error && <div className="text-yellow-500 mb-4">{error}</div>}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mb-6">
          <InfoCard 
            title="Current Price" 
            value={formatNumber(tokenData?.price || 0, 'ICAPrice')} 
            icon={DollarSign} 
          />
          <InfoCard 
            title="Circulating Supply" 
            value={formatNumber(tokenData?.circulatingSupply, 'ICA')} 
            icon={TrendingUp} 
            tooltip="Total Supply - (Burned + Liquidity + Staked + Locked + Team Tokens)"
          />
          <InfoCard 
            title="Market Cap" 
            value={formatNumber(tokenData?.marketCap || 0, 'USD')} 
            icon={PieChartIcon}
          />
          <InfoCard 
            title="24h Price Change" 
            value={`${formatNumber(tokenData?.priceChange24h || 0, 'percent')}`} 
            icon={Clock}
          />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <GlowingCard>
            <h3 className="text-xl font-bold text-orange-500 mb-4">Token Distribution</h3>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={pieChartData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                >
                  {pieChartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip formatter={(value) => formatNumber(value, 'ALV')} />
                <Legend formatter={(value, entry) => `${entry.payload.name} (${formatNumber(entry.payload.value, 'ICA')})`} />
              </PieChart>
            </ResponsiveContainer>
          </GlowingCard>
          <div className="grid grid-cols-2 gap-4">
            <InfoCard title="Total Supply" value={formatNumber(tokenData?.totalSupply, 'ICA')} icon={Coins} />
            <InfoCard title="Burned ALV" value={formatNumber(tokenData?.burnedTokens, 'ICA')} icon={Flame} />
            <InfoCard title="Liquidity ALV" value={formatNumber(tokenData?.liquidityTokens, 'ICA')} icon={Droplet} />
            <InfoCard title="Staked ALV" value={formatNumber(tokenData?.stakedTokens, 'ICA')} icon={Lock} />
            <InfoCard title="Locked ALV" value={formatNumber(tokenData?.lockedTokens, 'ICA')} icon={Lock} />
            <InfoCard title="Team Controlled ALV" value={formatNumber(tokenData?.teamTokens, 'ALV')} icon={Users} />
            <InfoCard title="Holders" value={formatNumber(tokenData?.holders, 'number')} icon={Users} />
            <InfoCard title="24h Transfers" value={formatNumber(tokenData?.transfers24h, 'number')} icon={TrendingUp} />
            <InfoCard title="Liquidity" value={formatNumber(tokenData?.liquidity || 0, 'USD')} icon={Droplet} />
          </div>
        </div>
      </section>
    </ErrorBoundary>
  );
};

export default ProjectOverviewAlvey;
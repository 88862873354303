import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, PieChart, Pie, Cell, Legend } from 'recharts';
import { format } from 'date-fns';
import { motion } from 'framer-motion';
import { Activity, AlertTriangle, Search, Coins, ArrowRightLeft, Layers, Info,
  Wallet, DollarSign, BarChart2, PieChart as PieChartIcon, Zap, Send, 
  RefreshCw, Shield, Code, ExternalLink } from 'lucide-react';
import { formatNumber, formatAddress } from '../../utils/numberUtils';
import LoadingAnimation from '../LoadingAnimation';
import NetworkOfInteractions from './NetworkOfInteractions';
import BalanceHistoryChart from './BalanceHistoryChart';
import { config } from '../../config';
import QuarkUtils from '../../utils/QuarkUtils';

const ALVEY_API_BASE_URL = 'https://api.alveyscan.com/api/v2';
const DEFAULT_WALLET = "0x05e55e63aD4D0fb2cAAE8BBCb4d34Ebb29185Ada";
const COLORS = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'];

const WalletActivityVisualization = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [networkError, setNetworkError] = useState(null);
  const [walletAddress, setWalletAddress] = useState(DEFAULT_WALLET);
  const [walletData, setWalletData] = useState({
    counters: null,
    transactions: [],
    tokenTransfers: [],
    internalTransactions: [],
    tokens: [],
    coinBalanceHistory: [],
    totalValue: 0
  });
  const [networkKey, setNetworkKey] = useState(0);

  const fetchTokenInfo = async (tokenAddress) => {
    try {
      const tokenInfo = await QuarkUtils.getToken(config.alveychainDec, tokenAddress);
      return {
        price: tokenInfo.price_usd,
        mcap: tokenInfo.mcap,
        symbol: tokenInfo.symbol,
        name: tokenInfo.name
      };
    } catch (error) {
      console.error(`Error fetching token info for ${tokenAddress}:`, error);
      return null;
    }
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const [
        counters,
        transactions,
        tokenTransfers,
        internalTransactions,
        tokens,
        coinBalanceHistory
      ] = await Promise.all([
        fetch(`${ALVEY_API_BASE_URL}/addresses/${walletAddress}/counters`).then(res => res.json()),
        fetch(`${ALVEY_API_BASE_URL}/addresses/${walletAddress}/transactions`).then(res => res.json()),
        fetch(`${ALVEY_API_BASE_URL}/addresses/${walletAddress}/token-transfers`).then(res => res.json()),
        fetch(`${ALVEY_API_BASE_URL}/addresses/${walletAddress}/internal-transactions`).then(res => res.json()),
        fetch(`${ALVEY_API_BASE_URL}/addresses/${walletAddress}/tokens`).then(res => res.json()),
        fetch(`${ALVEY_API_BASE_URL}/addresses/${walletAddress}/coin-balance-history`).then(res => res.json())
      ]);

      const tokensWithInfo = await Promise.all((tokens?.items || []).map(async (token) => {
        const tokenInfo = await fetchTokenInfo(token.token.address);
        const balance = parseFloat(token.value) / Math.pow(10, parseInt(token.token.decimals));
        const value = tokenInfo ? balance * tokenInfo.price : 0;
        return { 
          ...token, 
          ...tokenInfo, 
          balance, 
          value 
        };
      }));

      const totalValue = tokensWithInfo.reduce((sum, token) => sum + token.value, 0);

      setWalletData({
        counters,
        transactions: transactions?.items || [],
        tokenTransfers: tokenTransfers?.items || [],
        internalTransactions: internalTransactions?.items || [],
        tokens: tokensWithInfo,
        coinBalanceHistory: coinBalanceHistory?.items || [],
        totalValue
      });

      setNetworkKey(prevKey => prevKey + 1);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching wallet data:', err);
      setError(`Failed to fetch wallet data: ${err.message}`);
      setLoading(false);
    }
  }, [walletAddress]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const TotalValueCard = ({ totalValue }) => (
    <div className="bg-gradient-to-r from-orange-500 to-yellow-500 p-6 rounded-lg shadow-lg mb-6">
      <h3 className="text-2xl font-bold text-white mb-2 flex items-center">
        <Wallet className="mr-2" size={24} /> Total Portfolio Value
      </h3>
      <p className="text-4xl font-bold text-white">{formatNumber(totalValue, 'USD')}</p>
    </div>
  );

  const CountersCard = ({ counters }) => (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg mt-6">
      <h3 className="text-2xl font-bold text-orange-500 mb-4 flex items-center">
        <BarChart2 className="mr-2" size={24} /> Wallet Counters
      </h3>
      {counters ? (
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          <div className="bg-gray-700 p-4 rounded-lg">
            <p className="text-sm text-gray-400 flex items-center">
              <Zap className="mr-2" size={16} /> Gas Usage
            </p>
            <p className="text-xl font-bold text-white">{formatNumber(counters.gas_usage_count, 'number')}</p>
          </div>
          <div className="bg-gray-700 p-4 rounded-lg">
            <p className="text-sm text-gray-400 flex items-center">
              <Send className="mr-2" size={16} /> Token Transfers
            </p>
            <p className="text-xl font-bold text-white">{formatNumber(counters.token_transfers_count, 'number')}</p>
          </div>
          <div className="bg-gray-700 p-4 rounded-lg">
            <p className="text-sm text-gray-400 flex items-center">
              <ArrowRightLeft className="mr-2" size={16} /> Transactions
            </p>
            <p className="text-xl font-bold text-white">{formatNumber(counters.transactions_count, 'number')}</p>
          </div>
          <div className="bg-gray-700 p-4 rounded-lg">
            <p className="text-sm text-gray-400 flex items-center">
              <Shield className="mr-2" size={16} /> Validations
            </p>
            <p className="text-xl font-bold text-white">{formatNumber(counters.validations_count, 'number')}</p>
          </div>
        </div>
      ) : (
        <p className="text-gray-300">No counter data available.</p>
      )}
    </div>
  );

  const TokenDistributionPieChart = ({ tokens }) => {
    const pieData = tokens
      .filter(token => token.value > 0)
      .sort((a, b) => b.value - a.value)
      .slice(0, 5);

    return (
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg mt-6">
        <h3 className="text-2xl font-bold text-orange-500 mb-4 flex items-center">
          <PieChartIcon className="mr-2" size={24} /> Token Distribution
        </h3>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={pieData}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
              label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
            >
              {pieData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip formatter={(value, name, props) => [formatNumber(value, 'USD'), props.payload.symbol]} />
            <Legend formatter={(value, entry) => `${entry.payload.symbol} (${formatNumber(entry.payload.value, 'USD')})`} />
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  };

  const TokenHoldingsList = ({ tokens }) => (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg mt-6">
      <h3 className="text-2xl font-bold text-orange-500 mb-4 flex items-center">
        <Coins className="mr-2" size={24} /> Token Holdings
      </h3>
      <div className="overflow-x-auto">
        <table className="min-w-full table-auto">
          <thead>
            <tr className="bg-gray-700">
              <th className="px-4 py-2 text-left">Token</th>
              <th className="px-4 py-2 text-right">Balance</th>
              <th className="px-4 py-2 text-right">Price</th>
              <th className="px-4 py-2 text-right">Value</th>
              <th className="px-4 py-2 text-right">Market Cap</th>
            </tr>
          </thead>
          <tbody>
            {tokens.map((token, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-gray-800' : 'bg-gray-700'}>
                <td className="px-4 py-2">
                  <a
                    href={`https://alveyscan.com/token/${token.token.address}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-400 hover:text-blue-300 flex items-center"
                  >
                    {token.symbol || token.token.symbol}
                    <ExternalLink size={14} className="ml-1" />
                  </a>
                </td>
                <td className="px-4 py-2 text-right">{formatNumber(token.balance, token.symbol || token.token.symbol)}</td>
                <td className="px-4 py-2 text-right">
                  {token.price ? formatNumber(token.price, 'ICAPrice') : 'N/A'}
                </td>
                <td className="px-4 py-2 text-right">{formatNumber(token.value, 'USD')}</td>
                <td className="px-4 py-2 text-right">
                  {token.mcap ? formatNumber(token.mcap, 'USD') : 'N/A'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  const TransactionsList = ({ transactions }) => (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg mt-6">
      <h3 className="text-2xl font-bold text-orange-500 mb-4 flex items-center">
        <ArrowRightLeft className="mr-2" size={24} /> Recent Transactions
      </h3>
      {transactions && transactions.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto">
            <thead>
              <tr className="bg-gray-700">
                <th className="px-4 py-2 text-left">Hash</th>
                <th className="px-4 py-2 text-left">From</th>
                <th className="px-4 py-2 text-left">To</th>
                <th className="px-4 py-2 text-right">Value</th>
                <th className="px-4 py-2 text-right">Gas Used</th>
              </tr>
            </thead>
            <tbody>
              {transactions.slice(0, 10).map((tx, index) => (
                <tr key={index} className={index % 2 === 0 ? 'bg-gray-800' : 'bg-gray-700'}>
                  <td className="px-4 py-2">
                    <a href={`https://alveyscan.com/tx/${tx.hash}`} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
                      {formatAddress(tx.hash)}
                    </a>
                  </td>
                  <td className="px-4 py-2">
                    <a href={`https://alveyscan.com/address/${tx.from?.hash}`} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
                      {formatAddress(tx.from?.hash)}
                    </a>
                  </td>
                  <td className="px-4 py-2">
                    <a href={`https://alveyscan.com/address/${tx.to?.hash}`} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
                      {formatAddress(tx.to?.hash)}
                    </a>
                  </td>
                  <td className="px-4 py-2 text-right">{formatNumber(parseFloat(tx.value) / Math.pow(10, 18), 'ALV')}</td>
                  <td className="px-4 py-2 text-right">{formatNumber(tx.gas_used, 'number')}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-gray-300">No transactions found.</p>
      )}
    </div>
  );

 const TokenTransfersList = ({ transfers }) => (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg mt-6">
      <h3 className="text-2xl font-bold text-orange-500 mb-4 flex items-center">
        <Send className="mr-2" size={24} /> Recent Token Transfers
      </h3>
      {transfers && transfers.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto">
            <thead>
              <tr className="bg-gray-700">
                <th className="px-4 py-2 text-left">Token</th>
                <th className="px-4 py-2 text-left">From</th>
                <th className="px-4 py-2 text-left">To</th>
                <th className="px-4 py-2 text-right">Amount</th>
              </tr>
            </thead>
            <tbody>
              {transfers.slice(0, 10).map((transfer, index) => (
                <tr key={index} className={index % 2 === 0 ? 'bg-gray-800' : 'bg-gray-700'}>
                  <td className="px-4 py-2">
                    {transfer.token && transfer.token.address ? (
                      <a href={`https://alveyscan.com/token/${transfer.token.address}`} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
                        {transfer.token.symbol || 'Unknown'}
                      </a>
                    ) : (
                      transfer.token?.symbol || 'Unknown'
                    )}
                  </td>
                  <td className="px-4 py-2">
                    <a href={`https://alveyscan.com/address/${transfer.from?.hash}`} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
                      {formatAddress(transfer.from?.hash)}
                    </a>
                  </td>
                  <td className="px-4 py-2">
                    <a href={`https://alveyscan.com/address/${transfer.to?.hash}`} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
                      {formatAddress(transfer.to?.hash)}
                    </a>
                  </td>
                  <td className="px-4 py-2 text-right">
                    {formatNumber(
                      parseFloat(transfer.total?.value || '0') / Math.pow(10, parseInt(transfer.token?.decimals || '18')),
                      transfer.token?.symbol || 'Unknown'
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-gray-300">No token transfers found.</p>
      )}
    </div>
  );

  return (
    <div className="bg-gray-900 text-white p-6">
      <h2 className="text-4xl font-bold text-orange-500 mb-8 flex items-center">
        <Activity className="mr-4" size={36} /> Wallet Activity Tool
      </h2>

      <div className="mb-8 flex flex-wrap gap-4">
        <div className="flex-grow">
          <input
            type="text"
            value={walletAddress}
            onChange={(e) => setWalletAddress(e.target.value)}
            placeholder="Enter wallet address"
            className="bg-gray-800 text-white p-3 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-orange-500"
          />
        </div>
        <button
          onClick={fetchData}
          className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-3 px-6 rounded-lg flex items-center transition duration-300 ease-in-out transform hover:scale-105"
        >
          <Search className="mr-2" size={20} /> Analyze Wallet
        </button>
      </div>

      {error && (
        <div className="bg-red-900 border-l-4 border-red-500 text-red-300 p-4 mb-6 rounded-lg" role="alert">
          <AlertTriangle className="inline-block mr-2" size={20} />
          {error}
        </div>
      )}

      {loading ? (
        <LoadingAnimation />
      ) : (
        <>
          <TotalValueCard totalValue={walletData.totalValue} />
          <CountersCard counters={walletData.counters} />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <BalanceHistoryChart walletAddress={walletAddress} />
            <TokenDistributionPieChart tokens={walletData.tokens} />
          </div>
          {networkError ? (
            <div className="bg-yellow-900 border-l-4 border-yellow-500 text-yellow-300 p-4 mb-6 rounded-lg" role="alert">
              <AlertTriangle className="inline-block mr-2" size={20} />
              Error loading Network of Interactions: {networkError}
            </div>
          ) : (
            <ErrorBoundary onError={setNetworkError}>
              <NetworkOfInteractions 
                key={networkKey}
                initialTransactions={walletData.transactions} 
                initialTokenTransfers={walletData.tokenTransfers} 
                alvContractAddress={walletAddress}
                onError={setNetworkError}
              />
            </ErrorBoundary>
          )}
          <TokenHoldingsList tokens={walletData.tokens} />
          <TransactionsList transactions={walletData.transactions} />
          <TokenTransfersList transfers={walletData.tokenTransfers} />
        </>
      )}
    </div>
  );
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Caught an error:", error, errorInfo);
    this.props.onError && this.props.onError(error.message);
  }

  render() {
    if (this.state.hasError) {
      return null;
    }

    return this.props.children;
  }
}

export default WalletActivityVisualization;
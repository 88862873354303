import React, { useState, useEffect } from 'react';
import { useAccount, useBalance } from 'wagmi';
import { ethers } from 'ethers';
import { config } from '../config';
import { Lock, Crown, Rocket, Gift, Coins, Star, Users, ChartBar, MessageCircle, Shield, ExternalLink } from 'lucide-react';
import { motion } from 'framer-motion';

const Core = () => {
  const { address } = useAccount();
  const { data: balance } = useBalance({
    address,
    token: config.contractAddress,
  });

  const [isCoreMember, setIsCoreMember] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  useEffect(() => {
    if (balance) {
      const requiredBalance = ethers.utils.parseUnits('10000000000', 18);
      setIsCoreMember(balance.value.gte(requiredBalance));
    }
  }, [balance]);

  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
  };

  const BenefitCard = ({ icon: Icon, title, description }) => (
    <motion.div 
      className="bg-gray-800 p-4 rounded-lg shadow-lg flex items-start"
      whileHover={{ scale: 1.05 }}
      transition={{ type: "spring", stiffness: 300 }}
    >
      <Icon className="text-orange-500 mr-3 flex-shrink-0" size={24} />
      <div>
        <h4 className="text-lg font-semibold text-white mb-1">{title}</h4>
        <p className="text-sm text-gray-300">{description}</p>
      </div>
    </motion.div>
  );

  const TelegramInviteCard = () => (
    <motion.div
      className="bg-gradient-to-r from-blue-600 to-blue-800 p-6 rounded-lg shadow-lg mb-6"
      whileHover={{ scale: 1.05 }}
      transition={{ type: "spring", stiffness: 300 }}
    >
      <h3 className="text-xl font-bold text-white mb-4 flex items-center">
        <MessageCircle className="mr-2" size={24} /> CORE Members Telegram
      </h3>
      <p className="text-gray-200 mb-4">Join the exclusive Telegram group for CORE members to stay updated and connect with other ICARUS enthusiasts!</p>
      <a
        href="https://t.me/+-U7bpVrWqTkwM2Q0"
        target="_blank"
        rel="noopener noreferrer"
        className="bg-white text-blue-600 font-bold py-2 px-4 rounded inline-flex items-center transition-colors duration-200 hover:bg-gray-100"
      >
        Join Telegram Group <ExternalLink size={16} className="ml-2" />
      </a>
    </motion.div>
  );

  const CoreMemberContent = () => (
    <div className="space-y-6">
      <h2 className="text-3xl font-bold text-orange-500 mb-4">Welcome, ICARUS CORE Member!</h2>
      <p className="text-white mb-4">
        As a valued CORE member, you have access to exclusive benefits and features. 
        Explore your enhanced privileges and make the most of your ICARUS experience!
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <BenefitCard
          icon={MessageCircle}
          title="Exclusive Chat2Earn Rewards"
          description="Earn more through our special Chat2Earn program for CORE members."
        />
        <BenefitCard
          icon={Coins}
          title="Enhanced Staking Pools"
          description="Access to high-yield staking pools exclusive to CORE members."
        />
        <BenefitCard
          icon={ChartBar}
          title="Insider Information"
          description="Get early access to project updates and market insights."
        />
        <BenefitCard
          icon={Shield}
          title="Priority Support"
          description="Enjoy dedicated support channels for quick issue resolution."
        />
      </div>
    </div>
  );

  const NonCoreMemberContent = () => (
    <div className="space-y-6">
      <h2 className="text-3xl font-bold text-orange-500 mb-4">Become a Core Member of ICARUS!</h2>
      <p className="text-white mb-4">
        Unlock exclusive benefits and take your involvement with ICARUS to the next level by becoming a Core Member! Here are the incredible perks waiting for you:
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <BenefitCard
          icon={Gift}
          title="Exclusive Rewards"
          description="Gain access to special airdrops and bonus rewards."
        />
        <BenefitCard
          icon={Star}
          title="Premium Features"
          description="Unlock advanced platform features and tools."
        />
        <BenefitCard
          icon={Users}
          title="Community Leadership"
          description="Take a leading role in the ICARUS community."
        />
        <BenefitCard
          icon={Coins}
          title="Enhanced Earnings"
          description="Enjoy increased reward rates in our staking pools."
        />
      </div>
      <div className="mt-6">
        <a
          href="https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0xF012F402747E1E4715b7E496650243A29be204C7"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105"
        >
          BUY ICA TO BECOME A CORE MEMBER
        </a>
      </div>
    </div>
  );

  return (
    <div className="w-full">
      <h1 className="text-4xl font-bold text-orange-500 mb-8 flex items-center">
        <Crown className="mr-2" /> ICARUS CORE
      </h1>
      <div className="flex flex-col lg:flex-row gap-8">
        <div className="lg:w-[70%] space-y-8">
          {isCoreMember ? <CoreMemberContent /> : <NonCoreMemberContent />}
        </div>
        <div className="lg:w-[30%] space-y-6">
          {isCoreMember && <TelegramInviteCard />}
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
            <h3 className="text-2xl font-bold text-orange-500 mb-4">ICARUS CORE Experience</h3>
            {!isVideoLoaded && (
              <div className="flex items-center justify-center h-64 bg-gray-700 rounded">
                <Rocket className="animate-bounce text-orange-500" size={48} />
              </div>
            )}
            <video
              className={`w-full rounded ${isVideoLoaded ? '' : 'hidden'}`}
              controls
              onLoadedData={handleVideoLoad}
              style={{ maxHeight: '720px' }}
            >
              <source src="/core.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold text-orange-500 mb-4">Core Member Stats</h3>
            <p className="text-white">Total Core Members: 1,234</p>
            <p className="text-white">Average Holdings: 15M ICA</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Core;
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Buffer } from 'buffer';
import 'react-app-polyfill/stable';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { config } from './config';
import Moralis from 'moralis';
import { register } from './serviceWorkerRegistration';

// Polyfills and global assignments
window.Buffer = Buffer;
window.process = require('process/browser');

// Moralis initialization
Moralis.start({ apiKey: config.moralisApiKey }).then(() => {
  console.log('Moralis initialized successfully');
}).catch((error) => {
  console.error('Failed to initialize Moralis:', error);
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
register();
// src/components/ProjectScanner.js

import React from 'react';
import { Scan, AlertTriangle } from 'lucide-react';
import NewTokensList from './tools/NewTokensList';
import { useNotifications } from '../hooks/useNotifications';
import { useUserRole } from '../hooks/useUserRole';
import ErrorBoundary from './ErrorBoundary';
import IcarusIntroduction from './IcarusIntroduction';

const ProjectScanner = () => {
  const { permissionGranted } = useNotifications();
  const { userRole, isLoading } = useUserRole();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!['Flock', 'Soar', 'CORE', 'Acolyte'].includes(userRole)) {
    return (
      <ErrorBoundary>
        <div className="bg-gray-900 text-white p-6">
          <h2 className="text-4xl font-bold text-orange-500 mb-8 flex items-center">
            <Scan className="mr-4" size={36} /> Alvey Chain Project Scanner
          </h2>
          <div className="bg-yellow-900 border-l-4 border-yellow-500 text-yellow-300 p-4 mb-8 rounded-lg">
            <AlertTriangle className="inline-block mr-2" size={20} />
            You need to be a Flock member or higher to access the Project Scanner. 
            Please acquire more ICA tokens to unlock this feature.
          </div>
          <IcarusIntroduction />
        </div>
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary>
      <div className="bg-gray-900 text-white p-6">
        <h2 className="text-4xl font-bold text-orange-500 mb-8 flex items-center">
          <Scan className="mr-4" size={36} /> Alvey Chain Project Scanner
        </h2>
        {!permissionGranted && (
          <div className="bg-yellow-900 border-l-4 border-yellow-500 text-yellow-300 p-4 mb-6 rounded-lg">
            Please enable notifications for real-time alerts on new tokens and liquidity pairs.
          </div>
        )}
        <div className="space-y-12">
          <div>
            <h3 className="text-2xl font-bold text-orange-500 mb-4">New Projects</h3>
            <NewTokensList />
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default ProjectScanner;
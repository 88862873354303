import Moralis from 'moralis';
import { config } from '../config';

let tokenOwnersCache = null;
let tokenOwnersCacheTime = null;
const CACHE_DURATION = 0 * 0 * 15 * 1000; // 6 hours in milliseconds

export const initializeMoralis = async () => {
  if (!Moralis.Core.isStarted) {
    await Moralis.start({ apiKey: config.moralisApiKey });
  }
};

export const fetchTokenData = async (address) => {
  await initializeMoralis();
  return Moralis.EvmApi.token.getTokenMetadata({
    addresses: [address],
    chain: config.hexChain,
  });
};

export const fetchTokenPrice = async () => {
  await initializeMoralis();
  try {
    const response = await Moralis.EvmApi.token.getTokenPrice({
      chain: "0x38",
      include: "percent_change",
      address: config.contractAddress
    });
    return response.raw;
  } catch (e) {
    console.error('Error fetching token price:', e);
    throw e;
  }
};

export const fetchWalletTokenTransfers = async (walletAddress, tokenAddress) => {
  await initializeMoralis();
  return Moralis.EvmApi.token.getWalletTokenTransfers({
    address: walletAddress,
    chain: config.hexChain,
    tokenAddress: tokenAddress,
  });
};

export const fetchTokenHolders = async (tokenAddress) => {
  await initializeMoralis();
  return Moralis.EvmApi.token.getTokenHolders({
    address: tokenAddress,
    chain: config.hexChain,
  });
};

export const fetchTokenTransfers = async (tokenAddress) => {
  await initializeMoralis();
  return Moralis.EvmApi.token.getTokenTransfers({
    address: tokenAddress,
    chain: config.hexChain,
  });
};

export const fetchWalletTokenBalances = async (walletAddress) => {
  await initializeMoralis();
  return Moralis.EvmApi.token.getWalletTokenBalances({
    address: walletAddress,
    chain: config.hexChain,
  });
};

export const fetchLastTrades = async (limit = 10) => {
  await initializeMoralis();
  try {
    const response = await Moralis.EvmApi.token.getTokenTransfers({
      address: config.contractAddress,
      chain: config.hexChain,
      limit: limit
    });
    return response.result.map(trade => ({
      from: trade.from.checksum,
      to: trade.to.checksum,
      value: trade.value,
      timestamp: trade.blockTimestamp
    }));
  } catch (error) {
    console.error('Error fetching last trades:', error);
    return [];
  }
};

export const fetchTokenOwners = async () => {
  await initializeMoralis();

  // Check if cache is still valid
  if (tokenOwnersCache && tokenOwnersCacheTime && Date.now() - tokenOwnersCacheTime < CACHE_DURATION) {
    return tokenOwnersCache;
  }

  try {
    const response = await Moralis.EvmApi.token.getTokenOwners({
      chain: "0x38",
      order: "DESC",
      limit: 100,
      tokenAddress: config.contractAddress
    });

    tokenOwnersCache = response.raw;
    tokenOwnersCacheTime = Date.now();

    return tokenOwnersCache;
  } catch (e) {
    console.error('Error fetching token owners:', e);
    throw e;
  }
};
// AlveyUtils.js

const BASE_URL = 'https://api.alveyscan.com/api/v2';

const AlveyUtils = {
  // Helper function to make API calls
  async callApi(endpoint, params = {}) {
    const url = new URL(`${BASE_URL}${endpoint}`);
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error('API call failed:', error);
      throw error;
    }
  },

  // Search
  search: (query) => AlveyUtils.callApi('/search', { q: query }),
  checkSearchRedirect: (query) => AlveyUtils.callApi('/search/check-redirect', { q: query }),

  // Transactions
  getTransactions: (params) => AlveyUtils.callApi('/transactions', params),
  getMainPageTransactions: () => AlveyUtils.callApi('/main-page/transactions'),
  getTransactionInfo: (hash) => AlveyUtils.callApi(`/transactions/${hash}`),
  getTransactionTokenTransfers: (hash) => AlveyUtils.callApi(`/transactions/${hash}/token-transfers`),
  getTransactionInternalTransactions: (hash) => AlveyUtils.callApi(`/transactions/${hash}/internal-transactions`),
  getTransactionLogs: (hash) => AlveyUtils.callApi(`/transactions/${hash}/logs`),
  getTransactionRawTrace: (hash) => AlveyUtils.callApi(`/transactions/${hash}/raw-trace`),
  getTransactionStateChanges: (hash) => AlveyUtils.callApi(`/transactions/${hash}/state-changes`),

  // Blocks
  getBlocks: (params) => AlveyUtils.callApi('/blocks', params),
  getMainPageBlocks: () => AlveyUtils.callApi('/main-page/blocks'),
  getBlockInfo: (numberOrHash) => AlveyUtils.callApi(`/blocks/${numberOrHash}`),
  getBlockTransactions: (numberOrHash) => AlveyUtils.callApi(`/blocks/${numberOrHash}/transactions`),
  getBlockWithdrawals: (numberOrHash) => AlveyUtils.callApi(`/blocks/${numberOrHash}/withdrawals`),

  // Stats
  getIndexingStatus: () => AlveyUtils.callApi('/main-page/indexing-status'),
  getStatsCounters: () => AlveyUtils.callApi('/stats'),
  getTransactionsChart: () => AlveyUtils.callApi('/stats/charts/transactions'),
  getMarketChart: () => AlveyUtils.callApi('/stats/charts/market'),

  // Addresses
  getNativeCoinHolders: (params) => AlveyUtils.callApi('/addresses', params),
  getAddressInfo: (hash) => AlveyUtils.callApi(`/addresses/${hash}`),
  getAddressCounters: (hash) => AlveyUtils.callApi(`/addresses/${hash}/counters`),
  getAddressTransactions: (hash, params) => AlveyUtils.callApi(`/addresses/${hash}/transactions`, params),
  getAddressTokenTransfers: (hash, params) => AlveyUtils.callApi(`/addresses/${hash}/token-transfers`, params),
  getAddressInternalTransactions: (hash, params) => AlveyUtils.callApi(`/addresses/${hash}/internal-transactions`, params),
  getAddressLogs: (hash, params) => AlveyUtils.callApi(`/addresses/${hash}/logs`, params),
  getBlocksValidatedByAddress: (hash, params) => AlveyUtils.callApi(`/addresses/${hash}/blocks-validated`, params),
  getAllTokenBalancesForAddress: (hash) => AlveyUtils.callApi(`/addresses/${hash}/token-balances`),
  getTokenBalancesWithFiltering: (hash, params) => AlveyUtils.callApi(`/addresses/${hash}/tokens`, params),
  getAddressCoinBalanceHistory: (hash) => AlveyUtils.callApi(`/addresses/${hash}/coin-balance-history`),
  getAddressCoinBalanceHistoryByDay: (hash) => AlveyUtils.callApi(`/addresses/${hash}/coin-balance-history-by-day`),
  getAddressWithdrawals: (hash, params) => AlveyUtils.callApi(`/addresses/${hash}/withdrawals`, params),
  getNFTOwnedByAddress: (hash, params) => AlveyUtils.callApi(`/addresses/${hash}/nft`, params),
  getNFTCollectionsOwnedByAddress: (hash, params) => AlveyUtils.callApi(`/addresses/${hash}/nft/collections`, params),

  // Tokens
  getTokensList: (params) => AlveyUtils.callApi('/tokens', params),
  getTokenInfo: (hash) => AlveyUtils.callApi(`/tokens/${hash}`),
  getTokenTransfers: (hash, params) => AlveyUtils.callApi(`/tokens/${hash}/transfers`, params),
  getTokenHolders: (hash, params) => AlveyUtils.callApi(`/tokens/${hash}/holders`, params),
  getTokenCounters: (hash) => AlveyUtils.callApi(`/tokens/${hash}/counters`),
  getNFTInstances: (hash, params) => AlveyUtils.callApi(`/tokens/${hash}/instances`, params),
  getNFTInstanceById: (hash, id) => AlveyUtils.callApi(`/tokens/${hash}/instances/${id}`),
  getNFTInstanceTransfers: (hash, id, params) => AlveyUtils.callApi(`/tokens/${hash}/instances/${id}/transfers`, params),
  getNFTInstanceHolders: (hash, id, params) => AlveyUtils.callApi(`/tokens/${hash}/instances/${id}/holders`, params),
  getNFTInstanceTransfersCount: (hash, id) => AlveyUtils.callApi(`/tokens/${hash}/instances/${id}/transfers-count`),
  refetchNFTInstanceMetadata: (hash, id) => AlveyUtils.callApi(`/tokens/${hash}/instances/${id}/refetch-metadata`, {}, 'PATCH'),

  // Smart Contracts
  getVerifiedSmartContracts: (params) => AlveyUtils.callApi('/smart-contracts', params),
  getVerifiedSmartContractsCounters: () => AlveyUtils.callApi('/smart-contracts/counters'),
  getSmartContract: (hash) => AlveyUtils.callApi(`/smart-contracts/${hash}`),
  getSmartContractReadMethods: (hash) => AlveyUtils.callApi(`/smart-contracts/${hash}/methods-read`),
  getSmartContractReadMethodsProxy: (hash) => AlveyUtils.callApi(`/smart-contracts/${hash}/methods-read-proxy`),
  getSmartContractWriteMethods: (hash) => AlveyUtils.callApi(`/smart-contracts/${hash}/methods-write`),
  getSmartContractWriteMethodsProxy: (hash) => AlveyUtils.callApi(`/smart-contracts/${hash}/methods-write-proxy`),
  querySmartContractReadMethod: (hash, data) => AlveyUtils.callApi(`/smart-contracts/${hash}/query-read-method`, data, 'POST'),

  // Config
  getJsonRpcUrl: () => AlveyUtils.callApi('/config/json-rpc-url'),

  // Withdrawals
  getWithdrawals: (params) => AlveyUtils.callApi('/withdrawals', params),
};

export default AlveyUtils;